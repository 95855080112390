/* Options:
Date: 2023-01-16 13:10:41
Version: 6.21
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44333

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
ExcludeTypes: GetUserEmailAddresses,GetUserEmailAddressesResponse,GetInstitutionPersonnelEmailAddresses,InstitutionPersonnelEmailAddressesResponse
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IGet
{
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export interface IDelete
{
}

export class Drug
{
    public id?: number;
    public drugName: string;
    public unitsPerShippingContainer: number;
    public containerName: string;
    public unitConcentration: number;
    public unitVolume: number;
    public enableDrugShipmentGeneration: boolean;

    public constructor(init?: Partial<Drug>) { (Object as any).assign(this, init); }
}

export class Batch
{
    public id?: number;
    public drugId?: number;
    public batchNo: string;
    public dateExpiration?: string;
    public allocationCutOffDate?: string;
    public available: boolean;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public drug: Drug;

    public constructor(init?: Partial<Batch>) { (Object as any).assign(this, init); }
}

export class DepotBatch
{
    public id?: number;
    public depotId: number;
    public batchId: number;
    public depotBatchNo?: number;
    public drugUnitsTotal: number;
    public drugUnitsAllocated: number;
    public drugUnitsDestroyed: number;
    public available: boolean;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public batch: Batch;

    public constructor(init?: Partial<DepotBatch>) { (Object as any).assign(this, init); }
}

export class OrderForm
{
    public id?: number;
    public orderFormName: string;

    public constructor(init?: Partial<OrderForm>) { (Object as any).assign(this, init); }
}

export class Depot
{
    public id?: number;
    public depotName: string;
    public address: string;
    public email: string;
    public contactName: string;
    public phoneNo: string;
    public orderFormId: number;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public orderForm: OrderForm;

    public constructor(init?: Partial<Depot>) { (Object as any).assign(this, init); }
}

export enum PharmacyStatus
{
    Inactive = 1,
    Open = 2,
    Closed = 3,
}

export class PharmacyDrug
{
    public id?: number;
    public pharmacyId: number;
    public drugId: number;
    public capacity: number;
    public minThreshold: number;
    public enabled: boolean;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public drug: Drug;

    public constructor(init?: Partial<PharmacyDrug>) { (Object as any).assign(this, init); }
}

export class Pharmacy
{
    public id?: number;
    public depotId: number;
    public institutionCode: string;
    public pharmacyName: string;
    public pharmacistPersonId?: number;
    public phoneNumber: string;
    public address: string;
    public email: string;
    public ccOnEmails: boolean;
    public pharmacyStatus?: PharmacyStatus;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public pharmacyDrugs: PharmacyDrug[];

    public constructor(init?: Partial<Pharmacy>) { (Object as any).assign(this, init); }
}

export class Timepoint
{
    public id?: number;
    public value: string;
    public order?: number;
    public type?: number;

    public constructor(init?: Partial<Timepoint>) { (Object as any).assign(this, init); }
}

export class DrugDispensation
{
    public id?: number;
    public pharmacyId?: number;
    public patientId?: number;
    public depotBatchId?: number;
    public timepointId?: number;
    public dateDispensation?: string;
    public drugUnits?: number;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public pharmacy: Pharmacy;
    public depotBatch: DepotBatch;
    public timepoint: Timepoint;

    public constructor(init?: Partial<DrugDispensation>) { (Object as any).assign(this, init); }
}

export enum ShipmentStatus
{
    New = 1,
    Ordered = 2,
    InTransit = 3,
    Available = 4,
    Lost = 5,
    QuarantinedFromTransit = 6,
    Destroyed = 7,
    QuarantinedAtSite = 8,
}

export class DrugShipmentDepotBatch
{
    public id?: number;
    public drugShipmentId?: number;
    public depotBatchId?: number;
    public drugUnits?: number;
    public drugUnitsUsed?: number;
    public drugUnitsDamaged?: number;
    public drugUnitsDestroyed?: number;
    public drugUnitsLost?: number;
    public drugUnitsQuarantined?: number;
    public notes: string;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public drugShipment: DrugShipment;
    public depotBatch: DepotBatch;
    public drugAllocated?: number;
    public drugRemaining?: number;

    public constructor(init?: Partial<DrugShipmentDepotBatch>) { (Object as any).assign(this, init); }
}

export class DrugShipmentStatusHistory
{
    public id?: number;
    public drugShipmentId?: number;
    public shipmentStatus: ShipmentStatus;
    public statusDate: string;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<DrugShipmentStatusHistory>) { (Object as any).assign(this, init); }
}

export class DrugShipment
{
    public id?: number;
    public institutionCode: string;
    public pharmacyId?: number;
    public pharmacyShipmentNo?: number;
    public shipmentStatus?: ShipmentStatus;
    public consignmentNo: string;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public drugShipmentDepotBatches: DrugShipmentDepotBatch[];
    public drugShipmentStatusHistory: DrugShipmentStatusHistory[];

    public constructor(init?: Partial<DrugShipment>) { (Object as any).assign(this, init); }
}

export class DrugAmount
{
    public drugId?: number;
    public drugUnits?: number;

    public constructor(init?: Partial<DrugAmount>) { (Object as any).assign(this, init); }
}

export class DrugShipmentDepotBatchVerify
{
    public id?: number;
    public drugShipmentId?: number;
    public depotBatchId?: number;
    public drugUnits?: number;
    public drugUnitsReceived?: number;
    public drugUnitsDamaged?: number;
    public drugUnitsDestroyed?: number;
    public drugUnitsLost?: number;
    public drugUnitsQuarantined?: number;

    public constructor(init?: Partial<DrugShipmentDepotBatchVerify>) { (Object as any).assign(this, init); }
}

export class DrugShipmentVerify
{
    public id?: number;
    public consignmentNo: string;
    public received?: string;
    public drugShipmentDepotBatches: DrugShipmentDepotBatchVerify[];

    public constructor(init?: Partial<DrugShipmentVerify>) { (Object as any).assign(this, init); }
}

export enum PatientTreatmentStatus
{
    OnTreatment = 1,
    OffTreatment = 2,
}

export class PatientDrug
{
    public id?: number;
    public patientId?: number;
    public drugId?: number;
    public patientTreatmentStatus?: PatientTreatmentStatus;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public drug: Drug;

    public constructor(init?: Partial<PatientDrug>) { (Object as any).assign(this, init); }
}

export enum QuarantinedStockAction
{
    ReturnStockToSiteSupply = 1,
    DestroyStock = 2,
}

export enum SaeStatus
{
    Added = 0,
    New = 1,
    AwaitingForReview = 2,
    Approved = 3,
    Rejected = 4,
    Incorrect = 5,
}

export enum SaeRelatedness
{
    Unrelated = 1,
    Unlikely = 2,
    Possible = 3,
    Probable = 4,
    Definite = 5,
}

export enum SaeExpectedness
{
    Expected = 1,
    Unexpected = 2,
}

export class MedicalReviewItem
{
    public id?: number;
    public medicalReviewId?: number;
    public saeTreatmentCategoryId?: number;
    public typeId?: number;
    public dataEntered?: boolean;
    public latestRelatednessId?: number;
    public relatednessId?: SaeRelatedness;
    public expected?: SaeExpectedness;
    public comments: string;
    public notApplicable?: boolean;

    public constructor(init?: Partial<MedicalReviewItem>) { (Object as any).assign(this, init); }
}

export class MedicalReview
{
    public id?: number;
    public patientStudyNumber: string;
    public saeNumber?: number;
    public saeFollowUpNumber?: number;
    public date?: string;
    public saeStatus?: SaeStatus;
    public narrativeSummary: string;
    public items: MedicalReviewItem[];

    public constructor(init?: Partial<MedicalReview>) { (Object as any).assign(this, init); }
}

export interface ISaeGetCollection extends IGet
{
}

export interface ISaeGetCollectionByInstitutionCode extends IGet
{
    institutionCode: string;
}

export interface ISaeGetSingleByPatientStudyNumber extends IGet
{
    patientStudyNumber: string;
    saeNumber?: number;
}

export enum SaeWebActionTypeEnum
{
    ApprovalNotRequired = 0,
    NotifiedSiteInvestigators = 1,
    Approved = 2,
    Rejected = 3,
    NotifiedSiteTc = 4,
    Reset = 5,
    NotifiedMedicalReviewers = 6,
    SubmittedMedicalReview = 7,
    NotifiedTeam = 8,
    NotifiedStudyChair = 9,
    NotifiedStudySponsor = 10,
}

export class SaeWebActionType
{
    public id?: number;
    public value: string;

    public constructor(init?: Partial<SaeWebActionType>) { (Object as any).assign(this, init); }
}

export class WebAction
{
    public id?: number;
    public patientStudyNumber: string;
    public saeNumber?: number;
    public saeFollowUpNumber?: number;
    public actionType?: SaeWebActionTypeEnum;
    public actionDate?: string;
    public userName: string;
    public notes: string;
    public recipients: string;
    public saeWebActionType: SaeWebActionType;

    public constructor(init?: Partial<WebAction>) { (Object as any).assign(this, init); }
}

export class SaeStudyDrugReviewForm
{
    public studyDrug: string;
    public investigatorAssessment: string;
    public assessment: string;
    public comments: string;

    public constructor(init?: Partial<SaeStudyDrugReviewForm>) { (Object as any).assign(this, init); }
}

export class SaeExpectednessForm
{
    public assessment: string;
    public comments: string;

    public constructor(init?: Partial<SaeExpectednessForm>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewForm
{
    public studyDrugReviews: SaeStudyDrugReviewForm[];
    public expectedness: SaeExpectednessForm[];
    public isEventStatusDeath: boolean;

    public constructor(init?: Partial<SaeMedicalReviewForm>) { (Object as any).assign(this, init); }
}

export class FileUploadTemporary implements IFileUploadTemporary
{
    public guid: string;
    public name: string;
    public extension: string;
    public fileSize?: number;
    public contentType: string;
    public image: boolean;

    public constructor(init?: Partial<FileUploadTemporary>) { (Object as any).assign(this, init); }
}

export class File
{
    public id?: number;
    public patientId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyName: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    // @Ignore()
    public upload: FileUploadTemporary;

    // @Ignore()
    public extension: string;

    // @Ignore()
    public fileSize?: number;

    // @Ignore()
    public contentType: string;

    // @Ignore()
    public exists: boolean;

    // @Ignore()
    public image: boolean;

    public constructor(init?: Partial<File>) { (Object as any).assign(this, init); }
}

export class SaeDocumentForm
{
    public name: string;
    public file: File;

    public constructor(init?: Partial<SaeDocumentForm>) { (Object as any).assign(this, init); }
}

export enum SaeNotificationType
{
    NotifyInvestigator = 1,
    NotifyMedicalReviewer = 2,
}

export class SaeNotificationForm
{
    public recipients: number[];
    public ccRecipients: number[];
    public notes: string;
    public notifyStudyChair?: boolean;
    public notifySponsor?: boolean;
    public attachments: SaeDocumentForm[];
    public notificationType: SaeNotificationType;

    public constructor(init?: Partial<SaeNotificationForm>) { (Object as any).assign(this, init); }
}

export interface IScreeningSummaryGetSingleById extends IGet
{
    id?: number;
}

export interface IScreeningSummaryGetCollection extends IGet
{
    institutionId?: number;
    year?: number;
}

export interface IScreeningSummaryGetCollectionByInstitutionId extends IGet
{
    institutionId?: number;
}

export class ScreeningSummary implements IScreeningSummary
{
    public id?: number;
    public institutionId?: number;
    public institutionName: string;
    public summaryDate?: string;
    public endDate?: string;
    public preScreened?: number;
    public neuroendocrine?: number;
    public cprc?: number;
    public psaOrTestosterone?: number;
    public psaDoublingTime?: number;
    public dectectableMets?: number;
    public performanceStatus?: number;
    public organMarrowFunction?: number;
    public priorTreatment?: number;
    public priorMalignancy?: number;
    public incompatibleComorbities?: number;
    public unableToComplyWithProtocol?: number;
    public siteRestrictions?: number;
    public declined?: number;
    public numberConsented?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ScreeningSummary>) { (Object as any).assign(this, init); }
}

export interface ILookupGetCollectionByFormDefinitionId extends IGet
{
    formDefinitionId?: number;
}

export interface IEventDefinitionGetSingleById extends IGet
{
    id?: number;
}

export interface IEventDefinitionGetSingleByCode extends IGet
{
    code: string;
}

export interface IEventDefinitionGetCollection extends IGet
{
}

export interface IFormDefinitionGetSingleById extends IGet
{
    id?: number;
}

export interface IFormDefinitionGetSingleByCode extends IGet
{
    code: string;
}

export interface IFormDefinitionGetCollection extends IGet
{
}

export interface IPatientStateGetSingleById extends IGet
{
    id?: number;
}

export interface IPatientStateGetCollection extends IGet
{
}

export interface ITreatmentGetSingleById extends IGet
{
    id?: number;
}

export interface ITreatmentGetCollection extends IGet
{
}

export interface IMasterGroupGetSingleById extends IGet
{
    id?: number;
}

export interface IMasterGroupGetSingleByCode extends IGet
{
    code: string;
}

export interface IMasterGroupGetCollection extends IGet
{
}

export interface ICollaboratingGroupGetSingleById extends IGet
{
    id?: number;
}

export interface ICollaboratingGroupGetSingleByCode extends IGet
{
    code: string;
}

export interface ICollaboratingGroupGetCollection extends IGet
{
}

export interface ICollaboratingGroupGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
}

export interface ICollaboratingGroupGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
}

export interface ICountryGetSingleById extends IGet
{
    id?: number;
}

export interface ICountryGetSingleByCode extends IGet
{
    code: string;
}

export interface ICountryGetCollection extends IGet
{
}

export interface ICountryGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
}

export interface ICountryGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
}

export interface ICountryGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
}

export interface ICountryGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
}

export interface IInstitutionGetSingleById extends IGet
{
    id?: number;
}

export interface IInstitutionGetSingleByCode extends IGet
{
    code: string;
}

export interface IInstitutionGetCollection extends IGet
{
}

export interface IInstitutionGetCollectionByCountryId extends IGet
{
    countryId?: number;
}

export interface IInstitutionGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
    countryId?: number;
}

export interface IInstitutionGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
    countryId?: number;
}

export interface IInstitutionGetCollectionByCountryCode extends IGet
{
    countryCode: string;
}

export interface IInstitutionGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
    countryCode: string;
}

export interface IInstitutionGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
    countryCode: string;
}

export interface IPatientGetSingleById extends IGet
{
    id?: number;
}

export interface IPatientGetSingleByStudyNumber extends IGet
{
    studyNumber: string;
}

export interface IPatientGetValidationById extends IGet
{
    id?: number;
}

export interface IPatientGetValidationByStudyNumber extends IGet
{
    studyNumber: string;
}

export interface IPatientGetCollection extends IGet
{
    patientStateIds: number[];
}

export interface IPatientGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCountryId extends IGet
{
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByInstitutionId extends IGet
{
    institutionId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCountryCode extends IGet
{
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByInstitutionCode extends IGet
{
    institutionCode: string;
    patientStateIds: number[];
}

export class Patient implements IOpenClinicaPatient, IRandomisationPatient
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Data.Institution)")
    public institutionId?: number;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.PatientState)")
    public patientStateId?: number;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.PatientState)")
    public previousPatientStateId?: number;

    public patientStateComment: string;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.Treatment)")
    public treatmentId?: number;

    public studyNumber: string;
    public institutionCode: string;
    public randomisationNumber: string;
    public randomisationDate?: string;
    public initials: string;
    public dateOfBirth?: string;
    public exportedToOpenClinica?: string;
    public waivered?: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Patient>) { (Object as any).assign(this, init); }
}

export interface IEventGetSingleById extends IGet
{
    id?: number;
}

export interface IEventGetSingleByIds extends IGet
{
    institutionId?: number;
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
}

export interface IEventGetSingleByCodes extends IGet
{
    institutionCode: string;
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
}

export interface IEventGetCollection extends IGet
{
}

export interface IEventGetCollectionByPatientId extends IGet
{
    patientId?: number;
}

export interface IEventGetCollectionByPatientStudyNumber extends IGet
{
    patientStudyNumber: string;
}

export class Event implements IEvent
{
    public id?: number;
    public patientId?: number;
    public eventDefinitionId?: number;
    public repeat?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Event>) { (Object as any).assign(this, init); }
}

export interface IFormGetSingleById extends IGet
{
    id?: number;
}

export interface IFormGetSingleByIds extends IGet
{
    institutionId?: number;
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
    formRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
    createForm?: boolean;
}

export interface IFormGetSingleByCodes extends IGet
{
    institutionCode: string;
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
    formRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
    createForm?: boolean;
}

export interface IFormGetCollection extends IGet
{
}

export interface IFormGetCollectionByIds extends IGet
{
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
}

export interface IFormGetCollectionByCodes extends IGet
{
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
}

export class AdverseEvent implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public ctcaeTermId?: number;
    public ctcaeGradeId?: number;
    public ctcaeSpecification: string;
    public startDate: string;
    public ongoing?: boolean;
    public stopDate: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AdverseEvent>) { (Object as any).assign(this, init); }
}

export class AdverseEventForm implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public participantExperiencingAnyAe?: number;
    public adverseEvents: AdverseEvent[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<AdverseEventForm>) { (Object as any).assign(this, init); }
}

export class BloodsClinical implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public assessmentDate?: string;
    public haemoglobin?: number;
    public neutrophilCount?: number;
    public lymphocyteCount?: number;
    public plateletCount?: number;
    public alt?: number;
    public altUln?: number;
    public ast?: number;
    public astUln?: number;
    public totalBilirubin?: number;
    public totalBilirubinUln?: number;
    public participantHasGilbertDisease?: number;
    public ldh?: number;
    public albumin?: number;
    public urea?: number;
    public creatinine?: number;
    public creatinineUln?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<BloodsClinical>) { (Object as any).assign(this, init); }
}

export class ClinicalAssessment implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public dateOfClinicalAssessment?: string;
    public bloodPressureSystolic?: number;
    public bloodPressureDiastolic?: number;
    public receivedGrowthFactorsOrBloodTransfusions?: number;
    public lastBloodTransfusionNotApplicable?: boolean;
    public dateOfLastBloodTransfusion?: string;
    public lastGrowthFactorNotApplicable?: boolean;
    public dateOfLastGrowthFactor?: string;
    public participantTreatedWithSecondGeneration?: number;
    public participantUsedOestrogens?: number;
    public participantHadAnyRadiotherapy?: number;
    public participantStartedTreatment?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<ClinicalAssessment>) { (Object as any).assign(this, init); }
}

export class ConcomitantMedication implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public medicationName: string;
    public indication?: number;
    public indicationOther: string;
    public startDate: string;
    public ongoing?: boolean;
    public stopDate: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConcomitantMedication>) { (Object as any).assign(this, init); }
}

export class ConcomitantMedicationForm implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public participantTakingAnyMedications?: number;
    public concomitantMedication: ConcomitantMedication[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<ConcomitantMedicationForm>) { (Object as any).assign(this, init); }
}

export class DiseaseAssessment implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public dateOfCtScan?: string;
    public isPrimaryCancerDetectable?: number;
    public anyExistingPathologicAdenopathy?: number;
    public measurementOfShortAxis?: number;
    public anyDetectableMetastasesOnCtScan?: number;
    public dateOfWbbsScan?: string;
    public anyDetectableMetastasesOnWbbsScan?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<DiseaseAssessment>) { (Object as any).assign(this, init); }
}

export class EligibilityConfirmation implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eligibilityConfirmedBy: string;
    public dateConfirmed?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<EligibilityConfirmation>) { (Object as any).assign(this, init); }
}

export class General implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public patientInitials: string;
    public dateOfBirth?: string;
    public ageAtConsent?: number;
    public medicalOncologistName: string;
    public radiationOncologistName: string;
    public dateOfConsent?: string;
    public indigenousStatus?: number;
    public countryOfBirth: string;
    public primarySpokenLanguage: string;
    public languageSpokenAsAChild: string;
    public postcode: string;
    public postcodeNotApplicable?: boolean;
    // @Ignore()
    public dateRandomised?: string;

    // @Ignore()
    public treatmentId?: number;

    // @Ignore()
    public randNo: string;

    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<General>) { (Object as any).assign(this, init); }
}

export class MedicalHistory implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public condition: string;
    public startDate: string;
    public ongoing?: boolean;
    public stopDate: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MedicalHistory>) { (Object as any).assign(this, init); }
}

export class MedicalHistoryForm implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public participantHasAnyRelevantMedicalHistory?: number;
    public medicalHistory: MedicalHistory[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<MedicalHistoryForm>) { (Object as any).assign(this, init); }
}

export class PreviousPsaAndTestosteroneResult implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public dateOfPsa?: string;
    public psaLevel?: number;
    public dateOfSerumTestosterone?: string;
    public serumTestosterone?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResult>) { (Object as any).assign(this, init); }
}

export class ProstateCancerDetails implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public anyConfirmedAdenoCarcinoma?: number;
    public anyNeuroendocrineDifferentiation?: number;
    public anySmallCellFeatures?: number;
    public previousAntiAndrogenUse?: number;
    public dateOfAntiAndrogenWithdrawal?: string;
    public previousProstatectomy?: number;
    public bilateralOrchiectomy?: number;
    public dateOfBilateralOrchiectomy?: string;
    public participantCurrentlyOnGnrh?: number;
    public gnrhTherapyStartDate?: string;
    public gnrhTherapyStopDate?: string;
    public dateOfCrpcConfirmed?: string;
    public participantPsaDoublingTime?: number;
    public previousPsaAndTestosteroneResults: PreviousPsaAndTestosteroneResult[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<ProstateCancerDetails>) { (Object as any).assign(this, init); }
}

export class PsmaPet implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public dateOfPsmaPetScan?: string;
    public numberOfSitesOfActivity?: number;
    public anyExtraPelvicDisease?: number;
    public bone?: number;
    public pelvicNodal?: number;
    public extraPelvicNodal?: number;
    public visceral?: number;
    public prostate?: number;
    public prostateBed?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<PsmaPet>) { (Object as any).assign(this, init); }
}

export class SourceDocument implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public name: string;
    public fileTypeId?: number;
    public file: File;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SourceDocument>) { (Object as any).assign(this, init); }
}

export class SourceDocuments implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public sourceDocument: SourceDocument[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<SourceDocuments>) { (Object as any).assign(this, init); }
}

export enum Permission
{
    OpmsAccess = 1,
    OpmsInstitutionView = 2,
    OpmsAdminister = 3,
    OpmsPatientView = 4,
    SystemAdminister = 5,
    OpmsPatientUpdate = 6,
    OpmsMasterGroupAdminister = 7,
    OpmsCoordinatingGroupAdminister = 8,
    PathologyTestAdminister = 9,
    BlockShipmentDetailsView = 10,
    OpmsPatientRegister = 11,
    OpmsPatientRandomise = 12,
    SaeView = 13,
    SaeReview = 14,
    ScreeningLogView = 15,
    ScreeningLogUpdate = 16,
    TeleformView = 17,
    TeleformManage = 18,
    EcrfView = 19,
    SaeAdminister = 20,
    MedicalReviewAccess = 21,
    MedicalReviewPerform = 22,
    MedicalReviewAdminister = 23,
    HistopathologyAccess = 24,
    DrugManagementView = 25,
    DrugManagementAdminister = 26,
    DrugManagementDispenseDrug = 27,
    OpmsProView = 28,
    OpmsProUpdate = 29,
    OpmsProAdminister = 30,
    OpmsPatientImpersonate = 31,
    OpmsPatientAccountManagement = 32,
    OpmsCrfView = 33,
    OpmsCrfUpdate = 34,
    OpmsEventView = 35,
    OpmsEventUpdate = 36,
    OpmsConsentAdminister = 37,
    OpmsCoordinatingGroupContactView = 38,
    OpmsSaeInvestigatorNotify = 39,
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class EmailAddress
{
    public displayName: string;
    public address: string;

    public constructor(init?: Partial<EmailAddress>) { (Object as any).assign(this, init); }
}

export enum PatientStateType
{
    Prerandomised = 1,
    UnderReview = 2,
    Randomised = 3,
    Ineligible = 7,
    Waivered = 8,
    Removed = 9,
}

export enum EventDefinitionType
{
    Randomisation = 1,
}

export enum FormDefinitionType
{
    General = 1,
    ProstateCancerDetails = 2,
    PreviousPsaAndTestosteroneResult = 3,
    ClinicalAssessment = 4,
    MedicalHistoryForm = 5,
    MedicalHistory = 6,
    AdverseEventForm = 7,
    AdverseEvent = 8,
    ConcomitantMedicationForm = 9,
    ConcomitantMedication = 10,
    DiseaseAssessment = 11,
    PsmaPet = 12,
    BloodsClinical = 13,
    EligibilityConfirmation = 14,
    SourceDocuments = 15,
    SourceDocument = 16,
}

export enum TreatmentType
{
    Arm1 = 1,
    Arm2 = 2,
}

export enum LookupYesNoType
{
    No = 0,
    Yes = 1,
}

export enum LookupIndicationType
{
    ProstateCancer = 1,
    ConcomitantSystemic = 2,
    AdverseEventTreatment = 3,
    Other = 5,
}

export enum LookupUntypedType
{
    NoType = 1,
}

export enum LookupSourceDocumentType
{
    ConsentForm = 1,
    CtScanReport = 2,
    WbbsScanReport = 3,
    PsmaScanReport = 4,
    BloodReportClinical = 5,
    BloodReportPsa = 6,
    EligibilityConfirmation = 7,
    HistologyReport = 8,
    Other = 9,
}

export class IdentityServerExceptionInfo
{
    public message: string;
    public stackTrace: string;
    public innerException: IdentityServerExceptionInfo;

    public constructor(init?: Partial<IdentityServerExceptionInfo>) { (Object as any).assign(this, init); }
}

export class IdentityServerDiagnostic
{
    public test: string;
    public pass: boolean;
    public exception: IdentityServerExceptionInfo;
    public values: { [index: string]: Object; };

    public constructor(init?: Partial<IdentityServerDiagnostic>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate?: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export class Pharmacist
{
    public id?: number;
    public name: string;

    public constructor(init?: Partial<Pharmacist>) { (Object as any).assign(this, init); }
}

export class PharmacyBatchDrugRemaining
{
    public pharmacy: Pharmacy;
    public batch: Batch;
    public drugRemaining?: number;

    public constructor(init?: Partial<PharmacyBatchDrugRemaining>) { (Object as any).assign(this, init); }
}

export enum ValidationResultType
{
    Valid = 1,
    Ineligible = 2,
    Error = 3,
    StratificationFailure = 4,
    Critical = 5,
}

export enum ValidationErrorType
{
    Warning = 1,
    Ineligible = 2,
    Normal = 3,
    StratificationFailure = 4,
    Critical = 5,
}

export class SaeFormValidationError implements ISaeFormValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<SaeFormValidationError>) { (Object as any).assign(this, init); }
}

export class SaeFormValidationResult
{
    public result: ValidationResultType;
    public errors: SaeFormValidationError[];

    public constructor(init?: Partial<SaeFormValidationResult>) { (Object as any).assign(this, init); }
}

export enum SaeMedicalReviewStatus
{
    NotNotified = 0,
    Pending = 1,
    Reviewed = 3,
}

export class LookupItem implements ILookupItem
{
    public id?: number;
    public value: string;
    public order?: number;
    public type?: number;

    public constructor(init?: Partial<LookupItem>) { (Object as any).assign(this, init); }
}

export class Lookup
{
    public propertyName: string;
    public items: LookupItem[];

    public constructor(init?: Partial<Lookup>) { (Object as any).assign(this, init); }
}

export class Sae<TSaeForm, TPatient>
{
    public patient: TPatient;
    public form: TSaeForm;
    public status: SaeStatus;
    public ecrfs: TSaeForm[];
    public saeFormItems: { [index: string]: string; };
    public medicalReview: MedicalReview;
    public medicalReviewStatus: SaeMedicalReviewStatus;
    public actionHistory: WebAction[];
    public lookups: Lookup[];

    public constructor(init?: Partial<Sae<TSaeForm, TPatient>>) { (Object as any).assign(this, init); }
}

export class ScreeningSummaryFormValidationError implements IScreeningSummaryFormValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<ScreeningSummaryFormValidationError>) { (Object as any).assign(this, init); }
}

export class ScreeningSummaryFormValidationResult
{
    public result: ValidationResultType;
    public errors: ScreeningSummaryFormValidationError[];

    public constructor(init?: Partial<ScreeningSummaryFormValidationResult>) { (Object as any).assign(this, init); }
}

export interface IFileUploadTemporary
{
    guid: string;
    name: string;
    extension: string;
    fileSize?: number;
    contentType: string;
    image: boolean;
}

export enum UserType
{
    TrialPersonnel = 1,
    Patient = 2,
}

export class FormMetadata
{
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public name: string;
    public propertyName: string;
    public title: string;
    public instructions: string;

    public constructor(init?: Partial<FormMetadata>) { (Object as any).assign(this, init); }
}

export class EventDefinitionFormDefinitionState implements IEventDefinitionFormDefinitionState
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinitionFormDefinition)")
    public eventDefinitionFormDefinitionId?: number;

    public patientStateId?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;

    public constructor(init?: Partial<EventDefinitionFormDefinitionState>) { (Object as any).assign(this, init); }
}

export class EventDefinitionFormDefinition
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public repeating?: boolean;
    public minimumRepeat?: number;
    public maximumRepeat?: number;
    public order?: number;
    public states: EventDefinitionFormDefinitionState[];

    public constructor(init?: Partial<EventDefinitionFormDefinition>) { (Object as any).assign(this, init); }
}

export class EventDefinitionState implements IEventDefinitionState
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    public patientStateId?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;

    public constructor(init?: Partial<EventDefinitionState>) { (Object as any).assign(this, init); }
}

export class EventDefinitionErrorMessage implements IEventDefinitionErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<EventDefinitionErrorMessage>) { (Object as any).assign(this, init); }
}

export class EventDefinition
{
    public id?: number;
    public name: string;
    public code: string;
    public repeating?: boolean;
    public minimumRepeat?: number;
    public maximumRepeat?: number;
    public order?: number;
    public formDefinitions: EventDefinitionFormDefinition[];
    public states: EventDefinitionState[];
    public errorMessages: EventDefinitionErrorMessage[];

    public constructor(init?: Partial<EventDefinition>) { (Object as any).assign(this, init); }
}

export enum FormType
{
    Crf = 1,
    Pro = 2,
    Staff = 3,
}

export class FormDefinitionPropertyErrorMessage implements IFormDefinitionPropertyErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinitionProperty)")
    public formDefinitionPropertyId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormDefinitionPropertyErrorMessage>) { (Object as any).assign(this, init); }
}

export class FormDefinitionProperty
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public propertyName: string;
    public label: string;
    public detailedLabel: string;
    public errorMessages: FormDefinitionPropertyErrorMessage[];

    public constructor(init?: Partial<FormDefinitionProperty>) { (Object as any).assign(this, init); }
}

export class FormDefinition
{
    public id?: number;
    public code: string;
    public type?: FormType;
    public name: string;
    public instructions: string;
    public patientInstructions: string;
    public properties: FormDefinitionProperty[];
    // @Ignore()
    public subformProperties: { [index: string]: FormDefinitionProperty[]; };

    public constructor(init?: Partial<FormDefinition>) { (Object as any).assign(this, init); }
}

export class PatientState implements IPatientState
{
    public id?: number;
    public name: string;
    public order?: number;

    public constructor(init?: Partial<PatientState>) { (Object as any).assign(this, init); }
}

export class Treatment implements ITreatment
{
    public id?: number;
    public name: string;

    public constructor(init?: Partial<Treatment>) { (Object as any).assign(this, init); }
}

export class MasterGroup implements IMasterGroup
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MasterGroup>) { (Object as any).assign(this, init); }
}

export class CollaboratingGroup implements ICollaboratingGroup
{
    public id?: number;
    public masterGroupId?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CollaboratingGroup>) { (Object as any).assign(this, init); }
}

export class Country implements ICountry
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class Institution implements IInstitution
{
    public id?: number;
    public countryId?: number;
    public masterGroupId?: number;
    public collaboratingGroupId?: number;
    public code: string;
    public name: string;
    public enabled?: boolean;
    public activatedDate?: string;
    public recruitmentDisabled?: boolean;
    public eConsentActivationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Institution>) { (Object as any).assign(this, init); }
}

export class PatientValidationError implements IPatientValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<PatientValidationError>) { (Object as any).assign(this, init); }
}

export class EventValidationError implements IEventValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<EventValidationError>) { (Object as any).assign(this, init); }
}

export class FormValidationError implements IFormValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormValidationError>) { (Object as any).assign(this, init); }
}

export class FormValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public formId?: number;
    public formDefinitionId?: number;
    public formRepeat?: number;
    public completed?: boolean;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public errors: FormValidationError[];

    public constructor(init?: Partial<FormValidationResult>) { (Object as any).assign(this, init); }
}

export class EventValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public eventId?: number;
    public eventDefinitionId?: number;
    public eventRepeat?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public errors: EventValidationError[];
    public formResults: FormValidationResult[];

    public constructor(init?: Partial<EventValidationResult>) { (Object as any).assign(this, init); }
}

export class PatientValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public patientId?: number;
    public patientDefinitionId?: number;
    public patientRepeat?: number;
    public errors: PatientValidationError[];
    public eventResults: EventValidationResult[];

    public constructor(init?: Partial<PatientValidationResult>) { (Object as any).assign(this, init); }
}

export interface IScreeningSummary extends IDataModel
{
    institutionId?: number;
    institutionName: string;
    summaryDate?: string;
    endDate?: string;
}

export interface IDataModel extends IModel, IHasAudit
{
}

export interface IModel
{
    id?: number;
}

export interface IHasAudit
{
    enteredDate?: string;
    enteredBy: string;
    modifiedDate?: string;
    modifiedBy: string;
}

export interface IOpenClinicaPatient extends IPatient
{
    exportedToOpenClinica?: string;
}

export interface IPatient extends IDataModel, IHasInstitutionId, IHasPatientStateId, IHasTreatmentId
{
    studyNumber: string;
}

export interface IHasInstitutionId
{
    institutionId?: number;
}

export interface IHasPatientStateId
{
    patientStateId?: number;
}

export interface IHasTreatmentId
{
    treatmentId?: number;
}

export interface IRandomisationPatient
{
    randomisationNumber: string;
    randomisationDate?: string;
}

export interface IEvent extends IDataModel, IHasPatientId, IHasEventDefinitionId
{
    repeat?: number;
}

export interface IHasPatientId
{
    patientId?: number;
}

export interface IHasEventDefinitionId
{
    eventDefinitionId?: number;
}

export interface IForm extends IDataModel, IHasPatientId, IHasEventId, IHasParentFormDefinitionId, IHasParentFormId
{
    repeat?: number;
}

export interface IHasEventId
{
    eventId?: number;
}

export interface IHasParentFormDefinitionId
{
    parentFormDefinitionId?: number;
}

export interface IHasParentFormId
{
    parentFormId?: number;
}

export interface IOpenClinicaForm extends IForm
{
    exportedToOpenClinica?: string;
}

export interface ISaeFormValidationError extends IValidationError
{
    property: string;
}

export interface ISaeFormValidationResult extends IValidationResult
{
    errors: ISaeFormValidationError[];
}

export interface IValidationError
{
    message: string;
    detailedMessage: string;
    code: string;
    type?: ValidationErrorType;
}

export interface IValidationResult
{
    result: ValidationResultType;
    errors: IValidationError[];
}

export class RelevantMedicationHistory implements ISaeSubform
{
    public ordinal: number;
    public rmhDiseaseConditionProcedure: string;
    public rmhMedicationStartDate?: string;
    public rmhMedicationStopDate?: string;
    public rmhOngoing?: boolean;

    public constructor(init?: Partial<RelevantMedicationHistory>) { (Object as any).assign(this, init); }
}

export class EventTreatment implements ISaeSubform
{
    public ordinal: number;
    public etProcedure: string;
    public etDate?: string;
    public etResult: string;
    public etPending?: boolean;

    public constructor(init?: Partial<EventTreatment>) { (Object as any).assign(this, init); }
}

export class PrescribedMedications implements ISaeSubform
{
    public ordinal: number;
    public pmNameOfMedication: string;
    public pmIndicationOfMedication: string;
    public pmStartDate?: string;
    public pmStopDate?: string;
    public pmOngoing?: boolean;
    public pmDose?: number;
    public pmUnit: string;
    public pmRoute: string;
    public rmFrequency: string;
    public rmFrequencyOther: string;

    public constructor(init?: Partial<PrescribedMedications>) { (Object as any).assign(this, init); }
}

export class SaeForm implements ISaeForm
{
    public id?: number;
    public patientId?: number;
    public saeNumber?: number;
    public followUpNumber?: number;
    public reportingInvestigator: string;
    public ctcaeTerm: string;
    public ctcaeGrade?: number;
    public onsetDate?: string;
    public resultsInDeath: string;
    public lifeThreatening: string;
    public inPatientHospitalisation: string;
    public admissionDate?: string;
    public dischargeDate?: string;
    public dischargeDateOngoing?: boolean;
    public disabilityIncapacity: string;
    public congenitalAnomalyBirthDefect: string;
    public importantMedicalEvent: string;
    public relationshipToDarolutamide: string;
    public relationshipToRadiotherapy: string;
    public relationshipToRadium223: string;
    public progressiveDisease: string;
    public trialProcedure: string;
    public nameOfProcedure: string;
    public indicationOfProcedure: string;
    public procedureDate?: string;
    public concurrentDisorder: string;
    public concurrentDisorderSpecify: string;
    public concomitantMedication: string;
    public conComNameOfMedication: string;
    public conComIndication: string;
    public conComStartDate?: string;
    public conComStopDate?: string;
    public conComOnGoing?: boolean;
    public conComRoute: string;
    public conComDose?: number;
    public conComUnit: string;
    public conComUnitOther: string;
    public conComFrequency: string;
    public conComFrequencyOther: string;
    public other: string;
    public otherSpecify: string;
    public reactionAbateAfterStopping: string;
    public reactionReappeared: string;
    public eventDescription: string;
    public receivedDarolutamide?: boolean;
    public receivedRadiotherapy?: boolean;
    public receivedRadium223?: boolean;
    public startDateDarolutamide?: string;
    public lastDarolutamideBeforeEvent?: string;
    public darolutamideDoseOnEvent: string;
    public darolutamideKitNumber: string;
    public darolutamideBatchNumber: string;
    public darolutamideBottleExpiry?: string;
    public resultingDarolutamideModification: string;
    public startDateRadiotherapy?: string;
    public lastRadiotherapyBeforeEvent?: string;
    public radiotherapyDoseOnEvent?: number;
    public resultingRadiotherapyModification: string;
    public startDateRadium223?: string;
    public lastRadium223BeforeEvent?: string;
    public radium223DoseOnEvent?: number;
    public resultingRadium223Modification: string;
    public eventStatus: string;
    public describeSequelae: string;
    public causeOfDeath: string;
    public dateOfAutopsy?: string;
    public autopsyNA?: boolean;
    public outcomeUnknownReason: string;
    public dateEventResolved?: string;
    public relevantMedicationHistory: RelevantMedicationHistory[];
    public eventTreatment: EventTreatment[];
    public prescribedMedications: PrescribedMedications[];
    public dateCreated?: string;
    public dateUpdated?: string;
    public unassignedProperties: { [index: string]: Object; };
    public inconvertableProperties: { [index: string]: string; };

    public constructor(init?: Partial<SaeForm>) { (Object as any).assign(this, init); }
}

export interface ISaeForm extends IModel
{
    patientId?: number;
    saeNumber?: number;
    followUpNumber?: number;
    eventStatus: string;
    dateCreated?: string;
    dateUpdated?: string;
    unassignedProperties: { [index: string]: Object; };
    inconvertableProperties: { [index: string]: string; };
}

export interface IScreeningSummaryFormValidationError extends IValidationError
{
    property: string;
}

export interface IScreeningSummaryFormValidationResult extends IValidationResult
{
    errors: IScreeningSummaryFormValidationError[];
}

export interface ILookupItem
{
    id?: number;
    value: string;
    order?: number;
    type?: number;
}

export interface ILookup
{
    propertyName: string;
    items: ILookupItem[];
}

export interface IEventDefinitionFormDefinitionState extends IConfigurationModel, IHasEventDefinitionFormDefinitionId, IHasPatientStateId
{
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
}

export interface IEventDefinitionFormDefinition extends IConfigurationModel, IHasEventDefinitionId, IHasFormDefinitionId
{
    repeating?: boolean;
    minimumRepeat?: number;
    maximumRepeat?: number;
    order?: number;
    states: IEventDefinitionFormDefinitionState[];
}

export interface IEventDefinitionState extends IConfigurationModel, IHasEventDefinitionId, IHasPatientStateId
{
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
}

export interface IEventDefinitionErrorMessage extends IConfigurationModel, IHasEventDefinitionId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IEventDefinition extends IConfigurationModel
{
    name: string;
    code: string;
    repeating?: boolean;
    minimumRepeat?: number;
    maximumRepeat?: number;
    order?: number;
    formDefinitions: IEventDefinitionFormDefinition[];
    states: IEventDefinitionState[];
    errorMessages: IEventDefinitionErrorMessage[];
}

export interface IConfigurationModel extends IModel
{
}

export interface IFormDefinitionPropertyErrorMessage extends IConfigurationModel, IHasFormDefinitionPropertyId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IFormDefinitionProperty extends IConfigurationModel, IHasFormDefinitionId
{
    propertyName: string;
    label: string;
    detailedLabel: string;
    errorMessages: IFormDefinitionPropertyErrorMessage[];
}

export interface IFormDefinition extends IConfigurationModel
{
    type?: FormType;
    name: string;
    code: string;
    instructions: string;
    patientInstructions: string;
    properties: IFormDefinitionProperty[];
    subformProperties: { [index: string]: IFormDefinitionProperty[]; };
}

export interface IPatientState extends IConfigurationModel
{
    name: string;
    order?: number;
}

export interface ITreatment extends IConfigurationModel
{
    name: string;
}

export interface IMasterGroup extends IDataModel
{
    name: string;
    code: string;
}

export interface ICollaboratingGroup extends IDataModel, IHasMasterGroupId
{
    name: string;
    code: string;
}

export interface IHasMasterGroupId
{
    masterGroupId?: number;
}

export interface ICountry extends IDataModel
{
    name: string;
    code: string;
}

export interface IInstitution extends IDataModel, IHasMasterGroupId, IHasCollaboratingGroupId, IHasCountryId
{
    name: string;
    code: string;
    enabled?: boolean;
    activatedDate?: string;
    recruitmentDisabled?: boolean;
    eConsentActivationDate?: string;
}

export interface IHasCollaboratingGroupId
{
    collaboratingGroupId?: number;
}

export interface IHasCountryId
{
    countryId?: number;
}

export interface IPatientValidationError extends IValidationError
{
}

export interface IEventValidationError extends IValidationError
{
}

export interface IFormValidationError extends IValidationError
{
    property: string;
}

export interface IFormValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    formId?: number;
    formDefinitionId?: number;
    formRepeat?: number;
    completed?: boolean;
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    errors: IFormValidationError[];
}

export interface IEventValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    eventDefinitionId?: number;
    eventId?: number;
    eventRepeat?: number;
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    errors: IEventValidationError[];
    formResults: IFormValidationResult[];
}

export interface IPatientValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    patientId?: number;
    errors: IPatientValidationError[];
    eventResults: IEventValidationResult[];
}

export interface IFile extends IDataModel, IHasPatientId, IHasFormDefinitionId, IHasFormId, IFileUpload
{
    propertyName: string;
}

export interface IHasFormDefinitionId
{
    formDefinitionId?: number;
}

export interface IHasFormId
{
    formId?: number;
}

export interface IFileUpload
{
    extension: string;
    fileSize?: number;
    contentType: string;
    exists: boolean;
    image: boolean;
    upload: IFileUploadTemporary;
}

export interface ISaeSubform
{
    ordinal: number;
}

export interface IHasEventDefinitionFormDefinitionId
{
    eventDefinitionFormDefinitionId?: number;
}

export interface IHasFormDefinitionPropertyId
{
    formDefinitionPropertyId?: number;
}

export class SendRegistrationEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendRegistrationEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendRandomisationEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendRandomisationEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendPatientIneligibleEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPatientIneligibleEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendPatientSubmittedForReviewEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPatientSubmittedForReviewEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendPatientReassignedToSiteEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPatientReassignedToSiteEmailResponse>) { (Object as any).assign(this, init); }
}

export class QueuePatientOcTransferResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<QueuePatientOcTransferResponse>) { (Object as any).assign(this, init); }
}

export class QueuePatientsOcTransferResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<QueuePatientsOcTransferResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeNotifyInvestigatorEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeNotifyInvestigatorEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeInvestigatorReviewEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeInvestigatorReviewEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendNewSaeEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendNewSaeEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendUnapprovedSaeEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendUnapprovedSaeEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendUnresolvedSaeEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendUnresolvedSaeEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendDrugShipmentOrderedEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendDrugShipmentOrderedEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendDrugShipmentGenerationEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendDrugShipmentGenerationEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendStagnantDrugOrdersEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendStagnantDrugOrdersEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendStagnantDrugShipmentsEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendStagnantDrugShipmentsEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendExpiringStockEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendExpiringStockEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendLowStockEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendLowStockEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendLateDispensationEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendLateDispensationEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendDrugDispensationEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendDrugDispensationEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendDrugShipmentQuarantinedEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendDrugShipmentQuarantinedEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendDrugShipmentReceivedEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendDrugShipmentReceivedEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendDrugShipmentDispatchedEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendDrugShipmentDispatchedEmailResponse>) { (Object as any).assign(this, init); }
}

export class GetEnumerationTypesResponse
{
    public patientStateType?: PatientStateType;
    public eventDefinitionType?: EventDefinitionType;
    public formDefinitionType?: FormDefinitionType;
    public treatmentType?: TreatmentType;
    public lookupYesNoType?: LookupYesNoType;
    public lookupIndicationType?: LookupIndicationType;
    public lookupUntypedType?: LookupUntypedType;
    public lookupSourceDocumentType?: LookupSourceDocumentType;

    public constructor(init?: Partial<GetEnumerationTypesResponse>) { (Object as any).assign(this, init); }
}

export class IdentityServerDiagnosticsResponse
{
    public responseStatus: ResponseStatus;
    public diagnostics: IdentityServerDiagnostic[];

    public constructor(init?: Partial<IdentityServerDiagnosticsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

export class GetBatchesResponse
{
    public responseStatus: ResponseStatus;
    public batches: Batch[];

    public constructor(init?: Partial<GetBatchesResponse>) { (Object as any).assign(this, init); }
}

export class GetBatchByIdResponse
{
    public responseStatus: ResponseStatus;
    public batch: Batch;

    public constructor(init?: Partial<GetBatchByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetBatchesByIdsResponse
{
    public responseStatus: ResponseStatus;
    public batches: Batch[];

    public constructor(init?: Partial<GetBatchesByIdsResponse>) { (Object as any).assign(this, init); }
}

export class GetBatchesExpiringAtSpecifiedDateResponse
{
    public responseStatus: ResponseStatus;
    public batches: Batch[];

    public constructor(init?: Partial<GetBatchesExpiringAtSpecifiedDateResponse>) { (Object as any).assign(this, init); }
}

export class SetBatchResponse
{
    public responseStatus: ResponseStatus;
    public batch: Batch;

    public constructor(init?: Partial<SetBatchResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchesResponse
{
    public responseStatus: ResponseStatus;
    public depotBatches: DepotBatch[];

    public constructor(init?: Partial<GetDepotBatchesResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchByIdResponse
{
    public responseStatus: ResponseStatus;
    public depotBatch: DepotBatch;

    public constructor(init?: Partial<GetDepotBatchByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchesByDepotIdResponse
{
    public responseStatus: ResponseStatus;
    public depotBatches: DepotBatch[];

    public constructor(init?: Partial<GetDepotBatchesByDepotIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchesByPharmacyIdResponse
{
    public responseStatus: ResponseStatus;
    public depotBatches: DepotBatch[];

    public constructor(init?: Partial<GetDepotBatchesByPharmacyIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchesByDrugShipmentIdResponse
{
    public responseStatus: ResponseStatus;
    public depotBatches: DepotBatch[];

    public constructor(init?: Partial<GetDepotBatchesByDrugShipmentIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchesByBatchIdsResponse
{
    public responseStatus: ResponseStatus;
    public depotBatches: DepotBatch[];

    public constructor(init?: Partial<GetDepotBatchesByBatchIdsResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchesWithLowStockByDepotIdsResponse
{
    public responseStatus: ResponseStatus;
    public depotBatches: DepotBatch[];

    public constructor(init?: Partial<GetDepotBatchesWithLowStockByDepotIdsResponse>) { (Object as any).assign(this, init); }
}

export class SetDepotBatchResponse
{
    public responseStatus: ResponseStatus;
    public depotBatch: DepotBatch;

    public constructor(init?: Partial<SetDepotBatchResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotsResponse
{
    public responseStatus: ResponseStatus;
    public depots: Depot[];

    public constructor(init?: Partial<GetDepotsResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotByIdResponse
{
    public responseStatus: ResponseStatus;
    public depot: Depot;

    public constructor(init?: Partial<GetDepotByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotsByDrugUnderThresholdResponse
{
    public responseStatus: ResponseStatus;
    public depotIds: number[];

    public constructor(init?: Partial<GetDepotsByDrugUnderThresholdResponse>) { (Object as any).assign(this, init); }
}

export class SetDepotResponse
{
    public responseStatus: ResponseStatus;
    public depot: Depot;

    public constructor(init?: Partial<SetDepotResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugDispensationsResponse
{
    public responseStatus: ResponseStatus;
    public drugDispensations: DrugDispensation[];

    public constructor(init?: Partial<GetDrugDispensationsResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugDispensationByIdResponse
{
    public responseStatus: ResponseStatus;
    public drugDispensation: DrugDispensation;

    public constructor(init?: Partial<GetDrugDispensationByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugDispensationByPatientIdResponse
{
    public responseStatus: ResponseStatus;
    public drugDispensations: DrugDispensation[];

    public constructor(init?: Partial<GetDrugDispensationByPatientIdResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientsWithLateDispensationsResponse
{
    public responseStatus: ResponseStatus;
    public patientIds: number[];

    public constructor(init?: Partial<GetPatientsWithLateDispensationsResponse>) { (Object as any).assign(this, init); }
}

export class GetLastDispensationForPatientIdResponse
{
    public responseStatus: ResponseStatus;
    public drugDispensation: DrugDispensation;

    public constructor(init?: Partial<GetLastDispensationForPatientIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugDispensationsByIdsResponse
{
    public responseStatus: ResponseStatus;
    public drugDispensations: DrugDispensation[];

    public constructor(init?: Partial<GetDrugDispensationsByIdsResponse>) { (Object as any).assign(this, init); }
}

export class SetDrugDispensationResponse
{
    public responseStatus: ResponseStatus;
    public drugDispensation: DrugDispensation;

    public constructor(init?: Partial<SetDrugDispensationResponse>) { (Object as any).assign(this, init); }
}

export class DeleteDrugDispensationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteDrugDispensationResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugsResponse
{
    public responseStatus: ResponseStatus;
    public drugs: Drug[];

    public constructor(init?: Partial<GetDrugsResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentDepotBatchesResponse
{
    public responseStatus: ResponseStatus;
    public drugShipmentDepotBatches: DrugShipmentDepotBatch[];

    public constructor(init?: Partial<GetDrugShipmentDepotBatchesResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentDepotBatchByIdResponse
{
    public responseStatus: ResponseStatus;
    public drugShipmentDepotBatch: DrugShipmentDepotBatch;

    public constructor(init?: Partial<GetDrugShipmentDepotBatchByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentDepotBatchesByPharmacyIdResponse
{
    public responseStatus: ResponseStatus;
    public drugShipmentDepotBatches: DrugShipmentDepotBatch[];

    public constructor(init?: Partial<GetDrugShipmentDepotBatchesByPharmacyIdResponse>) { (Object as any).assign(this, init); }
}

export class SetDrugShipmentDepotBatchResponse
{
    public responseStatus: ResponseStatus;
    public drugShipmentDepotBatch: DrugShipmentDepotBatch;

    public constructor(init?: Partial<SetDrugShipmentDepotBatchResponse>) { (Object as any).assign(this, init); }
}

export class StartDrugShipmentGenerationResponse
{
    public responseStatus: ResponseStatus;
    public messages: string[];
    public createdDrugShipments: DrugShipment[];

    public constructor(init?: Partial<StartDrugShipmentGenerationResponse>) { (Object as any).assign(this, init); }
}

export class CreateDrugShipmentFromPatientResponse
{
    public responseStatus: ResponseStatus;
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<CreateDrugShipmentFromPatientResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentsResponse
{
    public responseStatus: ResponseStatus;
    public drugShipments: DrugShipment[];

    public constructor(init?: Partial<GetDrugShipmentsResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentsByInstitutionResponse
{
    public responseStatus: ResponseStatus;
    public drugShipments: DrugShipment[];

    public constructor(init?: Partial<GetDrugShipmentsByInstitutionResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentByIdResponse
{
    public responseStatus: ResponseStatus;
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<GetDrugShipmentByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentsByIdsResponse
{
    public responseStatus: ResponseStatus;
    public drugShipments: DrugShipment[];

    public constructor(init?: Partial<GetDrugShipmentsByIdsResponse>) { (Object as any).assign(this, init); }
}

export class SetDrugShipmentResponse
{
    public responseStatus: ResponseStatus;
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<SetDrugShipmentResponse>) { (Object as any).assign(this, init); }
}

export class SetDrugShipmentStatusResponse
{
    public responseStatus: ResponseStatus;
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<SetDrugShipmentStatusResponse>) { (Object as any).assign(this, init); }
}

export class DeleteDrugShipmentResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteDrugShipmentResponse>) { (Object as any).assign(this, init); }
}

export class SetDrugShipmentVerifyResponse
{
    public responseStatus: ResponseStatus;
    public drugShipmentVerify: DrugShipmentVerify;

    public constructor(init?: Partial<SetDrugShipmentVerifyResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentsNotShippedByExpectedDateResponse
{
    public responseStatus: ResponseStatus;
    public drugShipments: DrugShipment[];

    public constructor(init?: Partial<GetDrugShipmentsNotShippedByExpectedDateResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentsNotReceivedByExpectedDateResponse
{
    public responseStatus: ResponseStatus;
    public drugShipments: DrugShipment[];

    public constructor(init?: Partial<GetDrugShipmentsNotReceivedByExpectedDateResponse>) { (Object as any).assign(this, init); }
}

export class GetOrderFormsResponse
{
    public responseStatus: ResponseStatus;
    public orderForms: OrderForm[];

    public constructor(init?: Partial<GetOrderFormsResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientDrugsResponse
{
    public responseStatus: ResponseStatus;
    public patientDrugs: PatientDrug[];

    public constructor(init?: Partial<GetPatientDrugsResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientDrugsByPatientIdResponse
{
    public responseStatus: ResponseStatus;
    public patientDrugs: PatientDrug[];

    public constructor(init?: Partial<GetPatientDrugsByPatientIdResponse>) { (Object as any).assign(this, init); }
}

export class SetPatientDrugsResponse
{
    public responseStatus: ResponseStatus;
    public patientDrugs: PatientDrug[];

    public constructor(init?: Partial<SetPatientDrugsResponse>) { (Object as any).assign(this, init); }
}

export class GetPharmacistsByInstCodeResponse
{
    public responseStatus: ResponseStatus;
    public pharmacists: Pharmacist[];

    public constructor(init?: Partial<GetPharmacistsByInstCodeResponse>) { (Object as any).assign(this, init); }
}

export class GetPharmaciesResponse
{
    public responseStatus: ResponseStatus;
    public pharmacies: Pharmacy[];

    public constructor(init?: Partial<GetPharmaciesResponse>) { (Object as any).assign(this, init); }
}

export class GetPharmacyByIdResponse
{
    public responseStatus: ResponseStatus;
    public pharmacy: Pharmacy;

    public constructor(init?: Partial<GetPharmacyByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetPharmacyByInstitutionCodeResponse
{
    public responseStatus: ResponseStatus;
    public pharmacy: Pharmacy;

    public constructor(init?: Partial<GetPharmacyByInstitutionCodeResponse>) { (Object as any).assign(this, init); }
}

export class GetPharmacyBatchesDrugRemainingByBatchIdsResponse
{
    public responseStatus: ResponseStatus;
    public pharmacyBatchesDrugRemaining: PharmacyBatchDrugRemaining[];

    public constructor(init?: Partial<GetPharmacyBatchesDrugRemainingByBatchIdsResponse>) { (Object as any).assign(this, init); }
}

export class SetPharmacyResponse
{
    public responseStatus: ResponseStatus;
    public pharmacy: Pharmacy;

    public constructor(init?: Partial<SetPharmacyResponse>) { (Object as any).assign(this, init); }
}

export class QuarantinePharmacyStockResponse
{
    public responseStatus: ResponseStatus;
    public success: boolean;

    public constructor(init?: Partial<QuarantinePharmacyStockResponse>) { (Object as any).assign(this, init); }
}

export class ActionQuarantinedStockResponse
{
    public responseStatus: ResponseStatus;
    public success: boolean;

    public constructor(init?: Partial<ActionQuarantinedStockResponse>) { (Object as any).assign(this, init); }
}

export class GetPharmacyEmailsResponse
{
    public responseStatus: ResponseStatus;
    public emailAddresses: MailAddress[];

    public constructor(init?: Partial<GetPharmacyEmailsResponse>) { (Object as any).assign(this, init); }
}

export class GetTimepointsResponse
{
    public responseStatus: ResponseStatus;
    public timepoints: Timepoint[];

    public constructor(init?: Partial<GetTimepointsResponse>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewSingleByPatientStudyNumberResponse
{
    public responseStatus: ResponseStatus;
    public medicalReview: MedicalReview;

    public constructor(init?: Partial<SaeMedicalReviewSingleByPatientStudyNumberResponse>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewNarrativeSummaryPostSaveResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaeMedicalReviewNarrativeSummaryPostSaveResponse>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewPostSaveResponse
{
    public responseStatus: ResponseStatus;
    public medicalReview: MedicalReview;

    public constructor(init?: Partial<SaeMedicalReviewPostSaveResponse>) { (Object as any).assign(this, init); }
}

export class SaeValidateMedicalReviewResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: SaeFormValidationResult;

    public constructor(init?: Partial<SaeValidateMedicalReviewResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SaeCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved saes.
    */
    // @DataMember(Order=2)
    public saes: Sae<SaeForm,Patient>[];

    public constructor(init?: Partial<SaeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SaeSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved sae.
    */
    // @DataMember(Order=2)
    public sae: Sae<SaeForm, Patient>;

    public constructor(init?: Partial<SaeSingleResponse>) { (Object as any).assign(this, init); }
}

export class SaeGetCollectionByStatusResponse
{
    public responseStatus: ResponseStatus;
    public saes: Sae<ISaeForm,IPatient>[];

    public constructor(init?: Partial<SaeGetCollectionByStatusResponse>) { (Object as any).assign(this, init); }
}

export class SaeWebActionSaveResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaeWebActionSaveResponse>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewWebActionSaveResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaeMedicalReviewWebActionSaveResponse>) { (Object as any).assign(this, init); }
}

export class SaeSendNotificationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaeSendNotificationResponse>) { (Object as any).assign(this, init); }
}

export class SaeValidateNotificationFormResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: SaeFormValidationResult;

    public constructor(init?: Partial<SaeValidateNotificationFormResponse>) { (Object as any).assign(this, init); }
}

export class SaeValidateRejectionFormResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: SaeFormValidationResult;

    public constructor(init?: Partial<SaeValidateRejectionFormResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningSummarySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved screeningsummary.
    */
    // @DataMember(Order=2)
    public screeningSummary: ScreeningSummary;

    public constructor(init?: Partial<ScreeningSummarySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningSummaryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved screeningsummary.
    */
    // @DataMember(Order=2)
    public screeningSummary: ScreeningSummary[];

    public constructor(init?: Partial<ScreeningSummaryCollectionResponse>) { (Object as any).assign(this, init); }
}

export class ScreeningSummaryFormValidationResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: ScreeningSummaryFormValidationResult;

    public constructor(init?: Partial<ScreeningSummaryFormValidationResponse>) { (Object as any).assign(this, init); }
}

export class UploadResponse
{
    public responseStatus: ResponseStatus;
    public upload: IFileUploadTemporary;

    public constructor(init?: Partial<UploadResponse>) { (Object as any).assign(this, init); }
}

export class GetUserResponse
{
    public responseStatus: ResponseStatus;
    public userId: string;
    public sessionId: string;
    public userName: string;
    public displayName: string;
    public type?: UserType;

    public constructor(init?: Partial<GetUserResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved lookups.
    */
    // @DataMember(Order=2)
    public lookups: Lookup[];

    public constructor(init?: Partial<LookupCollectionResponse>) { (Object as any).assign(this, init); }
}

export class GetFormMetadataResponse
{
    public responseStatus: ResponseStatus;
    public formMetadata: FormMetadata[];

    public constructor(init?: Partial<GetFormMetadataResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved event definition.
    */
    // @DataMember(Order=2)
    public eventDefinition: EventDefinition;

    public constructor(init?: Partial<EventDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved event definitions.
    */
    // @DataMember(Order=2)
    public eventDefinitions: EventDefinition[];

    public constructor(init?: Partial<EventDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definition.
    */
    // @DataMember(Order=2)
    public formDefinition: FormDefinition;

    public constructor(init?: Partial<FormDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definitions.
    */
    // @DataMember(Order=2)
    public formDefinitions: FormDefinition[];

    public constructor(init?: Partial<FormDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStateSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient state.
    */
    // @DataMember(Order=2)
    public patientState: PatientState;

    public constructor(init?: Partial<PatientStateSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStateCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient states.
    */
    // @DataMember(Order=2)
    public patientStates: PatientState[];

    public constructor(init?: Partial<PatientStateCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved treatment.
    */
    // @DataMember(Order=2)
    public treatment: Treatment;

    public constructor(init?: Partial<TreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved treatments.
    */
    // @DataMember(Order=2)
    public treatments: Treatment[];

    public constructor(init?: Partial<TreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved master group.
    */
    // @DataMember(Order=2)
    public masterGroup: MasterGroup;

    public constructor(init?: Partial<MasterGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved master groups.
    */
    // @DataMember(Order=2)
    public masterGroups: MasterGroup[];

    public constructor(init?: Partial<MasterGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CollaboratingGroupSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved collaborating group.
    */
    // @DataMember(Order=2)
    public collaboratingGroup: CollaboratingGroup;

    public constructor(init?: Partial<CollaboratingGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CollaboratingGroupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved collaborating groups.
    */
    // @DataMember(Order=2)
    public collaboratingGroups: CollaboratingGroup[];

    public constructor(init?: Partial<CollaboratingGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountrySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved country.
    */
    // @DataMember(Order=2)
    public country: Country;

    public constructor(init?: Partial<CountrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved countrys.
    */
    // @DataMember(Order=2)
    public countries: Country[];

    public constructor(init?: Partial<CountryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved institution.
    */
    // @DataMember(Order=2)
    public institution: Institution;

    public constructor(init?: Partial<InstitutionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved institutions.
    */
    // @DataMember(Order=2)
    public institutions: Institution[];

    public constructor(init?: Partial<InstitutionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient.
    */
    // @DataMember(Order=2)
    public patient: Patient;

    public constructor(init?: Partial<PatientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: PatientValidationResult;

    public constructor(init?: Partial<PatientValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patients.
    */
    // @DataMember(Order=2)
    public patients: Patient[];

    public constructor(init?: Partial<PatientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved event.
    */
    // @DataMember(Order=2)
    public event: Event;

    public constructor(init?: Partial<EventSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved events.
    */
    // @DataMember(Order=2)
    public events: Event[];

    public constructor(init?: Partial<EventCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adverse event.
    */
    // @DataMember(Order=2)
    public form: AdverseEvent;

    public constructor(init?: Partial<AdverseEventSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adverse events.
    */
    // @DataMember(Order=2)
    public forms: AdverseEvent[];

    public constructor(init?: Partial<AdverseEventCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AdverseEventDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<AdverseEventValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventFormSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adverse event form.
    */
    // @DataMember(Order=2)
    public form: AdverseEventForm;

    public constructor(init?: Partial<AdverseEventFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventFormCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adverse event forms.
    */
    // @DataMember(Order=2)
    public forms: AdverseEventForm[];

    public constructor(init?: Partial<AdverseEventFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventFormDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AdverseEventFormDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventFormValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<AdverseEventFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BloodsClinicalSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved bloods clinical.
    */
    // @DataMember(Order=2)
    public form: BloodsClinical;

    public constructor(init?: Partial<BloodsClinicalSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BloodsClinicalCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved bloods clinicals.
    */
    // @DataMember(Order=2)
    public forms: BloodsClinical[];

    public constructor(init?: Partial<BloodsClinicalCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BloodsClinicalDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<BloodsClinicalDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BloodsClinicalValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<BloodsClinicalValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalAssessmentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved clinical assessment.
    */
    // @DataMember(Order=2)
    public form: ClinicalAssessment;

    public constructor(init?: Partial<ClinicalAssessmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalAssessmentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved clinical assessments.
    */
    // @DataMember(Order=2)
    public forms: ClinicalAssessment[];

    public constructor(init?: Partial<ClinicalAssessmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalAssessmentDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ClinicalAssessmentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalAssessmentValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ClinicalAssessmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medication.
    */
    // @DataMember(Order=2)
    public form: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medications.
    */
    // @DataMember(Order=2)
    public forms: ConcomitantMedication[];

    public constructor(init?: Partial<ConcomitantMedicationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ConcomitantMedicationDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ConcomitantMedicationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationFormSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medication form.
    */
    // @DataMember(Order=2)
    public form: ConcomitantMedicationForm;

    public constructor(init?: Partial<ConcomitantMedicationFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationFormCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medication forms.
    */
    // @DataMember(Order=2)
    public forms: ConcomitantMedicationForm[];

    public constructor(init?: Partial<ConcomitantMedicationFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationFormDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ConcomitantMedicationFormDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationFormValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ConcomitantMedicationFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiseaseAssessmentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved disease assessment.
    */
    // @DataMember(Order=2)
    public form: DiseaseAssessment;

    public constructor(init?: Partial<DiseaseAssessmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiseaseAssessmentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved disease assessments.
    */
    // @DataMember(Order=2)
    public forms: DiseaseAssessment[];

    public constructor(init?: Partial<DiseaseAssessmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiseaseAssessmentDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DiseaseAssessmentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiseaseAssessmentValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<DiseaseAssessmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityConfirmationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved eligibility confirmation.
    */
    // @DataMember(Order=2)
    public form: EligibilityConfirmation;

    public constructor(init?: Partial<EligibilityConfirmationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityConfirmationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved eligibility confirmations.
    */
    // @DataMember(Order=2)
    public forms: EligibilityConfirmation[];

    public constructor(init?: Partial<EligibilityConfirmationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityConfirmationDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<EligibilityConfirmationDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityConfirmationValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<EligibilityConfirmationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved general.
    */
    // @DataMember(Order=2)
    public form: General;

    public constructor(init?: Partial<GeneralSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved generals.
    */
    // @DataMember(Order=2)
    public forms: General[];

    public constructor(init?: Partial<GeneralCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GeneralDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<GeneralValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistorySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical history.
    */
    // @DataMember(Order=2)
    public form: MedicalHistory;

    public constructor(init?: Partial<MedicalHistorySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical historys.
    */
    // @DataMember(Order=2)
    public forms: MedicalHistory[];

    public constructor(init?: Partial<MedicalHistoryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<MedicalHistoryDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<MedicalHistoryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryFormSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical history form.
    */
    // @DataMember(Order=2)
    public form: MedicalHistoryForm;

    public constructor(init?: Partial<MedicalHistoryFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryFormCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical history forms.
    */
    // @DataMember(Order=2)
    public forms: MedicalHistoryForm[];

    public constructor(init?: Partial<MedicalHistoryFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryFormDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<MedicalHistoryFormDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryFormValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<MedicalHistoryFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PreviousPsaAndTestosteroneResultSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved previous psa and testosterone result.
    */
    // @DataMember(Order=2)
    public form: PreviousPsaAndTestosteroneResult;

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResultSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PreviousPsaAndTestosteroneResultCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved previous psa and testosterone results.
    */
    // @DataMember(Order=2)
    public forms: PreviousPsaAndTestosteroneResult[];

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResultCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PreviousPsaAndTestosteroneResultDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResultDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PreviousPsaAndTestosteroneResultValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResultValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProstateCancerDetailsSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved prostate cancer details.
    */
    // @DataMember(Order=2)
    public form: ProstateCancerDetails;

    public constructor(init?: Partial<ProstateCancerDetailsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProstateCancerDetailsCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved prostate cancer detailss.
    */
    // @DataMember(Order=2)
    public forms: ProstateCancerDetails[];

    public constructor(init?: Partial<ProstateCancerDetailsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProstateCancerDetailsDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ProstateCancerDetailsDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProstateCancerDetailsValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ProstateCancerDetailsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PsmaPetSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved psma pet.
    */
    // @DataMember(Order=2)
    public form: PsmaPet;

    public constructor(init?: Partial<PsmaPetSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PsmaPetCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved psma pets.
    */
    // @DataMember(Order=2)
    public forms: PsmaPet[];

    public constructor(init?: Partial<PsmaPetCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PsmaPetDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PsmaPetDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PsmaPetValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<PsmaPetValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved source document.
    */
    // @DataMember(Order=2)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved source documents.
    */
    // @DataMember(Order=2)
    public forms: SourceDocument[];

    public constructor(init?: Partial<SourceDocumentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SourceDocumentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<SourceDocumentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentsSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved source documents.
    */
    // @DataMember(Order=2)
    public form: SourceDocuments;

    public constructor(init?: Partial<SourceDocumentsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentsCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved source documentss.
    */
    // @DataMember(Order=2)
    public forms: SourceDocuments[];

    public constructor(init?: Partial<SourceDocumentsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentsDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SourceDocumentsDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentsValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<SourceDocumentsValidationResponse>) { (Object as any).assign(this, init); }
}

export class InstitutionPersonnelNamesResponse
{
    public responseStatus: ResponseStatus;
    public personnelNames: { [index: number]: string; };

    public constructor(init?: Partial<InstitutionPersonnelNamesResponse>) { (Object as any).assign(this, init); }
}

export class GetUserNamesResponse
{
    public responseStatus: ResponseStatus;
    public personnelNames: { [index: number]: string; };

    public constructor(init?: Partial<GetUserNamesResponse>) { (Object as any).assign(this, init); }
}

export class HasOpmsPermissionCollectionResponse
{
    public responseStatus: ResponseStatus;
    public hasPermission: { [index: number]: boolean; };

    public constructor(init?: Partial<HasOpmsPermissionCollectionResponse>) { (Object as any).assign(this, init); }
}

export class SendRegistrationEmail implements IReturn<SendRegistrationEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendRegistrationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendRegistrationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendRegistrationEmailResponse(); }
}

export class SendRandomisationEmail implements IReturn<SendRandomisationEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendRandomisationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendRandomisationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendRandomisationEmailResponse(); }
}

export class SendPatientIneligibleEmail implements IReturn<SendPatientIneligibleEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendPatientIneligibleEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPatientIneligibleEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPatientIneligibleEmailResponse(); }
}

export class SendPatientSubmittedForReviewEmail implements IReturn<SendPatientSubmittedForReviewEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendPatientSubmittedForReviewEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPatientSubmittedForReviewEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPatientSubmittedForReviewEmailResponse(); }
}

export class SendPatientReassignedToSiteEmail implements IReturn<SendPatientReassignedToSiteEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendPatientReassignedToSiteEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPatientReassignedToSiteEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPatientReassignedToSiteEmailResponse(); }
}

export class QueuePatientOcTransfer implements IReturn<QueuePatientOcTransferResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<QueuePatientOcTransfer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueuePatientOcTransfer'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueuePatientOcTransferResponse(); }
}

export class QueuePatientsOcTransfer implements IReturn<QueuePatientsOcTransferResponse>
{

    public constructor(init?: Partial<QueuePatientsOcTransfer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueuePatientsOcTransfer'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueuePatientsOcTransferResponse(); }
}

export class SendSaeNotifyInvestigatorEmail implements IReturn<SendSaeNotifyInvestigatorEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;
    public initialEmail: boolean;
    public recipientIds: number[];
    public ccRecipientIds: number[];
    public attachmentNames: string[];
    public attachmentGuids: string[];
    public includedNotes: string;

    public constructor(init?: Partial<SendSaeNotifyInvestigatorEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeNotifyInvestigatorEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeNotifyInvestigatorEmailResponse(); }
}

export class SendSaeInvestigatorReviewEmail implements IReturn<SendSaeInvestigatorReviewEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;
    public approved: boolean;
    public reasonForRejection: string;
    public reviewer: string;

    public constructor(init?: Partial<SendSaeInvestigatorReviewEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeInvestigatorReviewEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeInvestigatorReviewEmailResponse(); }
}

export class SendNewSaeEmail implements IReturn<SendNewSaeEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;
    public initialEmail: boolean;

    public constructor(init?: Partial<SendNewSaeEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendNewSaeEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendNewSaeEmailResponse(); }
}

export class SendUnapprovedSaeEmail implements IReturn<SendUnapprovedSaeEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;

    public constructor(init?: Partial<SendUnapprovedSaeEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendUnapprovedSaeEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendUnapprovedSaeEmailResponse(); }
}

export class SendUnresolvedSaeEmail implements IReturn<SendUnresolvedSaeEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;

    public constructor(init?: Partial<SendUnresolvedSaeEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendUnresolvedSaeEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendUnresolvedSaeEmailResponse(); }
}

export class SendDrugShipmentOrderedEmail implements IReturn<SendDrugShipmentOrderedEmailResponse>
{
    public drugShipmentId: number;

    public constructor(init?: Partial<SendDrugShipmentOrderedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDrugShipmentOrderedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendDrugShipmentOrderedEmailResponse(); }
}

export class SendDrugShipmentGenerationEmail implements IReturn<SendDrugShipmentGenerationEmailResponse>
{
    public drugShipmentIds: number[];

    public constructor(init?: Partial<SendDrugShipmentGenerationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDrugShipmentGenerationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendDrugShipmentGenerationEmailResponse(); }
}

export class SendStagnantDrugOrdersEmail implements IReturn<SendStagnantDrugOrdersEmailResponse>
{
    public drugShipmentIds: number[];

    public constructor(init?: Partial<SendStagnantDrugOrdersEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendStagnantDrugOrdersEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendStagnantDrugOrdersEmailResponse(); }
}

export class SendStagnantDrugShipmentsEmail implements IReturn<SendStagnantDrugShipmentsEmailResponse>
{
    public drugShipmentIds: number[];

    public constructor(init?: Partial<SendStagnantDrugShipmentsEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendStagnantDrugShipmentsEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendStagnantDrugShipmentsEmailResponse(); }
}

export class SendExpiringStockEmail implements IReturn<SendExpiringStockEmailResponse>
{
    public batchIds: number[];

    public constructor(init?: Partial<SendExpiringStockEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendExpiringStockEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendExpiringStockEmailResponse(); }
}

export class SendLowStockEmail implements IReturn<SendLowStockEmailResponse>
{
    public depotIds: number[];
    public drugThreshold: number;

    public constructor(init?: Partial<SendLowStockEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendLowStockEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendLowStockEmailResponse(); }
}

export class SendLateDispensationEmail implements IReturn<SendLateDispensationEmailResponse>
{
    public patientId: number;

    public constructor(init?: Partial<SendLateDispensationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendLateDispensationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendLateDispensationEmailResponse(); }
}

export class SendDrugDispensationEmail implements IReturn<SendDrugDispensationEmailResponse>
{
    public drugDispensationIds: number[];

    public constructor(init?: Partial<SendDrugDispensationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDrugDispensationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendDrugDispensationEmailResponse(); }
}

export class SendDrugShipmentQuarantinedEmail implements IReturn<SendDrugShipmentQuarantinedEmailResponse>
{
    public drugShipmentId: number;

    public constructor(init?: Partial<SendDrugShipmentQuarantinedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDrugShipmentQuarantinedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendDrugShipmentQuarantinedEmailResponse(); }
}

export class SendDrugShipmentReceivedEmail implements IReturn<SendDrugShipmentReceivedEmailResponse>
{
    public drugShipmentId: number;

    public constructor(init?: Partial<SendDrugShipmentReceivedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDrugShipmentReceivedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendDrugShipmentReceivedEmailResponse(); }
}

export class SendDrugShipmentDispatchedEmail implements IReturn<SendDrugShipmentDispatchedEmailResponse>
{
    public drugShipmentId: number;

    public constructor(init?: Partial<SendDrugShipmentDispatchedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDrugShipmentDispatchedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendDrugShipmentDispatchedEmailResponse(); }
}

// @Route("/print/screeningsummary/{InstitutionCode}/{DownloadFormat}/", "GET")
export class GetScreeningSummaryReport
{
    public institutionCode: string;
    public downloadFormat: string;

    public constructor(init?: Partial<GetScreeningSummaryReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetScreeningSummaryReport'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

export class GetEnumerationTypes implements IReturn<GetEnumerationTypesResponse>
{

    public constructor(init?: Partial<GetEnumerationTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEnumerationTypes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEnumerationTypesResponse(); }
}

// @Route("/identity-server/authorized")
export class IdentityServerAuthorized implements IReturn<IdentityServerDiagnosticsResponse>
{

    public constructor(init?: Partial<IdentityServerAuthorized>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'IdentityServerAuthorized'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new IdentityServerDiagnosticsResponse(); }
}

// @Route("/identity-server/test")
export class IdentityServerDiagnostics implements IReturn<IdentityServerDiagnosticsResponse>
{
    public code: string;
    public state: string;
    public error: string;

    public constructor(init?: Partial<IdentityServerDiagnostics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'IdentityServerDiagnostics'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new IdentityServerDiagnosticsResponse(); }
}

/**
* Sign In
*/
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/assignroles", "POST")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AssignRolesResponse(); }
}

// @Route("/unassignroles", "POST")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnAssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnAssignRolesResponse(); }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApiKeys'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetApiKeysResponse(); }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegenerateApiKeys'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegenerateApiKeysResponse(); }
}

// @Route("/api/batches", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetBatches implements IReturn<GetBatchesResponse>
{

    public constructor(init?: Partial<GetBatches>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBatches'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetBatchesResponse(); }
}

// @Route("/api/batch/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetBatchById implements IReturn<GetBatchByIdResponse>
{
    /**
    * The id of the drug batch
    */
    // @ApiMember(DataType="int", Description="The id of the drug batch", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetBatchById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBatchById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetBatchByIdResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetBatchesByIds implements IReturn<GetBatchesByIdsResponse>, IGet
{
    public ids: number[];

    public constructor(init?: Partial<GetBatchesByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBatchesByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetBatchesByIdsResponse(); }
}

// @Route("/api/batches/expiring-at-specified-date", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetBatchesExpiringAtSpecifiedDate implements IReturn<GetBatchesExpiringAtSpecifiedDateResponse>, IGet
{
    /**
    * The date of expiry
    */
    // @ApiMember(DataType="DateTime", Description="The date of expiry", IsRequired=true, Name="ExpiryDate", ParameterType="query")
    public expiryDate: string;

    public constructor(init?: Partial<GetBatchesExpiringAtSpecifiedDate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBatchesExpiringAtSpecifiedDate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetBatchesExpiringAtSpecifiedDateResponse(); }
}

// @Route("/api/batch/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetBatch implements IReturn<SetBatchResponse>
{
    /**
    * The drug batch form data to save
    */
    // @ApiMember(DataType="BatchForm", Description="The drug batch form data to save", IsRequired=true, Name="BatchForm", ParameterType="body")
    public batch: Batch;

    public constructor(init?: Partial<SetBatch>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetBatch'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetBatchResponse(); }
}

// @Route("/api/depot-batches", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatches implements IReturn<GetDepotBatchesResponse>
{

    public constructor(init?: Partial<GetDepotBatches>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatches'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesResponse(); }
}

// @Route("/api/depot-batch/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchById implements IReturn<GetDepotBatchByIdResponse>
{
    /**
    * The id of the depot batch
    */
    // @ApiMember(DataType="int", Description="The id of the depot batch", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDepotBatchById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchByIdResponse(); }
}

// @Route("/api/depot-batches/depotId/{DepotId}", "GET")
// @ApiResponse(Description="Depot Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchesByDepotId implements IReturn<GetDepotBatchesByDepotIdResponse>
{
    /**
    * The id of the depot
    */
    // @ApiMember(DataType="int", Description="The id of the depot", IsRequired=true, Name="DepotId", ParameterType="path")
    public depotId: number;

    public constructor(init?: Partial<GetDepotBatchesByDepotId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchesByDepotId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesByDepotIdResponse(); }
}

// @Route("/api/depot-batches/pharmacyId/{PharmacyId}", "GET")
// @ApiResponse(Description="Pharmacy Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchesByPharmacyId implements IReturn<GetDepotBatchesByPharmacyIdResponse>
{
    /**
    * The id of the pharmacy
    */
    // @ApiMember(DataType="int", Description="The id of the pharmacy", IsRequired=true, Name="PharmacyId", ParameterType="path")
    public pharmacyId: number;

    public constructor(init?: Partial<GetDepotBatchesByPharmacyId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchesByPharmacyId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesByPharmacyIdResponse(); }
}

// @Route("/api/depot-batches/drugShipmentId/{DrugShipmentId}", "GET")
// @ApiResponse(Description="Drug Shipment Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchesByDrugShipmentId implements IReturn<GetDepotBatchesByDrugShipmentIdResponse>
{
    /**
    * The id of the drug shipment
    */
    // @ApiMember(DataType="int", Description="The id of the drug shipment", IsRequired=true, Name="DrugShipmentId", ParameterType="path")
    public drugShipmentId: number;

    public constructor(init?: Partial<GetDepotBatchesByDrugShipmentId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchesByDrugShipmentId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesByDrugShipmentIdResponse(); }
}

// @Route("/api/depot-batches/institution/{InstCode}", "GET")
// @ApiResponse(Description="Institution code invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchesByInstitutionCode implements IReturn<GetDepotBatchesByPharmacyIdResponse>
{
    /**
    * The institution code
    */
    // @ApiMember(DataType="string", Description="The institution code", IsRequired=true, Name="InstCode", ParameterType="path")
    public instCode: string;

    public constructor(init?: Partial<GetDepotBatchesByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchesByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesByPharmacyIdResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchesByBatchIds implements IReturn<GetDepotBatchesByBatchIdsResponse>, IGet
{
    public batchIds: number[];

    public constructor(init?: Partial<GetDepotBatchesByBatchIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchesByBatchIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesByBatchIdsResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchesWithLowStockByDepotIds implements IReturn<GetDepotBatchesWithLowStockByDepotIdsResponse>, IGet
{
    public depotIds: number[];
    public drugThreshold?: number;

    public constructor(init?: Partial<GetDepotBatchesWithLowStockByDepotIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchesWithLowStockByDepotIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesWithLowStockByDepotIdsResponse(); }
}

// @Route("/api/depot-batch/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDepotBatch implements IReturn<SetDepotBatchResponse>
{
    /**
    * The depot batch form data to save
    */
    // @ApiMember(DataType="DepotBatch", Description="The depot batch form data to save", IsRequired=true, Name="DepotBatch", ParameterType="body")
    public depotBatch: DepotBatch;

    public constructor(init?: Partial<SetDepotBatch>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDepotBatch'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDepotBatchResponse(); }
}

// @Route("/api/depots", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepots implements IReturn<GetDepotsResponse>, IGet
{

    public constructor(init?: Partial<GetDepots>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepots'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotsResponse(); }
}

// @Route("/api/depot/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotById implements IReturn<GetDepotByIdResponse>, IGet
{
    /**
    * The id of the drug depot
    */
    // @ApiMember(DataType="int", Description="The id of the drug depot", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDepotById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotByIdResponse(); }
}

// @Route("/api/depots/by-drug-under-threshold", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotsByDrugUnderThreshold implements IReturn<GetDepotsByDrugUnderThresholdResponse>, IGet
{
    /**
    * The threshold to check
    */
    // @ApiMember(DataType="int", Description="The threshold to check", IsRequired=true, Name="DrugThreshold", ParameterType="query")
    public drugThreshold: number;

    public constructor(init?: Partial<GetDepotsByDrugUnderThreshold>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotsByDrugUnderThreshold'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotsByDrugUnderThresholdResponse(); }
}

// @Route("/api/depot/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDepot implements IReturn<SetDepotResponse>
{
    /**
    * The drug depot form data to save
    */
    // @ApiMember(DataType="DepotForm", Description="The drug depot form data to save", IsRequired=true, Name="DepotForm", ParameterType="body")
    public depot: Depot;

    public constructor(init?: Partial<SetDepot>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDepot'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDepotResponse(); }
}

// @Route("/api/drug-dispensations", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugDispensations implements IReturn<GetDrugDispensationsResponse>, IGet
{

    public constructor(init?: Partial<GetDrugDispensations>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugDispensations'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugDispensationsResponse(); }
}

// @Route("/api/drug-dispensation/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugDispensationById implements IReturn<GetDrugDispensationByIdResponse>, IGet
{
    /**
    * The id of the drug dispensation
    */
    // @ApiMember(DataType="int", Description="The id of the drug dispensation", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDrugDispensationById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugDispensationById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugDispensationByIdResponse(); }
}

// @Route("/api/drug-dispensations/patient-id/{PatientId}", "GET")
// @ApiResponse(Description="Patient id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugDispensationByPatientId implements IReturn<GetDrugDispensationByPatientIdResponse>, IGet
{
    /**
    * The id of the patient to retrieve drug dispensations for
    */
    // @ApiMember(DataType="int", Description="The id of the patient to retrieve drug dispensations for", IsRequired=true, Name="PatientId", ParameterType="path")
    public patientId: number;

    public constructor(init?: Partial<GetDrugDispensationByPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugDispensationByPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugDispensationByPatientIdResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPatientsWithLateDispensations implements IReturn<GetPatientsWithLateDispensationsResponse>, IGet
{
    public expectedDate?: string;

    public constructor(init?: Partial<GetPatientsWithLateDispensations>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatientsWithLateDispensations'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPatientsWithLateDispensationsResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetLastDispensationForPatientId implements IReturn<GetLastDispensationForPatientIdResponse>, IGet
{
    public patientId?: number;

    public constructor(init?: Partial<GetLastDispensationForPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLastDispensationForPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetLastDispensationForPatientIdResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugDispensationsByIds implements IReturn<GetDrugDispensationsByIdsResponse>, IGet
{
    public ids: number[];

    public constructor(init?: Partial<GetDrugDispensationsByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugDispensationsByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugDispensationsByIdsResponse(); }
}

// @Route("/api/drug-dispensation/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDrugDispensation implements IReturn<SetDrugDispensationResponse>, IPost
{
    /**
    * The patient dispensation form data to save
    */
    // @ApiMember(DataType="DrugDispensation", Description="The patient dispensation form data to save", IsRequired=true, Name="DrugDispensation", ParameterType="body")
    public drugDispensation: DrugDispensation;

    public constructor(init?: Partial<SetDrugDispensation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDrugDispensation'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDrugDispensationResponse(); }
}

export class DeleteDrugDispensation implements IReturn<DeleteDrugDispensationResponse>
{
    /**
    * The drug dispensation we want to delete
    */
    // @ApiMember(DataType="DrugDispensation", Description="The drug dispensation we want to delete", IsRequired=true, Name="DrugDispensation", ParameterType="body")
    public drugDispensation: DrugDispensation;

    public constructor(init?: Partial<DeleteDrugDispensation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteDrugDispensation'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeleteDrugDispensationResponse(); }
}

// @Route("/api/drugs", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugs implements IReturn<GetDrugsResponse>
{

    public constructor(init?: Partial<GetDrugs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugsResponse(); }
}

// @Route("/api/drug-shipment-depot-batches", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentDepotBatches implements IReturn<GetDrugShipmentDepotBatchesResponse>
{

    public constructor(init?: Partial<GetDrugShipmentDepotBatches>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentDepotBatches'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentDepotBatchesResponse(); }
}

// @Route("/api/drug-shipment-depot-batch/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentDepotBatchById implements IReturn<GetDrugShipmentDepotBatchByIdResponse>
{
    /**
    * The id of the drug shipment depot batch
    */
    // @ApiMember(DataType="int", Description="The id of the drug shipment depot batch", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDrugShipmentDepotBatchById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentDepotBatchById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentDepotBatchByIdResponse(); }
}

// @Route("/api/drug-shipment-depot-batches/pharmacyId/{PharmacyId}", "GET")
// @ApiResponse(Description="Pharmacy Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentDepotBatchesByPharmacyId implements IReturn<GetDrugShipmentDepotBatchesByPharmacyIdResponse>
{
    /**
    * The id of the pharmacy
    */
    // @ApiMember(DataType="int", Description="The id of the pharmacy", IsRequired=true, Name="PharmacyId", ParameterType="path")
    public pharmacyId: number;

    public constructor(init?: Partial<GetDrugShipmentDepotBatchesByPharmacyId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentDepotBatchesByPharmacyId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentDepotBatchesByPharmacyIdResponse(); }
}

// @Route("/api/drug-shipment-depot-batches/institution/{InstCode}", "GET")
// @ApiResponse(Description="Institution code invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentDepotBatchesByInstitutionCode implements IReturn<GetDrugShipmentDepotBatchesByPharmacyIdResponse>
{
    /**
    * The institution code
    */
    // @ApiMember(DataType="string", Description="The institution code", IsRequired=true, Name="InstCode", ParameterType="path")
    public instCode: string;

    public constructor(init?: Partial<GetDrugShipmentDepotBatchesByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentDepotBatchesByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentDepotBatchesByPharmacyIdResponse(); }
}

// @Route("/api/drug-shipment-depot-batch/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDrugShipmentDepotBatch implements IReturn<SetDrugShipmentDepotBatchResponse>
{
    /**
    * The drug shipment depot batch form data to save
    */
    // @ApiMember(DataType="DrugShipmentDepotBatch", Description="The drug shipment depot batch form data to save", IsRequired=true, Name="DrugShipmentDepotBatch", ParameterType="body")
    public drugShipmentDepotBatch: DrugShipmentDepotBatch;

    public constructor(init?: Partial<SetDrugShipmentDepotBatch>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDrugShipmentDepotBatch'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDrugShipmentDepotBatchResponse(); }
}

// @Route("/api/drug-shipment-generation/queue", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class StartDrugShipmentGeneration implements IReturn<StartDrugShipmentGenerationResponse>
{

    public constructor(init?: Partial<StartDrugShipmentGeneration>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StartDrugShipmentGeneration'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new StartDrugShipmentGenerationResponse(); }
}

// @Route("/api/drug-shipment-generation/create-from-patient", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateDrugShipmentFromPatient implements IReturn<CreateDrugShipmentFromPatientResponse>, IPost
{
    /**
    * The id of the patient
    */
    // @ApiMember(DataType="int", Description="The id of the patient", IsRequired=true, Name="PatientId", ParameterType="body")
    public patientId: number;

    /**
    * The drugs to order for the patient
    */
    // @ApiMember(DataType="model", Description="The drugs to order for the patient", IsRequired=true, Name="Drugs", ParameterType="body")
    public drugs: DrugAmount[];

    public constructor(init?: Partial<CreateDrugShipmentFromPatient>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateDrugShipmentFromPatient'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreateDrugShipmentFromPatientResponse(); }
}

// @Route("/api/drug-shipments", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipments implements IReturn<GetDrugShipmentsResponse>, IGet
{

    public constructor(init?: Partial<GetDrugShipments>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipments'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentsResponse(); }
}

// @Route("/api/drug-shipments/institution/{InstitutionCode}", "GET")
// @ApiResponse(Description="Institution code invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentsByInstitution implements IReturn<GetDrugShipmentsByInstitutionResponse>, IGet
{
    /**
    * The institution of the pharmacies
    */
    // @ApiMember(DataType="string", Description="The institution of the pharmacies", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    public constructor(init?: Partial<GetDrugShipmentsByInstitution>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentsByInstitution'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentsByInstitutionResponse(); }
}

// @Route("/api/drug-shipment/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentById implements IReturn<GetDrugShipmentByIdResponse>, IGet
{
    /**
    * The id of the drug shipment
    */
    // @ApiMember(DataType="int", Description="The id of the drug shipment", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDrugShipmentById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentByIdResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentsByIds implements IReturn<GetDrugShipmentsByIdsResponse>, IGet
{
    public ids: number[];

    public constructor(init?: Partial<GetDrugShipmentsByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentsByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentsByIdsResponse(); }
}

// @Route("/api/drug-shipment/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDrugShipment implements IReturn<SetDrugShipmentResponse>, IPost
{
    /**
    * The drug shipment form data to save
    */
    // @ApiMember(DataType="DrugShipmentForm", Description="The drug shipment form data to save", IsRequired=true, Name="DrugShipmentForm", ParameterType="body")
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<SetDrugShipment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDrugShipment'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDrugShipmentResponse(); }
}

// @Route("/api/drug-shipment/set-status", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDrugShipmentStatus implements IReturn<SetDrugShipmentStatusResponse>, IPost
{
    /**
    * The drug shipment form data to save
    */
    // @ApiMember(DataType="DrugShipmentForm", Description="The drug shipment form data to save", IsRequired=true, Name="DrugShipmentForm", ParameterType="body")
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<SetDrugShipmentStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDrugShipmentStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDrugShipmentStatusResponse(); }
}

// @Route("/api/drug-shipment/delete", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class DeleteDrugShipment implements IReturn<DeleteDrugShipmentResponse>, IPost
{
    /**
    * The drug shipment id to delete
    */
    // @ApiMember(DataType="int", Description="The drug shipment id to delete", IsRequired=true, Name="DrugShipmentId", ParameterType="body")
    public drugShipmentId: number;

    public constructor(init?: Partial<DeleteDrugShipment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteDrugShipment'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeleteDrugShipmentResponse(); }
}

// @Route("/api/drug-shipment/verify", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDrugShipmentVerify implements IReturn<SetDrugShipmentVerifyResponse>, IPost
{
    /**
    * The drug shipment form data to verify
    */
    // @ApiMember(DataType="model", Description="The drug shipment form data to verify", IsRequired=true, Name="DrugShipmentVerify", ParameterType="body")
    public drugShipmentVerify: DrugShipmentVerify;

    public constructor(init?: Partial<SetDrugShipmentVerify>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDrugShipmentVerify'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDrugShipmentVerifyResponse(); }
}

// @Route("/api/drug-shipments/not-shipped-by-expected-date", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentsNotShippedByExpectedDate implements IReturn<GetDrugShipmentsNotShippedByExpectedDateResponse>, IGet
{
    /**
    * The date to check from
    */
    // @ApiMember(DataType="DateTime", Description="The date to check from", IsRequired=true, Name="ExpectedShippingDate", ParameterType="query")
    public expectedShippingDate: string;

    public constructor(init?: Partial<GetDrugShipmentsNotShippedByExpectedDate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentsNotShippedByExpectedDate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentsNotShippedByExpectedDateResponse(); }
}

// @Route("/api/drug-shipments/not-received-by-expected-date", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentsNotReceivedByExpectedDate implements IReturn<GetDrugShipmentsNotReceivedByExpectedDateResponse>, IGet
{
    /**
    * The date to check from
    */
    // @ApiMember(DataType="DateTime", Description="The date to check from", IsRequired=true, Name="ExpectedReceptionDate", ParameterType="query")
    public expectedReceptionDate: string;

    public constructor(init?: Partial<GetDrugShipmentsNotReceivedByExpectedDate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentsNotReceivedByExpectedDate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentsNotReceivedByExpectedDateResponse(); }
}

// @Route("/api/drug-shipment-order-form/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentOrderFormById implements IReturn<Blob>, IGet
{
    /**
    * The id of the drug shipment
    */
    // @ApiMember(DataType="int", Description="The id of the drug shipment", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDrugShipmentOrderFormById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentOrderFormById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/api/order-forms", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetOrderForms implements IReturn<GetOrderFormsResponse>
{

    public constructor(init?: Partial<GetOrderForms>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOrderForms'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetOrderFormsResponse(); }
}

// @Route("/api/patient-drugs", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPatientDrugs implements IReturn<GetPatientDrugsResponse>, IGet
{

    public constructor(init?: Partial<GetPatientDrugs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatientDrugs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPatientDrugsResponse(); }
}

// @Route("/api/patient-drugs/patient-id/{PatientId}", "GET")
// @ApiResponse(Description="Patient id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPatientDrugsByPatientId implements IReturn<GetPatientDrugsByPatientIdResponse>, IGet
{
    /**
    * The id of the patient to retrieve patient drugs for
    */
    // @ApiMember(DataType="int", Description="The id of the patient to retrieve patient drugs for", IsRequired=true, Name="PatientId", ParameterType="path")
    public patientId: number;

    public constructor(init?: Partial<GetPatientDrugsByPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatientDrugsByPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPatientDrugsByPatientIdResponse(); }
}

// @Route("/api/patient-drugs/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetPatientDrugs implements IReturn<SetPatientDrugsResponse>, IPost
{
    /**
    * The patient drug form data to save
    */
    // @ApiMember(DataType="model", Description="The patient drug form data to save", IsRequired=true, Name="PatientDrugs", ParameterType="body")
    public patientDrugs: PatientDrug[];

    public constructor(init?: Partial<SetPatientDrugs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetPatientDrugs'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetPatientDrugsResponse(); }
}

export class GetPharmacistsByInstCode implements IReturn<GetPharmacistsByInstCodeResponse>, IGet
{
    public instCode: string;

    public constructor(init?: Partial<GetPharmacistsByInstCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPharmacistsByInstCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPharmacistsByInstCodeResponse(); }
}

// @Route("/api/pharmacies", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPharmacies implements IReturn<GetPharmaciesResponse>, IGet
{

    public constructor(init?: Partial<GetPharmacies>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPharmacies'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPharmaciesResponse(); }
}

// @Route("/api/pharmacy/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPharmacyById implements IReturn<GetPharmacyByIdResponse>, IGet
{
    /**
    * The id of the pharmacy
    */
    // @ApiMember(DataType="int", Description="The id of the pharmacy", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetPharmacyById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPharmacyById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPharmacyByIdResponse(); }
}

// @Route("/api/pharmacy/institution/{InstitutionCode}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPharmacyByInstitutionCode implements IReturn<GetPharmacyByInstitutionCodeResponse>, IGet
{
    /**
    * The institution code of the pharmacy
    */
    // @ApiMember(DataType="string", Description="The institution code of the pharmacy", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    public constructor(init?: Partial<GetPharmacyByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPharmacyByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPharmacyByInstitutionCodeResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPharmacyBatchesDrugRemainingByBatchIds implements IReturn<GetPharmacyBatchesDrugRemainingByBatchIdsResponse>, IGet
{
    public batchIds: number[];

    public constructor(init?: Partial<GetPharmacyBatchesDrugRemainingByBatchIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPharmacyBatchesDrugRemainingByBatchIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPharmacyBatchesDrugRemainingByBatchIdsResponse(); }
}

// @Route("/api/pharmacy/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetPharmacy implements IReturn<SetPharmacyResponse>, IPost
{
    /**
    * The pharmacy form data to save
    */
    // @ApiMember(DataType="model", Description="The pharmacy form data to save", IsRequired=true, Name="PharmacyForm", ParameterType="body")
    public pharmacy: Pharmacy;

    public constructor(init?: Partial<SetPharmacy>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetPharmacy'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetPharmacyResponse(); }
}

// @Route("/api/pharmacy/quarantine-stock", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class QuarantinePharmacyStock implements IReturn<QuarantinePharmacyStockResponse>, IPost
{
    /**
    * The pharmacy id to quarantine
    */
    // @ApiMember(DataType="integer", Description="The pharmacy id to quarantine", IsRequired=true, Name="Id", ParameterType="body")
    public id: number;

    public constructor(init?: Partial<QuarantinePharmacyStock>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QuarantinePharmacyStock'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QuarantinePharmacyStockResponse(); }
}

// @Route("/api/pharmacy/action-quarantined-stock", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class ActionQuarantinedStock implements IReturn<ActionQuarantinedStockResponse>, IPost
{
    /**
    * The pharmacy id to quarantine
    */
    // @ApiMember(DataType="integer", Description="The pharmacy id to quarantine", IsRequired=true, Name="Id", ParameterType="body")
    public id: number;

    /**
    * The action to take on the pharmacy's stock
    */
    // @ApiMember(DataType="integer", Description="The action to take on the pharmacy's stock", IsRequired=true, Name="QuarantinedStockAction", ParameterType="body")
    public quarantinedStockAction: QuarantinedStockAction;

    public constructor(init?: Partial<ActionQuarantinedStock>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ActionQuarantinedStock'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ActionQuarantinedStockResponse(); }
}

// @Route("/api/pharmacy/emails", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPharmacyEmails implements IReturn<GetPharmacyEmailsResponse>, IGet
{
    /**
    * The id of the pharmacy
    */
    // @ApiMember(DataType="int", Description="The id of the pharmacy", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    /**
    * The institution code of the pharmacy
    */
    // @ApiMember(DataType="string", Description="The institution code of the pharmacy", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    public constructor(init?: Partial<GetPharmacyEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPharmacyEmails'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPharmacyEmailsResponse(); }
}

// @Route("/api/timepoints", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTimepoints implements IReturn<GetTimepointsResponse>
{

    public constructor(init?: Partial<GetTimepoints>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTimepoints'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetTimepointsResponse(); }
}

// @Route("/api/timepoints/treatment/{TreatmentId}", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTimepointsByTreatmentId implements IReturn<GetTimepointsResponse>
{
    /**
    * The treatment id to get timepoints for
    */
    // @ApiMember(DataType="int", Description="The treatment id to get timepoints for", IsRequired=true, Name="TreatmentId", ParameterType="path")
    public treatmentId: number;

    public constructor(init?: Partial<GetTimepointsByTreatmentId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTimepointsByTreatmentId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetTimepointsResponse(); }
}

// @Route("/opms/sae/medicalreview/{PatientStudyNumber}/{SaeNumber}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeMedicalReviewSingleByPatientStudyNumber implements IReturn<SaeMedicalReviewSingleByPatientStudyNumberResponse>
{
    /**
    * The patient study number of the sae
    */
    // @DataMember(Order=1)
    // @ApiMember(Description="The patient study number of the sae", IsRequired=true, Name="PatientStudyNumber", ParameterType="string")
    public patientStudyNumber: string;

    /**
    * The sae number
    */
    // @DataMember(Order=2)
    // @ApiMember(Description="The sae number", IsRequired=true, Name="SaeNumber", ParameterType="int")
    public saeNumber: number;

    public constructor(init?: Partial<SaeMedicalReviewSingleByPatientStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeMedicalReviewSingleByPatientStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeMedicalReviewSingleByPatientStudyNumberResponse(); }
}

// @Route("/opms/sae/medicalreview/save-narrative-summary", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeMedicalReviewNarrativeSummaryPostSave implements IReturn<SaeMedicalReviewNarrativeSummaryPostSaveResponse>
{
    /**
    * The medical review id of the narrative to save
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="integer", Description="The medical review id of the narrative to save", IsRequired=true, Name="MedicalReviewId", ParameterType="body")
    public medicalReviewId: number;

    /**
    * The narrative to save
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="string", Description="The narrative to save", IsRequired=true, Name="NarrativeSummary", ParameterType="body")
    public narrativeSummary: string;

    public constructor(init?: Partial<SaeMedicalReviewNarrativeSummaryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeMedicalReviewNarrativeSummaryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeMedicalReviewNarrativeSummaryPostSaveResponse(); }
}

// @Route("/opms/sae/medicalreview/save", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeMedicalReviewPostSave implements IReturn<SaeMedicalReviewPostSaveResponse>
{
    /**
    * The medical review to save
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="model", Description="The medical review to save", IsRequired=true, Name="PatientStudyNumber", ParameterType="body")
    public medicalReview: MedicalReview;

    public constructor(init?: Partial<SaeMedicalReviewPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeMedicalReviewPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeMedicalReviewPostSaveResponse(); }
}

// @Route("/opms/sae/medicalreview/validate", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeValidateMedicalReview implements IReturn<SaeValidateMedicalReviewResponse>
{
    /**
    * The medical review
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="MedicalReview", Description="The medical review", IsRequired=true, Name="MedicalReview", ParameterType="body")
    public medicalReview: MedicalReview;

    public constructor(init?: Partial<SaeValidateMedicalReview>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeValidateMedicalReview'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeValidateMedicalReviewResponse(); }
}

/**
* Sae
*/
// @Route("/api/sae/collection", "GET")
// @Api(Description="Sae")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=500)
// @DataContract
export class SaeGetCollection implements IReturn<SaeCollectionResponse>, ISaeGetCollection
{

    public constructor(init?: Partial<SaeGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeCollectionResponse(); }
}

/**
* Sae
*/
// @Route("/api/sae/collection/institution/{InstitutionCode}", "GET")
// @Api(Description="Sae")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=500)
// @DataContract
export class SaeGetCollectionByInstitutionCode implements IReturn<SaeCollectionResponse>, ISaeGetCollectionByInstitutionCode
{
    /**
    * The code of the Institution associated with the sae to retrieve.
    */
    // @DataMember(Order=1)
    public institutionCode: string;

    public constructor(init?: Partial<SaeGetCollectionByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetCollectionByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeCollectionResponse(); }
}

/**
* Sae
*/
// @Route("/api/sae/single/patient-study-number/{PatientStudyNumber}/sae-number/{SaeNumber}", "GET")
// @Api(Description="Sae")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Sae.Sae.SaeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Sae.Sae.SaeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Sae.Sae.SaeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Sae.Sae.SaeSingleResponse)", StatusCode=500)
// @DataContract
export class SaeGetSingleByPatientStudyNumber implements IReturn<SaeSingleResponse>, ISaeGetSingleByPatientStudyNumber
{
    /**
    * The study number of the Patient associated with the sae to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The sae number of the Sae associated with the sae to retrieve.
    */
    // @DataMember(Order=2)
    public saeNumber: number;

    public constructor(init?: Partial<SaeGetSingleByPatientStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetSingleByPatientStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeSingleResponse(); }
}

// @Route("/opms/sae/collection/status", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeGetCollectionByStatus implements IReturn<SaeGetCollectionByStatusResponse>
{
    /**
    * The status of the saes
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="integer", Description="The status of the saes", IsRequired=true, Name="Status", ParameterType="body")
    public status: SaeStatus;

    public constructor(init?: Partial<SaeGetCollectionByStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetCollectionByStatus'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeGetCollectionByStatusResponse(); }
}

// @Route("/opms/sae/webaction", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeWebActionSave implements IReturn<SaeWebActionSaveResponse>
{
    /**
    * The sae web action
    */
    // @DataMember(Order=1)
    // @ApiMember(Description="The sae web action", IsRequired=true, Name="WebAction", ParameterType="model")
    public webAction: WebAction;

    public constructor(init?: Partial<SaeWebActionSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeWebActionSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeWebActionSaveResponse(); }
}

// @Route("/opms/sae/webaction/medicalreview", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeMedicalReviewWebActionSave implements IReturn<SaeMedicalReviewWebActionSaveResponse>
{
    /**
    * The patient study number of the sae
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="string", Description="The patient study number of the sae", IsRequired=true, Name="PatientStudyNumber", ParameterType="body")
    public patientStudyNumber: string;

    /**
    * The sae number of the sae
    */
    // @DataMember(Order=2)
    // @ApiMember(DataType="integer", Description="The sae number of the sae", IsRequired=true, Name="SaeNumber", ParameterType="body")
    public saeNumber: number;

    /**
    * The medical review form from which the sae web action will be based off
    */
    // @DataMember(Order=3)
    // @ApiMember(Description="The medical review form from which the sae web action will be based off", IsRequired=true, Name="SaeMedicalReviewForm", ParameterType="model")
    public medicalReview: SaeMedicalReviewForm;

    public constructor(init?: Partial<SaeMedicalReviewWebActionSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeMedicalReviewWebActionSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeMedicalReviewWebActionSaveResponse(); }
}

// @Route("/opms/sae/send-notification", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeSendNotification implements IReturn<SaeSendNotificationResponse>
{
    /**
    * The patient study number of the sae
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="string", Description="The patient study number of the sae", IsRequired=true, Name="PatientStudyNumber", ParameterType="body")
    public patientStudyNumber: string;

    /**
    * The sae number of the sae
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="integer", Description="The sae number of the sae", IsRequired=true, Name="SaeNumber", ParameterType="body")
    public saeNumber: number;

    /**
    * The notification form
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="SaeNotificationForm", Description="The notification form", IsRequired=true, Name="NotificationForm", ParameterType="body")
    public notificationForm: SaeNotificationForm;

    public constructor(init?: Partial<SaeSendNotification>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeSendNotification'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeSendNotificationResponse(); }
}

// @Route("/opms/sae/validate-notification", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeValidateNotificationForm implements IReturn<SaeValidateNotificationFormResponse>
{
    /**
    * The notification form
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="SaeNotificationForm", Description="The notification form", IsRequired=true, Name="NotificationForm", ParameterType="body")
    public form: SaeNotificationForm;

    public constructor(init?: Partial<SaeValidateNotificationForm>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeValidateNotificationForm'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeValidateNotificationFormResponse(); }
}

// @Route("/opms/sae/validate-rejection", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeValidateRejectionForm implements IReturn<SaeValidateRejectionFormResponse>
{
    /**
    * The rejection form
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="WebAction", Description="The rejection form", IsRequired=true, Name="RejectionForm", ParameterType="body")
    public form: WebAction;

    public constructor(init?: Partial<SaeValidateRejectionForm>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeValidateRejectionForm'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeValidateRejectionFormResponse(); }
}

// @Route("/opms/sae/open-clinica/document", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeGetOpenClinicaDocument implements IReturn<Blob>, IGet
{
    /**
    * The file name of the document in openclinica
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="string", Description="The file name of the document in openclinica", IsRequired=true, Name="FileName", ParameterType="body")
    public fileName: string;

    public constructor(init?: Partial<SaeGetOpenClinicaDocument>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetOpenClinicaDocument'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeningsummary/single/id/{Id}", "GET")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryGetSingleById implements IReturn<ScreeningSummarySingleResponse>, IScreeningSummaryGetSingleById
{
    /**
    * The ID of the screeningsummary to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ScreeningSummaryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningSummaryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningSummarySingleResponse(); }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeningsummary/collection", "GET")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryGetCollection implements IReturn<ScreeningSummaryCollectionResponse>, IScreeningSummaryGetCollection
{
    /**
    * The ID of the Institution associated with the screeningsummary to retrieve.
    */
    // @DataMember(Order=1)
    public institutionId?: number;

    /**
    * The Year associated with the screeningsummary to retrieve.
    */
    // @DataMember(Order=2)
    public year?: number;

    public constructor(init?: Partial<ScreeningSummaryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningSummaryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningSummaryCollectionResponse(); }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeningsummary/collection/institution/id/{InstitutionId}", "GET")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryGetCollectionByInstitutionId implements IReturn<ScreeningSummaryCollectionResponse>, IScreeningSummaryGetCollectionByInstitutionId
{
    /**
    * The ID of the Institution associated with the screeningsummary to retrieve.
    */
    // @DataMember(Order=1)
    public institutionId?: number;

    public constructor(init?: Partial<ScreeningSummaryGetCollectionByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningSummaryGetCollectionByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningSummaryCollectionResponse(); }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeningsummary/save", "POST")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryPostSave implements IReturn<ScreeningSummarySingleResponse>
{
    /**
    * The screeningsummary to save.
    */
    // @DataMember(Order=1)
    public screeningSummary: ScreeningSummary;

    public constructor(init?: Partial<ScreeningSummaryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningSummaryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ScreeningSummarySingleResponse(); }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeningsummary/validate", "POST")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningSummaryFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningSummaryFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningSummaryFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningSummaryFormValidationResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryPostValidate implements IReturn<ScreeningSummaryFormValidationResponse>
{
    /**
    * The screeningsummary to validate.
    */
    // @DataMember(Order=1)
    public screeningSummary: ScreeningSummary;

    public constructor(init?: Partial<ScreeningSummaryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningSummaryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ScreeningSummaryFormValidationResponse(); }
}

// @Route("/opms/file-upload", "POST")
export class PostUploadFile implements IReturn<UploadResponse>, IPost
{

    public constructor(init?: Partial<PostUploadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostUploadFile'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/file-upload/info/{Guid}", "GET")
export class GetUploadInfo implements IReturn<UploadResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUploadInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUploadInfo'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/file-upload/{Guid}", "GET")
export class GetUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file-upload/download/{Guid}", "GET")
export class DownloadUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<DownloadUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file-upload/{Guid}", "DELETE")
export class DeleteUpload implements IReturn<UploadResponse>, IDelete
{
    public guid: string;

    public constructor(init?: Partial<DeleteUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteUpload'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/user")
export class GetUser implements IReturn<GetUserResponse>, IGet
{

    public constructor(init?: Partial<GetUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUser'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetUserResponse(); }
}

/**
* Lookup
*/
// @Route("/opms/lookup/collection/form-definition/{FormDefinitionId}", "GET")
// @Api(Description="Lookup")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=500)
// @DataContract
export class LookupGetCollectionByFormDefinitionId implements IReturn<LookupCollectionResponse>, ILookupGetCollectionByFormDefinitionId
{
    /**
    * The ID of the Form Definition associated with the lookup to retrieve.
    */
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    public constructor(init?: Partial<LookupGetCollectionByFormDefinitionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LookupGetCollectionByFormDefinitionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LookupCollectionResponse(); }
}

// @Route("/opms/form-names")
export class GetFormMetadata implements IReturn<GetFormMetadataResponse>
{

    public constructor(init?: Partial<GetFormMetadata>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFormMetadata'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetFormMetadataResponse(); }
}

/**
* Event Definition
*/
// @Route("/opms/event-definition/single/id/{Id}", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetSingleById implements IReturn<EventDefinitionSingleResponse>, IEventDefinitionGetSingleById
{
    /**
    * The ID of the event definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EventDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionSingleResponse(); }
}

/**
* Event Definition
*/
// @Route("/opms/event-definition/single/code/{Code}", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetSingleByCode implements IReturn<EventDefinitionSingleResponse>, IEventDefinitionGetSingleByCode
{
    /**
    * The ID of the event definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<EventDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionSingleResponse(); }
}

/**
* Event Definition
*/
// @Route("/opms/event-definition/collection", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetCollection implements IReturn<EventDefinitionCollectionResponse>, IEventDefinitionGetCollection
{

    public constructor(init?: Partial<EventDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionCollectionResponse(); }
}

/**
* Form Definition
*/
// @Route("/opms/form-definition/single/id/{Id}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleById implements IReturn<FormDefinitionSingleResponse>, IFormDefinitionGetSingleById
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

/**
* Form Definition
*/
// @Route("/opms/form-definition/single/code/{Code}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleByCode implements IReturn<FormDefinitionSingleResponse>, IFormDefinitionGetSingleByCode
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

/**
* Form Definition
*/
// @Route("/opms/form-definition/collection", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetCollection implements IReturn<FormDefinitionCollectionResponse>, IFormDefinitionGetCollection
{

    public constructor(init?: Partial<FormDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionCollectionResponse(); }
}

/**
* Patient State
*/
// @Route("/opms/patient-state/single/id/{Id}", "GET")
// @Api(Description="Patient State")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=500)
// @DataContract
export class PatientStateGetSingleById implements IReturn<PatientStateSingleResponse>, IPatientStateGetSingleById
{
    /**
    * The ID of the patient state to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientStateGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStateGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStateSingleResponse(); }
}

/**
* Patient State
*/
// @Route("/opms/patient-state/collection", "GET")
// @Api(Description="Patient State")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientStateGetCollection implements IReturn<PatientStateCollectionResponse>, IPatientStateGetCollection
{

    public constructor(init?: Partial<PatientStateGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStateGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStateCollectionResponse(); }
}

/**
* Treatment
*/
// @Route("/opms/treatment/single/id/{Id}", "GET")
// @Api(Description="Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetSingleById implements IReturn<TreatmentSingleResponse>, ITreatmentGetSingleById
{
    /**
    * The ID of the treatment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentSingleResponse(); }
}

/**
* Treatment
*/
// @Route("/opms/treatment/collection", "GET")
// @Api(Description="Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetCollection implements IReturn<TreatmentCollectionResponse>, ITreatmentGetCollection
{

    public constructor(init?: Partial<TreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentCollectionResponse(); }
}

/**
* Master Group
*/
// @Route("/opms/master-group/single/id/{Id}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleById implements IReturn<MasterGroupSingleResponse>, IMasterGroupGetSingleById
{
    /**
    * The ID of the master group to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MasterGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

/**
* Master Group
*/
// @Route("/opms/master-group/single/code/{Code}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleByCode implements IReturn<MasterGroupSingleResponse>, IMasterGroupGetSingleByCode
{
    /**
    * The ID of the master group to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<MasterGroupGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

/**
* Master Group
*/
// @Route("/opms/master-group/collection", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetCollection implements IReturn<MasterGroupCollectionResponse>, IMasterGroupGetCollection
{

    public constructor(init?: Partial<MasterGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupCollectionResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/single/id/{Id}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetSingleById implements IReturn<CollaboratingGroupSingleResponse>, ICollaboratingGroupGetSingleById
{
    /**
    * The ID of the collaborating group to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CollaboratingGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupSingleResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/single/code/{Code}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetSingleByCode implements IReturn<CollaboratingGroupSingleResponse>, ICollaboratingGroupGetSingleByCode
{
    /**
    * The ID of the collaborating group to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<CollaboratingGroupGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupSingleResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/collection", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollection implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollection
{

    public constructor(init?: Partial<CollaboratingGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollectionByMasterGroupId implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollectionByMasterGroupId
{
    /**
    * The ID of the Master Group associated with the collaborating group to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupId?: number;

    public constructor(init?: Partial<CollaboratingGroupGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollectionByMasterGroupCode implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollectionByMasterGroupCode
{
    /**
    * The Code of the Master Group associated with the collaborating group to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupCode: string;

    public constructor(init?: Partial<CollaboratingGroupGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/single/id/{Id}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleById implements IReturn<CountrySingleResponse>, ICountryGetSingleById
{
    /**
    * The ID of the country to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CountryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/single/code/{Code}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleByCode implements IReturn<CountrySingleResponse>, ICountryGetSingleByCode
{
    /**
    * The ID of the country to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<CountryGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollection implements IReturn<CountryCollectionResponse>, ICountryGetCollection
{

    public constructor(init?: Partial<CountryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByMasterGroupId implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByMasterGroupId
{
    /**
    * The ID of the Master Group associated with the country to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupId?: number;

    public constructor(init?: Partial<CountryGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByMasterGroupCode implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByMasterGroupCode
{
    /**
    * The Code of the Master Group associated with the country to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupCode: string;

    public constructor(init?: Partial<CountryGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByCollaboratingGroupId implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByCollaboratingGroupId
{
    /**
    * The ID of the Collaborating Group associated with the country to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupId?: number;

    public constructor(init?: Partial<CountryGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByCollaboratingGroupCode implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByCollaboratingGroupCode
{
    /**
    * The Code of the Collaborating Group associated with the country to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    public constructor(init?: Partial<CountryGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/single/id/{Id}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleById implements IReturn<InstitutionSingleResponse>, IInstitutionGetSingleById
{
    /**
    * The ID of the institution to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<InstitutionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/single/code/{Code}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleByCode implements IReturn<InstitutionSingleResponse>, IInstitutionGetSingleByCode
{
    /**
    * The ID of the institution to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<InstitutionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollection implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollection
{

    public constructor(init?: Partial<InstitutionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/country/id/{CountryId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCountryId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCountryId
{
    /**
    * The ID of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByCountryId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCountryId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByMasterGroupId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByMasterGroupId
{
    /**
    * The ID of the Master Group associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupId: number;

    /**
    * The ID of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=2)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCollaboratingGroupId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCollaboratingGroupId
{
    /**
    * The ID of the Collaborating Group associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupId: number;

    /**
    * The ID of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=2)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/country/code/{CountryCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCountryCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCountryCode
{
    /**
    * The Code of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByCountryCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCountryCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByMasterGroupCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByMasterGroupCode
{
    /**
    * The Code of the Master Group associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupCode: string;

    /**
    * The Code of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=2)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCollaboratingGroupCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCollaboratingGroupCode
{
    /**
    * The Code of the Collaborating Group associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    /**
    * The Code of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=2)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/single/id/{Id}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleById implements IReturn<PatientSingleResponse>, IPatientGetSingleById
{
    /**
    * The ID of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/single/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleByStudyNumber implements IReturn<PatientSingleResponse>, IPatientGetSingleByStudyNumber
{
    /**
    * The Study Number of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetSingleByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/validation/id/{Id}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientGetValidationById implements IReturn<PatientValidationResponse>, IPatientGetValidationById
{
    /**
    * The ID of the patient to validate.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetValidationById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetValidationById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientValidationResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/validation/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientGetValidationByStudyNumber implements IReturn<PatientValidationResponse>, IPatientGetValidationByStudyNumber
{
    /**
    * The Study Number of the patient to validate.
    */
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetValidationByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetValidationByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientValidationResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollection implements IReturn<PatientCollectionResponse>, IPatientGetCollection
{
    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByMasterGroupId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByMasterGroupId
{
    /**
    * The ID of the Master Group associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupId: number;

    /**
    * The ID of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public countryId?: number;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCollaboratingGroupId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCollaboratingGroupId
{
    /**
    * The ID of the Collaborating Group associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupId: number;

    /**
    * The ID of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public countryId?: number;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/country/id/{CountryId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCountryId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCountryId
{
    /**
    * The ID of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public countryId: number;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCountryId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCountryId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/institution/id/{InstitutionId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByInstitutionId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByInstitutionId
{
    /**
    * The ID of the Institution associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public institutionId: number;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByMasterGroupCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByMasterGroupCode
{
    /**
    * The Code of the Master Group associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupCode: string;

    /**
    * The Code of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public countryCode: string;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCollaboratingGroupCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCollaboratingGroupCode
{
    /**
    * The Code of the Collaborating Group associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    /**
    * The Code of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public countryCode: string;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/country/code/{CountryCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCountryCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCountryCode
{
    /**
    * The Code of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public countryCode: string;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCountryCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCountryCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/institution/code/{InstitutionCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByInstitutionCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByInstitutionCode
{
    /**
    * The Code of the Institution associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public institutionCode: string;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/Save", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSave implements IReturn<PatientSingleResponse>
{
    /**
    * The patient to save.
    */
    // @DataMember(Order=1)
    public patient: Patient;

    public constructor(init?: Partial<PatientPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/save/ids", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSaveWithIds implements IReturn<PatientSingleResponse>
{
    /**
    * The patient to save.
    */
    // @DataMember(Order=1)
    public patient: Patient;

    /**
    * The ID of the Institution associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    public constructor(init?: Partial<PatientPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/save/codes", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSaveWithCodes implements IReturn<PatientSingleResponse>
{
    /**
    * The patient to save.
    */
    // @DataMember(Order=1)
    public patient: Patient;

    /**
    * The ID of the Institution associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    public constructor(init?: Partial<PatientPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/single/id/{Id}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleById implements IReturn<EventSingleResponse>, IEventGetSingleById
{
    /**
    * The ID of the event to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EventGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/single/ids", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleByIds implements IReturn<EventSingleResponse>, IEventGetSingleByIds
{
    /**
    * The Patient ID of the event to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Id of the Institution associated to the event to retrieve.
    */
    // @DataMember(Order=4)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=5)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=6)
    public createEvent?: boolean;

    public constructor(init?: Partial<EventGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/single/codes", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleByCodes implements IReturn<EventSingleResponse>, IEventGetSingleByCodes
{
    /**
    * The Patient Study Number of the event to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Code of the Institution associated to the event to retrieve.
    */
    // @DataMember(Order=4)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=5)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=6)
    public createEvent?: boolean;

    public constructor(init?: Partial<EventGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/collection", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollection implements IReturn<EventCollectionResponse>, IEventGetCollection
{

    public constructor(init?: Partial<EventGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/collection/patient/id/{PatientId}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollectionByPatientId implements IReturn<EventCollectionResponse>, IEventGetCollectionByPatientId
{
    /**
    * The ID of the Patient associated with the event to retrieve.
    */
    // @DataMember(Order=1)
    public patientId: number;

    public constructor(init?: Partial<EventGetCollectionByPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollectionByPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/collection/patient/study-number/{PatientStudyNumber}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollectionByPatientStudyNumber implements IReturn<EventCollectionResponse>, IEventGetCollectionByPatientStudyNumber
{
    /**
    * The Study Number of the Patient associated with the event to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    public constructor(init?: Partial<EventGetCollectionByPatientStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollectionByPatientStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/Save", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSave implements IReturn<EventSingleResponse>
{
    /**
    * The event to save.
    */
    // @DataMember(Order=1)
    public event: Event;

    public constructor(init?: Partial<EventPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/save/ids", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSaveWithIds implements IReturn<EventSingleResponse>
{
    /**
    * The event to save.
    */
    // @DataMember(Order=1)
    public event: Event;

    /**
    * The ID of the Institution associated with the event to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the event to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition associated with the event to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Repeat number of the event to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    public constructor(init?: Partial<EventPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/save/codes", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSaveWithCodes implements IReturn<EventSingleResponse>
{
    /**
    * The event to save.
    */
    // @DataMember(Order=1)
    public event: Event;

    /**
    * The Code of the Institution associated with the event to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the event to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the event to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Repeat number of the event to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    public constructor(init?: Partial<EventPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/single/id/{Id}", "GET")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetSingleById implements IReturn<AdverseEventSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the adverse event to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdverseEventGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/single/ids", "GET")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetSingleByIds implements IReturn<AdverseEventSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the adverse event to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the adverse event to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the adverse event to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new adverse event if no adverse event is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<AdverseEventGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/single/codes", "GET")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetSingleByCodes implements IReturn<AdverseEventSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the adverse event to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the adverse event to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the adverse event to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new adverse event if no adverse event is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<AdverseEventGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/collection", "GET")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetCollection implements IReturn<AdverseEventCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<AdverseEventGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventCollectionResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetCollectionByIds implements IReturn<AdverseEventCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the adverse event to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdverseEventGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventCollectionResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetCollectionByCodes implements IReturn<AdverseEventCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the adverse event to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdverseEventGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventCollectionResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/save", "POST")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostSave implements IReturn<AdverseEventSingleResponse>
{
    /**
    * The adverse event to save.
    */
    // @DataMember(Order=1)
    public form: AdverseEvent;

    public constructor(init?: Partial<AdverseEventPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/save/ids", "POST")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostSaveWithIds implements IReturn<AdverseEventSingleResponse>
{
    /**
    * The adverse event to save.
    */
    // @DataMember(Order=1)
    public form: AdverseEvent;

    /**
    * The ID of the Institution associated with the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the adverse event to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the adverse event to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<AdverseEventPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/save/codes", "POST")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostSaveWithCodes implements IReturn<AdverseEventSingleResponse>
{
    /**
    * The adverse event to save.
    */
    // @DataMember(Order=1)
    public form: AdverseEvent;

    /**
    * The Code of the Institution associated with the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the adverse event to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the adverse event to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the adverse event to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<AdverseEventPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/delete", "DELETE")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=500)
// @DataContract
export class AdverseEventDelete implements IReturn<AdverseEventDeleteResponse>
{
    /**
    * The adverse event to delete.
    */
    // @DataMember(Order=1)
    public form: AdverseEvent;

    public constructor(init?: Partial<AdverseEventDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdverseEventDeleteResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=500)
// @DataContract
export class AdverseEventDeleteWithId implements IReturn<AdverseEventDeleteResponse>
{
    /**
    * The ID of the adverse event to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdverseEventDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdverseEventDeleteResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/delete/ids", "DELETE")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=500)
// @DataContract
export class AdverseEventDeleteWithIds implements IReturn<AdverseEventDeleteResponse>
{
    /**
    * The Patient ID of the adverse event to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the adverse event to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<AdverseEventDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdverseEventDeleteResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/delete/codes", "DELETE")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=500)
// @DataContract
export class AdverseEventDeleteByCodes implements IReturn<AdverseEventDeleteResponse>
{
    /**
    * The Patient Study Number of the adverse event to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the adverse event to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<AdverseEventDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdverseEventDeleteResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/validate", "POST")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostValidate implements IReturn<AdverseEventValidationResponse>
{
    /**
    * The adverse event to validate.
    */
    // @DataMember(Order=1)
    public form: AdverseEvent;

    public constructor(init?: Partial<AdverseEventPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventValidationResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/validate/ids", "POST")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostValidateWithIds implements IReturn<AdverseEventValidationResponse>
{
    /**
    * The adverse event to validate.
    */
    // @DataMember(Order=1)
    public form: AdverseEvent;

    /**
    * The ID of the Institution associated with the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the adverse event to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdverseEventPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventValidationResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/validate/codes", "POST")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostValidateWithCodes implements IReturn<AdverseEventValidationResponse>
{
    /**
    * The adverse event to validate.
    */
    // @DataMember(Order=1)
    public form: AdverseEvent;

    /**
    * The Code of the Institution associated with the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the adverse event to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the adverse event to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdverseEventPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventValidationResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/single/id/{Id}", "GET")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormGetSingleById implements IReturn<AdverseEventFormSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdverseEventFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventFormSingleResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/single/ids", "GET")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormGetSingleByIds implements IReturn<AdverseEventFormSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the adverse event form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new adverse event form if no adverse event form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<AdverseEventFormGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventFormSingleResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/single/codes", "GET")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormGetSingleByCodes implements IReturn<AdverseEventFormSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the adverse event form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new adverse event form if no adverse event form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<AdverseEventFormGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventFormSingleResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/collection", "GET")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormGetCollection implements IReturn<AdverseEventFormCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<AdverseEventFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventFormCollectionResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormGetCollectionByIds implements IReturn<AdverseEventFormCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdverseEventFormGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventFormCollectionResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormGetCollectionByCodes implements IReturn<AdverseEventFormCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdverseEventFormGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventFormCollectionResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/save", "POST")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormPostSave implements IReturn<AdverseEventFormSingleResponse>
{
    /**
    * The adverse event form to save.
    */
    // @DataMember(Order=1)
    public form: AdverseEventForm;

    public constructor(init?: Partial<AdverseEventFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventFormSingleResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/save/ids", "POST")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormPostSaveWithIds implements IReturn<AdverseEventFormSingleResponse>
{
    /**
    * The adverse event form to save.
    */
    // @DataMember(Order=1)
    public form: AdverseEventForm;

    /**
    * The ID of the Institution associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the adverse event form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<AdverseEventFormPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventFormSingleResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/save/codes", "POST")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormPostSaveWithCodes implements IReturn<AdverseEventFormSingleResponse>
{
    /**
    * The adverse event form to save.
    */
    // @DataMember(Order=1)
    public form: AdverseEventForm;

    /**
    * The Code of the Institution associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the adverse event form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<AdverseEventFormPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventFormSingleResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/delete", "DELETE")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormDelete implements IReturn<AdverseEventFormDeleteResponse>
{
    /**
    * The adverse event form to delete.
    */
    // @DataMember(Order=1)
    public form: AdverseEventForm;

    public constructor(init?: Partial<AdverseEventFormDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdverseEventFormDeleteResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormDeleteWithId implements IReturn<AdverseEventFormDeleteResponse>
{
    /**
    * The ID of the adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdverseEventFormDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdverseEventFormDeleteResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/delete/ids", "DELETE")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormDeleteWithIds implements IReturn<AdverseEventFormDeleteResponse>
{
    /**
    * The Patient ID of the adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<AdverseEventFormDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdverseEventFormDeleteResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/delete/codes", "DELETE")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormDeleteByCodes implements IReturn<AdverseEventFormDeleteResponse>
{
    /**
    * The Patient Study Number of the adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<AdverseEventFormDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdverseEventFormDeleteResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/validate", "POST")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormPostValidate implements IReturn<AdverseEventFormValidationResponse>
{
    /**
    * The adverse event form to validate.
    */
    // @DataMember(Order=1)
    public form: AdverseEventForm;

    public constructor(init?: Partial<AdverseEventFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventFormValidationResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/validate/ids", "POST")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormPostValidateWithIds implements IReturn<AdverseEventFormValidationResponse>
{
    /**
    * The adverse event form to validate.
    */
    // @DataMember(Order=1)
    public form: AdverseEventForm;

    /**
    * The ID of the Institution associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdverseEventFormPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventFormValidationResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/validate/codes", "POST")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormPostValidateWithCodes implements IReturn<AdverseEventFormValidationResponse>
{
    /**
    * The adverse event form to validate.
    */
    // @DataMember(Order=1)
    public form: AdverseEventForm;

    /**
    * The Code of the Institution associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdverseEventFormPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventFormValidationResponse(); }
}

/**
* Form - Bloods Clinical
*/
// @Route("/opms/form/bloods-clinical/single/id/{Id}", "GET")
// @Api(Description="Form - Bloods Clinical")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=500)
// @DataContract
export class BloodsClinicalGetSingleById implements IReturn<BloodsClinicalSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the bloods clinical to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BloodsClinicalGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsClinicalGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BloodsClinicalSingleResponse(); }
}

/**
* Form - Bloods Clinical
*/
// @Route("/opms/form/bloods-clinical/single/ids", "GET")
// @Api(Description="Form - Bloods Clinical")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=500)
// @DataContract
export class BloodsClinicalGetSingleByIds implements IReturn<BloodsClinicalSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the bloods clinical to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the bloods clinical to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the bloods clinical to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new bloods clinical if no bloods clinical is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<BloodsClinicalGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsClinicalGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BloodsClinicalSingleResponse(); }
}

/**
* Form - Bloods Clinical
*/
// @Route("/opms/form/bloods-clinical/single/codes", "GET")
// @Api(Description="Form - Bloods Clinical")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=500)
// @DataContract
export class BloodsClinicalGetSingleByCodes implements IReturn<BloodsClinicalSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the bloods clinical to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the bloods clinical to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the bloods clinical to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new bloods clinical if no bloods clinical is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<BloodsClinicalGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsClinicalGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BloodsClinicalSingleResponse(); }
}

/**
* Form - Bloods Clinical
*/
// @Route("/opms/form/bloods-clinical/collection", "GET")
// @Api(Description="Form - Bloods Clinical")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsClinicalCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsClinicalCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsClinicalCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsClinicalCollectionResponse)", StatusCode=500)
// @DataContract
export class BloodsClinicalGetCollection implements IReturn<BloodsClinicalCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<BloodsClinicalGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsClinicalGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BloodsClinicalCollectionResponse(); }
}

/**
* Form - Bloods Clinical
*/
// @Route("/opms/form/bloods-clinical/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Bloods Clinical")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsClinicalCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsClinicalCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsClinicalCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsClinicalCollectionResponse)", StatusCode=500)
// @DataContract
export class BloodsClinicalGetCollectionByIds implements IReturn<BloodsClinicalCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the bloods clinical to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<BloodsClinicalGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsClinicalGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BloodsClinicalCollectionResponse(); }
}

/**
* Form - Bloods Clinical
*/
// @Route("/opms/form/bloods-clinical/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Bloods Clinical")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsClinicalCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsClinicalCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsClinicalCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsClinicalCollectionResponse)", StatusCode=500)
// @DataContract
export class BloodsClinicalGetCollectionByCodes implements IReturn<BloodsClinicalCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<BloodsClinicalGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsClinicalGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BloodsClinicalCollectionResponse(); }
}

/**
* Form - Bloods Clinical
*/
// @Route("/opms/form/bloods-clinical/save", "POST")
// @Api(Description="Form - Bloods Clinical")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=500)
// @DataContract
export class BloodsClinicalPostSave implements IReturn<BloodsClinicalSingleResponse>
{
    /**
    * The bloods clinical to save.
    */
    // @DataMember(Order=1)
    public form: BloodsClinical;

    public constructor(init?: Partial<BloodsClinicalPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsClinicalPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BloodsClinicalSingleResponse(); }
}

/**
* Form - Bloods Clinical
*/
// @Route("/opms/form/bloods-clinical/save/ids", "POST")
// @Api(Description="Form - Bloods Clinical")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=500)
// @DataContract
export class BloodsClinicalPostSaveWithIds implements IReturn<BloodsClinicalSingleResponse>
{
    /**
    * The bloods clinical to save.
    */
    // @DataMember(Order=1)
    public form: BloodsClinical;

    /**
    * The ID of the Institution associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the bloods clinical to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<BloodsClinicalPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsClinicalPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BloodsClinicalSingleResponse(); }
}

/**
* Form - Bloods Clinical
*/
// @Route("/opms/form/bloods-clinical/save/codes", "POST")
// @Api(Description="Form - Bloods Clinical")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsClinicalSingleResponse)", StatusCode=500)
// @DataContract
export class BloodsClinicalPostSaveWithCodes implements IReturn<BloodsClinicalSingleResponse>
{
    /**
    * The bloods clinical to save.
    */
    // @DataMember(Order=1)
    public form: BloodsClinical;

    /**
    * The Code of the Institution associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the bloods clinical to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<BloodsClinicalPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsClinicalPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BloodsClinicalSingleResponse(); }
}

/**
* Form - Bloods Clinical
*/
// @Route("/opms/form/bloods-clinical/delete", "DELETE")
// @Api(Description="Form - Bloods Clinical")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsClinicalDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsClinicalDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsClinicalDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsClinicalDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsClinicalDeleteResponse)", StatusCode=500)
// @DataContract
export class BloodsClinicalDelete implements IReturn<BloodsClinicalDeleteResponse>
{
    /**
    * The bloods clinical to delete.
    */
    // @DataMember(Order=1)
    public form: BloodsClinical;

    public constructor(init?: Partial<BloodsClinicalDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsClinicalDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BloodsClinicalDeleteResponse(); }
}

/**
* Form - Bloods Clinical
*/
// @Route("/opms/form/bloods-clinical/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Bloods Clinical")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsClinicalDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsClinicalDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsClinicalDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsClinicalDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsClinicalDeleteResponse)", StatusCode=500)
// @DataContract
export class BloodsClinicalDeleteWithId implements IReturn<BloodsClinicalDeleteResponse>
{
    /**
    * The ID of the bloods clinical to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BloodsClinicalDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsClinicalDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BloodsClinicalDeleteResponse(); }
}

/**
* Form - Bloods Clinical
*/
// @Route("/opms/form/bloods-clinical/delete/ids", "DELETE")
// @Api(Description="Form - Bloods Clinical")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsClinicalDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsClinicalDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsClinicalDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsClinicalDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsClinicalDeleteResponse)", StatusCode=500)
// @DataContract
export class BloodsClinicalDeleteWithIds implements IReturn<BloodsClinicalDeleteResponse>
{
    /**
    * The Patient ID of the bloods clinical to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the bloods clinical to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<BloodsClinicalDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsClinicalDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BloodsClinicalDeleteResponse(); }
}

/**
* Form - Bloods Clinical
*/
// @Route("/opms/form/bloods-clinical/delete/codes", "DELETE")
// @Api(Description="Form - Bloods Clinical")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsClinicalDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsClinicalDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsClinicalDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsClinicalDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsClinicalDeleteResponse)", StatusCode=500)
// @DataContract
export class BloodsClinicalDeleteByCodes implements IReturn<BloodsClinicalDeleteResponse>
{
    /**
    * The Patient Study Number of the bloods clinical to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the bloods clinical to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<BloodsClinicalDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsClinicalDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BloodsClinicalDeleteResponse(); }
}

/**
* Form - Bloods Clinical
*/
// @Route("/opms/form/bloods-clinical/validate", "POST")
// @Api(Description="Form - Bloods Clinical")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsClinicalValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsClinicalValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsClinicalValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsClinicalValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsClinicalValidationResponse)", StatusCode=500)
// @DataContract
export class BloodsClinicalPostValidate implements IReturn<BloodsClinicalValidationResponse>
{
    /**
    * The bloods clinical to validate.
    */
    // @DataMember(Order=1)
    public form: BloodsClinical;

    public constructor(init?: Partial<BloodsClinicalPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsClinicalPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BloodsClinicalValidationResponse(); }
}

/**
* Form - Bloods Clinical
*/
// @Route("/opms/form/bloods-clinical/validate/ids", "POST")
// @Api(Description="Form - Bloods Clinical")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsClinicalValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsClinicalValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsClinicalValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsClinicalValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsClinicalValidationResponse)", StatusCode=500)
// @DataContract
export class BloodsClinicalPostValidateWithIds implements IReturn<BloodsClinicalValidationResponse>
{
    /**
    * The bloods clinical to validate.
    */
    // @DataMember(Order=1)
    public form: BloodsClinical;

    /**
    * The ID of the Institution associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<BloodsClinicalPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsClinicalPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BloodsClinicalValidationResponse(); }
}

/**
* Form - Bloods Clinical
*/
// @Route("/opms/form/bloods-clinical/validate/codes", "POST")
// @Api(Description="Form - Bloods Clinical")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsClinicalValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsClinicalValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsClinicalValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsClinicalValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsClinicalValidationResponse)", StatusCode=500)
// @DataContract
export class BloodsClinicalPostValidateWithCodes implements IReturn<BloodsClinicalValidationResponse>
{
    /**
    * The bloods clinical to validate.
    */
    // @DataMember(Order=1)
    public form: BloodsClinical;

    /**
    * The Code of the Institution associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the bloods clinical to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<BloodsClinicalPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsClinicalPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BloodsClinicalValidationResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/single/id/{Id}", "GET")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentGetSingleById implements IReturn<ClinicalAssessmentSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ClinicalAssessmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentSingleResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/single/ids", "GET")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentGetSingleByIds implements IReturn<ClinicalAssessmentSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the clinical assessment to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new clinical assessment if no clinical assessment is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ClinicalAssessmentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentSingleResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/single/codes", "GET")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentGetSingleByCodes implements IReturn<ClinicalAssessmentSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the clinical assessment to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new clinical assessment if no clinical assessment is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ClinicalAssessmentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentSingleResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/collection", "GET")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentGetCollection implements IReturn<ClinicalAssessmentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ClinicalAssessmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentCollectionResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentGetCollectionByIds implements IReturn<ClinicalAssessmentCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ClinicalAssessmentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentCollectionResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentGetCollectionByCodes implements IReturn<ClinicalAssessmentCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ClinicalAssessmentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentCollectionResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/save", "POST")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentPostSave implements IReturn<ClinicalAssessmentSingleResponse>
{
    /**
    * The clinical assessment to save.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    public constructor(init?: Partial<ClinicalAssessmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentSingleResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/save/ids", "POST")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentPostSaveWithIds implements IReturn<ClinicalAssessmentSingleResponse>
{
    /**
    * The clinical assessment to save.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    /**
    * The ID of the Institution associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ClinicalAssessmentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentSingleResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/save/codes", "POST")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentPostSaveWithCodes implements IReturn<ClinicalAssessmentSingleResponse>
{
    /**
    * The clinical assessment to save.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    /**
    * The Code of the Institution associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ClinicalAssessmentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentSingleResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/delete", "DELETE")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentDelete implements IReturn<ClinicalAssessmentDeleteResponse>
{
    /**
    * The clinical assessment to delete.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    public constructor(init?: Partial<ClinicalAssessmentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ClinicalAssessmentDeleteResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentDeleteWithId implements IReturn<ClinicalAssessmentDeleteResponse>
{
    /**
    * The ID of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ClinicalAssessmentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ClinicalAssessmentDeleteResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/delete/ids", "DELETE")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentDeleteWithIds implements IReturn<ClinicalAssessmentDeleteResponse>
{
    /**
    * The Patient ID of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ClinicalAssessmentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ClinicalAssessmentDeleteResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/delete/codes", "DELETE")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentDeleteByCodes implements IReturn<ClinicalAssessmentDeleteResponse>
{
    /**
    * The Patient Study Number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ClinicalAssessmentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ClinicalAssessmentDeleteResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/validate", "POST")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentPostValidate implements IReturn<ClinicalAssessmentValidationResponse>
{
    /**
    * The clinical assessment to validate.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    public constructor(init?: Partial<ClinicalAssessmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentValidationResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/validate/ids", "POST")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentPostValidateWithIds implements IReturn<ClinicalAssessmentValidationResponse>
{
    /**
    * The clinical assessment to validate.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    /**
    * The ID of the Institution associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ClinicalAssessmentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentValidationResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/validate/codes", "POST")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentPostValidateWithCodes implements IReturn<ClinicalAssessmentValidationResponse>
{
    /**
    * The clinical assessment to validate.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    /**
    * The Code of the Institution associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ClinicalAssessmentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentValidationResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/single/id/{Id}", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetSingleById implements IReturn<ConcomitantMedicationSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/single/ids", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetSingleByIds implements IReturn<ConcomitantMedicationSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the concomitant medication to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new concomitant medication if no concomitant medication is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/single/codes", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetSingleByCodes implements IReturn<ConcomitantMedicationSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the concomitant medication to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new concomitant medication if no concomitant medication is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/collection", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetCollection implements IReturn<ConcomitantMedicationCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ConcomitantMedicationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationCollectionResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetCollectionByIds implements IReturn<ConcomitantMedicationCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationCollectionResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetCollectionByCodes implements IReturn<ConcomitantMedicationCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationCollectionResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/save", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostSave implements IReturn<ConcomitantMedicationSingleResponse>
{
    /**
    * The concomitant medication to save.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/save/ids", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostSaveWithIds implements IReturn<ConcomitantMedicationSingleResponse>
{
    /**
    * The concomitant medication to save.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    /**
    * The ID of the Institution associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/save/codes", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostSaveWithCodes implements IReturn<ConcomitantMedicationSingleResponse>
{
    /**
    * The concomitant medication to save.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    /**
    * The Code of the Institution associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/delete", "DELETE")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationDelete implements IReturn<ConcomitantMedicationDeleteResponse>
{
    /**
    * The concomitant medication to delete.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationDeleteResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationDeleteWithId implements IReturn<ConcomitantMedicationDeleteResponse>
{
    /**
    * The ID of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationDeleteResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/delete/ids", "DELETE")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationDeleteWithIds implements IReturn<ConcomitantMedicationDeleteResponse>
{
    /**
    * The Patient ID of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationDeleteResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/delete/codes", "DELETE")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationDeleteByCodes implements IReturn<ConcomitantMedicationDeleteResponse>
{
    /**
    * The Patient Study Number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationDeleteResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/validate", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostValidate implements IReturn<ConcomitantMedicationValidationResponse>
{
    /**
    * The concomitant medication to validate.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationValidationResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/validate/ids", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostValidateWithIds implements IReturn<ConcomitantMedicationValidationResponse>
{
    /**
    * The concomitant medication to validate.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    /**
    * The ID of the Institution associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationValidationResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/validate/codes", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostValidateWithCodes implements IReturn<ConcomitantMedicationValidationResponse>
{
    /**
    * The concomitant medication to validate.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    /**
    * The Code of the Institution associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationValidationResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/single/id/{Id}", "GET")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormGetSingleById implements IReturn<ConcomitantMedicationFormSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationFormSingleResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/single/ids", "GET")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormGetSingleByIds implements IReturn<ConcomitantMedicationFormSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the concomitant medication form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new concomitant medication form if no concomitant medication form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationFormGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationFormSingleResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/single/codes", "GET")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormGetSingleByCodes implements IReturn<ConcomitantMedicationFormSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the concomitant medication form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new concomitant medication form if no concomitant medication form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationFormGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationFormSingleResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/collection", "GET")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormGetCollection implements IReturn<ConcomitantMedicationFormCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ConcomitantMedicationFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationFormCollectionResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormGetCollectionByIds implements IReturn<ConcomitantMedicationFormCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationFormCollectionResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormGetCollectionByCodes implements IReturn<ConcomitantMedicationFormCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationFormCollectionResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/save", "POST")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormPostSave implements IReturn<ConcomitantMedicationFormSingleResponse>
{
    /**
    * The concomitant medication form to save.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    public constructor(init?: Partial<ConcomitantMedicationFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationFormSingleResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/save/ids", "POST")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormPostSaveWithIds implements IReturn<ConcomitantMedicationFormSingleResponse>
{
    /**
    * The concomitant medication form to save.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    /**
    * The ID of the Institution associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationFormPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationFormSingleResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/save/codes", "POST")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormPostSaveWithCodes implements IReturn<ConcomitantMedicationFormSingleResponse>
{
    /**
    * The concomitant medication form to save.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    /**
    * The Code of the Institution associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationFormPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationFormSingleResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/delete", "DELETE")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormDelete implements IReturn<ConcomitantMedicationFormDeleteResponse>
{
    /**
    * The concomitant medication form to delete.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    public constructor(init?: Partial<ConcomitantMedicationFormDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationFormDeleteResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormDeleteWithId implements IReturn<ConcomitantMedicationFormDeleteResponse>
{
    /**
    * The ID of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationFormDeleteResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/delete/ids", "DELETE")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormDeleteWithIds implements IReturn<ConcomitantMedicationFormDeleteResponse>
{
    /**
    * The Patient ID of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationFormDeleteResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/delete/codes", "DELETE")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormDeleteByCodes implements IReturn<ConcomitantMedicationFormDeleteResponse>
{
    /**
    * The Patient Study Number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationFormDeleteResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/validate", "POST")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormPostValidate implements IReturn<ConcomitantMedicationFormValidationResponse>
{
    /**
    * The concomitant medication form to validate.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    public constructor(init?: Partial<ConcomitantMedicationFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationFormValidationResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/validate/ids", "POST")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormPostValidateWithIds implements IReturn<ConcomitantMedicationFormValidationResponse>
{
    /**
    * The concomitant medication form to validate.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    /**
    * The ID of the Institution associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationFormValidationResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/validate/codes", "POST")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormPostValidateWithCodes implements IReturn<ConcomitantMedicationFormValidationResponse>
{
    /**
    * The concomitant medication form to validate.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    /**
    * The Code of the Institution associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationFormValidationResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/single/id/{Id}", "GET")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentGetSingleById implements IReturn<DiseaseAssessmentSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DiseaseAssessmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiseaseAssessmentSingleResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/single/ids", "GET")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentGetSingleByIds implements IReturn<DiseaseAssessmentSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the disease assessment to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new disease assessment if no disease assessment is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DiseaseAssessmentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiseaseAssessmentSingleResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/single/codes", "GET")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentGetSingleByCodes implements IReturn<DiseaseAssessmentSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the disease assessment to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new disease assessment if no disease assessment is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DiseaseAssessmentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiseaseAssessmentSingleResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/collection", "GET")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentGetCollection implements IReturn<DiseaseAssessmentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<DiseaseAssessmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiseaseAssessmentCollectionResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentGetCollectionByIds implements IReturn<DiseaseAssessmentCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DiseaseAssessmentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiseaseAssessmentCollectionResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentGetCollectionByCodes implements IReturn<DiseaseAssessmentCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DiseaseAssessmentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiseaseAssessmentCollectionResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/save", "POST")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentPostSave implements IReturn<DiseaseAssessmentSingleResponse>
{
    /**
    * The disease assessment to save.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    public constructor(init?: Partial<DiseaseAssessmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiseaseAssessmentSingleResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/save/ids", "POST")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentPostSaveWithIds implements IReturn<DiseaseAssessmentSingleResponse>
{
    /**
    * The disease assessment to save.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    /**
    * The ID of the Institution associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the disease assessment to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DiseaseAssessmentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiseaseAssessmentSingleResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/save/codes", "POST")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentPostSaveWithCodes implements IReturn<DiseaseAssessmentSingleResponse>
{
    /**
    * The disease assessment to save.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    /**
    * The Code of the Institution associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the disease assessment to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DiseaseAssessmentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiseaseAssessmentSingleResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/delete", "DELETE")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentDelete implements IReturn<DiseaseAssessmentDeleteResponse>
{
    /**
    * The disease assessment to delete.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    public constructor(init?: Partial<DiseaseAssessmentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DiseaseAssessmentDeleteResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentDeleteWithId implements IReturn<DiseaseAssessmentDeleteResponse>
{
    /**
    * The ID of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DiseaseAssessmentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DiseaseAssessmentDeleteResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/delete/ids", "DELETE")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentDeleteWithIds implements IReturn<DiseaseAssessmentDeleteResponse>
{
    /**
    * The Patient ID of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DiseaseAssessmentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DiseaseAssessmentDeleteResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/delete/codes", "DELETE")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentDeleteByCodes implements IReturn<DiseaseAssessmentDeleteResponse>
{
    /**
    * The Patient Study Number of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DiseaseAssessmentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DiseaseAssessmentDeleteResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/validate", "POST")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentPostValidate implements IReturn<DiseaseAssessmentValidationResponse>
{
    /**
    * The disease assessment to validate.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    public constructor(init?: Partial<DiseaseAssessmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiseaseAssessmentValidationResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/validate/ids", "POST")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentPostValidateWithIds implements IReturn<DiseaseAssessmentValidationResponse>
{
    /**
    * The disease assessment to validate.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    /**
    * The ID of the Institution associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DiseaseAssessmentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiseaseAssessmentValidationResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/validate/codes", "POST")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentPostValidateWithCodes implements IReturn<DiseaseAssessmentValidationResponse>
{
    /**
    * The disease assessment to validate.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    /**
    * The Code of the Institution associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DiseaseAssessmentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiseaseAssessmentValidationResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/single/id/{Id}", "GET")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationGetSingleById implements IReturn<EligibilityConfirmationSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EligibilityConfirmationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityConfirmationSingleResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/single/ids", "GET")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationGetSingleByIds implements IReturn<EligibilityConfirmationSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new eligibility confirmation if no eligibility confirmation is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<EligibilityConfirmationGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityConfirmationSingleResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/single/codes", "GET")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationGetSingleByCodes implements IReturn<EligibilityConfirmationSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new eligibility confirmation if no eligibility confirmation is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<EligibilityConfirmationGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityConfirmationSingleResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/collection", "GET")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationGetCollection implements IReturn<EligibilityConfirmationCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<EligibilityConfirmationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityConfirmationCollectionResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationGetCollectionByIds implements IReturn<EligibilityConfirmationCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityConfirmationGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityConfirmationCollectionResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationGetCollectionByCodes implements IReturn<EligibilityConfirmationCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityConfirmationGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityConfirmationCollectionResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/save", "POST")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationPostSave implements IReturn<EligibilityConfirmationSingleResponse>
{
    /**
    * The eligibility confirmation to save.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    public constructor(init?: Partial<EligibilityConfirmationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityConfirmationSingleResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/save/ids", "POST")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationPostSaveWithIds implements IReturn<EligibilityConfirmationSingleResponse>
{
    /**
    * The eligibility confirmation to save.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    /**
    * The ID of the Institution associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<EligibilityConfirmationPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityConfirmationSingleResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/save/codes", "POST")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationPostSaveWithCodes implements IReturn<EligibilityConfirmationSingleResponse>
{
    /**
    * The eligibility confirmation to save.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    /**
    * The Code of the Institution associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<EligibilityConfirmationPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityConfirmationSingleResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/delete", "DELETE")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationDelete implements IReturn<EligibilityConfirmationDeleteResponse>
{
    /**
    * The eligibility confirmation to delete.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    public constructor(init?: Partial<EligibilityConfirmationDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityConfirmationDeleteResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationDeleteWithId implements IReturn<EligibilityConfirmationDeleteResponse>
{
    /**
    * The ID of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EligibilityConfirmationDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityConfirmationDeleteResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/delete/ids", "DELETE")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationDeleteWithIds implements IReturn<EligibilityConfirmationDeleteResponse>
{
    /**
    * The Patient ID of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<EligibilityConfirmationDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityConfirmationDeleteResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/delete/codes", "DELETE")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationDeleteByCodes implements IReturn<EligibilityConfirmationDeleteResponse>
{
    /**
    * The Patient Study Number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<EligibilityConfirmationDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityConfirmationDeleteResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/validate", "POST")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationPostValidate implements IReturn<EligibilityConfirmationValidationResponse>
{
    /**
    * The eligibility confirmation to validate.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    public constructor(init?: Partial<EligibilityConfirmationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityConfirmationValidationResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/validate/ids", "POST")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationPostValidateWithIds implements IReturn<EligibilityConfirmationValidationResponse>
{
    /**
    * The eligibility confirmation to validate.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    /**
    * The ID of the Institution associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityConfirmationPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityConfirmationValidationResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/validate/codes", "POST")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationPostValidateWithCodes implements IReturn<EligibilityConfirmationValidationResponse>
{
    /**
    * The eligibility confirmation to validate.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    /**
    * The Code of the Institution associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityConfirmationPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityConfirmationValidationResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/single/id/{Id}", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralGetSingleById implements IReturn<GeneralSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the general to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<GeneralGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/single/ids", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralGetSingleByIds implements IReturn<GeneralSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the general to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the general to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new general if no general is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<GeneralGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/single/codes", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralGetSingleByCodes implements IReturn<GeneralSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the general to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the general to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new general if no general is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<GeneralGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/collection", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralGetCollection implements IReturn<GeneralCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<GeneralGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralCollectionResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralGetCollectionByIds implements IReturn<GeneralCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<GeneralGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralCollectionResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralGetCollectionByCodes implements IReturn<GeneralCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<GeneralGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralCollectionResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/save", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralPostSave implements IReturn<GeneralSingleResponse>
{
    /**
    * The general to save.
    */
    // @DataMember(Order=1)
    public form: General;

    public constructor(init?: Partial<GeneralPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/save/ids", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralPostSaveWithIds implements IReturn<GeneralSingleResponse>
{
    /**
    * The general to save.
    */
    // @DataMember(Order=1)
    public form: General;

    /**
    * The ID of the Institution associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the general to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<GeneralPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/save/codes", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralPostSaveWithCodes implements IReturn<GeneralSingleResponse>
{
    /**
    * The general to save.
    */
    // @DataMember(Order=1)
    public form: General;

    /**
    * The Code of the Institution associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the general to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the general to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<GeneralPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/delete", "DELETE")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=500)
// @DataContract
export class GeneralDelete implements IReturn<GeneralDeleteResponse>
{
    /**
    * The general to delete.
    */
    // @DataMember(Order=1)
    public form: General;

    public constructor(init?: Partial<GeneralDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new GeneralDeleteResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/delete/id/{Id}", "DELETE")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=500)
// @DataContract
export class GeneralDeleteWithId implements IReturn<GeneralDeleteResponse>
{
    /**
    * The ID of the general to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<GeneralDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new GeneralDeleteResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/delete/ids", "DELETE")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=500)
// @DataContract
export class GeneralDeleteWithIds implements IReturn<GeneralDeleteResponse>
{
    /**
    * The Patient ID of the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the general to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<GeneralDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new GeneralDeleteResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/delete/codes", "DELETE")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=500)
// @DataContract
export class GeneralDeleteByCodes implements IReturn<GeneralDeleteResponse>
{
    /**
    * The Patient Study Number of the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the general to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<GeneralDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new GeneralDeleteResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/validate", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralPostValidate implements IReturn<GeneralValidationResponse>
{
    /**
    * The general to validate.
    */
    // @DataMember(Order=1)
    public form: General;

    public constructor(init?: Partial<GeneralPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralValidationResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/validate/ids", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralPostValidateWithIds implements IReturn<GeneralValidationResponse>
{
    /**
    * The general to validate.
    */
    // @DataMember(Order=1)
    public form: General;

    /**
    * The ID of the Institution associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<GeneralPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralValidationResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/validate/codes", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralPostValidateWithCodes implements IReturn<GeneralValidationResponse>
{
    /**
    * The general to validate.
    */
    // @DataMember(Order=1)
    public form: General;

    /**
    * The Code of the Institution associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the general to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<GeneralPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralValidationResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/single/id/{Id}", "GET")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetSingleById implements IReturn<MedicalHistorySingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/single/ids", "GET")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetSingleByIds implements IReturn<MedicalHistorySingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the medical history to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new medical history if no medical history is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<MedicalHistoryGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/single/codes", "GET")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetSingleByCodes implements IReturn<MedicalHistorySingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the medical history to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new medical history if no medical history is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<MedicalHistoryGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/collection", "GET")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetCollection implements IReturn<MedicalHistoryCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<MedicalHistoryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryCollectionResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetCollectionByIds implements IReturn<MedicalHistoryCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryCollectionResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetCollectionByCodes implements IReturn<MedicalHistoryCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryCollectionResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/save", "POST")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostSave implements IReturn<MedicalHistorySingleResponse>
{
    /**
    * The medical history to save.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/save/ids", "POST")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostSaveWithIds implements IReturn<MedicalHistorySingleResponse>
{
    /**
    * The medical history to save.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    /**
    * The ID of the Institution associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the medical history to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<MedicalHistoryPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/save/codes", "POST")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostSaveWithCodes implements IReturn<MedicalHistorySingleResponse>
{
    /**
    * The medical history to save.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    /**
    * The Code of the Institution associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the medical history to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<MedicalHistoryPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/delete", "DELETE")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryDelete implements IReturn<MedicalHistoryDeleteResponse>
{
    /**
    * The medical history to delete.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalHistoryDeleteResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryDeleteWithId implements IReturn<MedicalHistoryDeleteResponse>
{
    /**
    * The ID of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalHistoryDeleteResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/delete/ids", "DELETE")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryDeleteWithIds implements IReturn<MedicalHistoryDeleteResponse>
{
    /**
    * The Patient ID of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalHistoryDeleteResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/delete/codes", "DELETE")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryDeleteByCodes implements IReturn<MedicalHistoryDeleteResponse>
{
    /**
    * The Patient Study Number of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalHistoryDeleteResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/validate", "POST")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostValidate implements IReturn<MedicalHistoryValidationResponse>
{
    /**
    * The medical history to validate.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryValidationResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/validate/ids", "POST")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostValidateWithIds implements IReturn<MedicalHistoryValidationResponse>
{
    /**
    * The medical history to validate.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    /**
    * The ID of the Institution associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryValidationResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/validate/codes", "POST")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostValidateWithCodes implements IReturn<MedicalHistoryValidationResponse>
{
    /**
    * The medical history to validate.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    /**
    * The Code of the Institution associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryValidationResponse(); }
}

/**
* Form - Medical History Form
*/
// @Route("/opms/form/medical-history-form/single/id/{Id}", "GET")
// @Api(Description="Form - Medical History Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryFormGetSingleById implements IReturn<MedicalHistoryFormSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the medical history form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryFormSingleResponse(); }
}

/**
* Form - Medical History Form
*/
// @Route("/opms/form/medical-history-form/single/ids", "GET")
// @Api(Description="Form - Medical History Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryFormGetSingleByIds implements IReturn<MedicalHistoryFormSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the medical history form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the medical history form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the medical history form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the medical history form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the medical history form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new medical history form if no medical history form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<MedicalHistoryFormGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryFormGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryFormSingleResponse(); }
}

/**
* Form - Medical History Form
*/
// @Route("/opms/form/medical-history-form/single/codes", "GET")
// @Api(Description="Form - Medical History Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryFormGetSingleByCodes implements IReturn<MedicalHistoryFormSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the medical history form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the medical history form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the medical history form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the medical history form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the medical history form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new medical history form if no medical history form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<MedicalHistoryFormGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryFormGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryFormSingleResponse(); }
}

/**
* Form - Medical History Form
*/
// @Route("/opms/form/medical-history-form/collection", "GET")
// @Api(Description="Form - Medical History Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryFormGetCollection implements IReturn<MedicalHistoryFormCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<MedicalHistoryFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryFormCollectionResponse(); }
}

/**
* Form - Medical History Form
*/
// @Route("/opms/form/medical-history-form/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Medical History Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryFormGetCollectionByIds implements IReturn<MedicalHistoryFormCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the medical history form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the medical history form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the medical history form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryFormGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryFormGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryFormCollectionResponse(); }
}

/**
* Form - Medical History Form
*/
// @Route("/opms/form/medical-history-form/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Medical History Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryFormGetCollectionByCodes implements IReturn<MedicalHistoryFormCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the medical history form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the medical history form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the medical history form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryFormGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryFormGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryFormCollectionResponse(); }
}

/**
* Form - Medical History Form
*/
// @Route("/opms/form/medical-history-form/save", "POST")
// @Api(Description="Form - Medical History Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryFormPostSave implements IReturn<MedicalHistoryFormSingleResponse>
{
    /**
    * The medical history form to save.
    */
    // @DataMember(Order=1)
    public form: MedicalHistoryForm;

    public constructor(init?: Partial<MedicalHistoryFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryFormSingleResponse(); }
}

/**
* Form - Medical History Form
*/
// @Route("/opms/form/medical-history-form/save/ids", "POST")
// @Api(Description="Form - Medical History Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryFormPostSaveWithIds implements IReturn<MedicalHistoryFormSingleResponse>
{
    /**
    * The medical history form to save.
    */
    // @DataMember(Order=1)
    public form: MedicalHistoryForm;

    /**
    * The ID of the Institution associated with the medical history form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the medical history form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the medical history form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the medical history form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the medical history form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<MedicalHistoryFormPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryFormPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryFormSingleResponse(); }
}

/**
* Form - Medical History Form
*/
// @Route("/opms/form/medical-history-form/save/codes", "POST")
// @Api(Description="Form - Medical History Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryFormPostSaveWithCodes implements IReturn<MedicalHistoryFormSingleResponse>
{
    /**
    * The medical history form to save.
    */
    // @DataMember(Order=1)
    public form: MedicalHistoryForm;

    /**
    * The Code of the Institution associated with the medical history form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the medical history form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the medical history form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the medical history form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the medical history form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<MedicalHistoryFormPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryFormPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryFormSingleResponse(); }
}

/**
* Form - Medical History Form
*/
// @Route("/opms/form/medical-history-form/delete", "DELETE")
// @Api(Description="Form - Medical History Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryFormDelete implements IReturn<MedicalHistoryFormDeleteResponse>
{
    /**
    * The medical history form to delete.
    */
    // @DataMember(Order=1)
    public form: MedicalHistoryForm;

    public constructor(init?: Partial<MedicalHistoryFormDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryFormDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalHistoryFormDeleteResponse(); }
}

/**
* Form - Medical History Form
*/
// @Route("/opms/form/medical-history-form/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Medical History Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryFormDeleteWithId implements IReturn<MedicalHistoryFormDeleteResponse>
{
    /**
    * The ID of the medical history form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryFormDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryFormDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalHistoryFormDeleteResponse(); }
}

/**
* Form - Medical History Form
*/
// @Route("/opms/form/medical-history-form/delete/ids", "DELETE")
// @Api(Description="Form - Medical History Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryFormDeleteWithIds implements IReturn<MedicalHistoryFormDeleteResponse>
{
    /**
    * The Patient ID of the medical history form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the medical history form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the medical history form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the medical history form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryFormDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryFormDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalHistoryFormDeleteResponse(); }
}

/**
* Form - Medical History Form
*/
// @Route("/opms/form/medical-history-form/delete/codes", "DELETE")
// @Api(Description="Form - Medical History Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryFormDeleteByCodes implements IReturn<MedicalHistoryFormDeleteResponse>
{
    /**
    * The Patient Study Number of the medical history form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the medical history form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the medical history form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the medical history form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryFormDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryFormDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalHistoryFormDeleteResponse(); }
}

/**
* Form - Medical History Form
*/
// @Route("/opms/form/medical-history-form/validate", "POST")
// @Api(Description="Form - Medical History Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryFormPostValidate implements IReturn<MedicalHistoryFormValidationResponse>
{
    /**
    * The medical history form to validate.
    */
    // @DataMember(Order=1)
    public form: MedicalHistoryForm;

    public constructor(init?: Partial<MedicalHistoryFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryFormValidationResponse(); }
}

/**
* Form - Medical History Form
*/
// @Route("/opms/form/medical-history-form/validate/ids", "POST")
// @Api(Description="Form - Medical History Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryFormPostValidateWithIds implements IReturn<MedicalHistoryFormValidationResponse>
{
    /**
    * The medical history form to validate.
    */
    // @DataMember(Order=1)
    public form: MedicalHistoryForm;

    /**
    * The ID of the Institution associated with the medical history form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the medical history form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the medical history form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the medical history form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryFormPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryFormPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryFormValidationResponse(); }
}

/**
* Form - Medical History Form
*/
// @Route("/opms/form/medical-history-form/validate/codes", "POST")
// @Api(Description="Form - Medical History Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryFormValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryFormPostValidateWithCodes implements IReturn<MedicalHistoryFormValidationResponse>
{
    /**
    * The medical history form to validate.
    */
    // @DataMember(Order=1)
    public form: MedicalHistoryForm;

    /**
    * The Code of the Institution associated with the medical history form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the medical history form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the medical history form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the medical history form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryFormPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryFormPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryFormValidationResponse(); }
}

/**
* Form - Previous Psa And Testosterone Result
*/
// @Route("/opms/form/previous-psa-and-testosterone-result/single/id/{Id}", "GET")
// @Api(Description="Form - Previous Psa And Testosterone Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=500)
// @DataContract
export class PreviousPsaAndTestosteroneResultGetSingleById implements IReturn<PreviousPsaAndTestosteroneResultSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResultGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousPsaAndTestosteroneResultGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreviousPsaAndTestosteroneResultSingleResponse(); }
}

/**
* Form - Previous Psa And Testosterone Result
*/
// @Route("/opms/form/previous-psa-and-testosterone-result/single/ids", "GET")
// @Api(Description="Form - Previous Psa And Testosterone Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=500)
// @DataContract
export class PreviousPsaAndTestosteroneResultGetSingleByIds implements IReturn<PreviousPsaAndTestosteroneResultSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new previous psa and testosterone result if no previous psa and testosterone result is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResultGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousPsaAndTestosteroneResultGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreviousPsaAndTestosteroneResultSingleResponse(); }
}

/**
* Form - Previous Psa And Testosterone Result
*/
// @Route("/opms/form/previous-psa-and-testosterone-result/single/codes", "GET")
// @Api(Description="Form - Previous Psa And Testosterone Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=500)
// @DataContract
export class PreviousPsaAndTestosteroneResultGetSingleByCodes implements IReturn<PreviousPsaAndTestosteroneResultSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new previous psa and testosterone result if no previous psa and testosterone result is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResultGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousPsaAndTestosteroneResultGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreviousPsaAndTestosteroneResultSingleResponse(); }
}

/**
* Form - Previous Psa And Testosterone Result
*/
// @Route("/opms/form/previous-psa-and-testosterone-result/collection", "GET")
// @Api(Description="Form - Previous Psa And Testosterone Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultCollectionResponse)", StatusCode=500)
// @DataContract
export class PreviousPsaAndTestosteroneResultGetCollection implements IReturn<PreviousPsaAndTestosteroneResultCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResultGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousPsaAndTestosteroneResultGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreviousPsaAndTestosteroneResultCollectionResponse(); }
}

/**
* Form - Previous Psa And Testosterone Result
*/
// @Route("/opms/form/previous-psa-and-testosterone-result/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Previous Psa And Testosterone Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultCollectionResponse)", StatusCode=500)
// @DataContract
export class PreviousPsaAndTestosteroneResultGetCollectionByIds implements IReturn<PreviousPsaAndTestosteroneResultCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResultGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousPsaAndTestosteroneResultGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreviousPsaAndTestosteroneResultCollectionResponse(); }
}

/**
* Form - Previous Psa And Testosterone Result
*/
// @Route("/opms/form/previous-psa-and-testosterone-result/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Previous Psa And Testosterone Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultCollectionResponse)", StatusCode=500)
// @DataContract
export class PreviousPsaAndTestosteroneResultGetCollectionByCodes implements IReturn<PreviousPsaAndTestosteroneResultCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResultGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousPsaAndTestosteroneResultGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreviousPsaAndTestosteroneResultCollectionResponse(); }
}

/**
* Form - Previous Psa And Testosterone Result
*/
// @Route("/opms/form/previous-psa-and-testosterone-result/save", "POST")
// @Api(Description="Form - Previous Psa And Testosterone Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=500)
// @DataContract
export class PreviousPsaAndTestosteroneResultPostSave implements IReturn<PreviousPsaAndTestosteroneResultSingleResponse>
{
    /**
    * The previous psa and testosterone result to save.
    */
    // @DataMember(Order=1)
    public form: PreviousPsaAndTestosteroneResult;

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResultPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousPsaAndTestosteroneResultPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreviousPsaAndTestosteroneResultSingleResponse(); }
}

/**
* Form - Previous Psa And Testosterone Result
*/
// @Route("/opms/form/previous-psa-and-testosterone-result/save/ids", "POST")
// @Api(Description="Form - Previous Psa And Testosterone Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=500)
// @DataContract
export class PreviousPsaAndTestosteroneResultPostSaveWithIds implements IReturn<PreviousPsaAndTestosteroneResultSingleResponse>
{
    /**
    * The previous psa and testosterone result to save.
    */
    // @DataMember(Order=1)
    public form: PreviousPsaAndTestosteroneResult;

    /**
    * The ID of the Institution associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResultPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousPsaAndTestosteroneResultPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreviousPsaAndTestosteroneResultSingleResponse(); }
}

/**
* Form - Previous Psa And Testosterone Result
*/
// @Route("/opms/form/previous-psa-and-testosterone-result/save/codes", "POST")
// @Api(Description="Form - Previous Psa And Testosterone Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultSingleResponse)", StatusCode=500)
// @DataContract
export class PreviousPsaAndTestosteroneResultPostSaveWithCodes implements IReturn<PreviousPsaAndTestosteroneResultSingleResponse>
{
    /**
    * The previous psa and testosterone result to save.
    */
    // @DataMember(Order=1)
    public form: PreviousPsaAndTestosteroneResult;

    /**
    * The Code of the Institution associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResultPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousPsaAndTestosteroneResultPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreviousPsaAndTestosteroneResultSingleResponse(); }
}

/**
* Form - Previous Psa And Testosterone Result
*/
// @Route("/opms/form/previous-psa-and-testosterone-result/delete", "DELETE")
// @Api(Description="Form - Previous Psa And Testosterone Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultDeleteResponse)", StatusCode=500)
// @DataContract
export class PreviousPsaAndTestosteroneResultDelete implements IReturn<PreviousPsaAndTestosteroneResultDeleteResponse>
{
    /**
    * The previous psa and testosterone result to delete.
    */
    // @DataMember(Order=1)
    public form: PreviousPsaAndTestosteroneResult;

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResultDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousPsaAndTestosteroneResultDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PreviousPsaAndTestosteroneResultDeleteResponse(); }
}

/**
* Form - Previous Psa And Testosterone Result
*/
// @Route("/opms/form/previous-psa-and-testosterone-result/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Previous Psa And Testosterone Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultDeleteResponse)", StatusCode=500)
// @DataContract
export class PreviousPsaAndTestosteroneResultDeleteWithId implements IReturn<PreviousPsaAndTestosteroneResultDeleteResponse>
{
    /**
    * The ID of the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResultDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousPsaAndTestosteroneResultDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PreviousPsaAndTestosteroneResultDeleteResponse(); }
}

/**
* Form - Previous Psa And Testosterone Result
*/
// @Route("/opms/form/previous-psa-and-testosterone-result/delete/ids", "DELETE")
// @Api(Description="Form - Previous Psa And Testosterone Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultDeleteResponse)", StatusCode=500)
// @DataContract
export class PreviousPsaAndTestosteroneResultDeleteWithIds implements IReturn<PreviousPsaAndTestosteroneResultDeleteResponse>
{
    /**
    * The Patient ID of the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResultDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousPsaAndTestosteroneResultDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PreviousPsaAndTestosteroneResultDeleteResponse(); }
}

/**
* Form - Previous Psa And Testosterone Result
*/
// @Route("/opms/form/previous-psa-and-testosterone-result/delete/codes", "DELETE")
// @Api(Description="Form - Previous Psa And Testosterone Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultDeleteResponse)", StatusCode=500)
// @DataContract
export class PreviousPsaAndTestosteroneResultDeleteByCodes implements IReturn<PreviousPsaAndTestosteroneResultDeleteResponse>
{
    /**
    * The Patient Study Number of the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResultDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousPsaAndTestosteroneResultDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PreviousPsaAndTestosteroneResultDeleteResponse(); }
}

/**
* Form - Previous Psa And Testosterone Result
*/
// @Route("/opms/form/previous-psa-and-testosterone-result/validate", "POST")
// @Api(Description="Form - Previous Psa And Testosterone Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultValidationResponse)", StatusCode=500)
// @DataContract
export class PreviousPsaAndTestosteroneResultPostValidate implements IReturn<PreviousPsaAndTestosteroneResultValidationResponse>
{
    /**
    * The previous psa and testosterone result to validate.
    */
    // @DataMember(Order=1)
    public form: PreviousPsaAndTestosteroneResult;

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResultPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousPsaAndTestosteroneResultPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreviousPsaAndTestosteroneResultValidationResponse(); }
}

/**
* Form - Previous Psa And Testosterone Result
*/
// @Route("/opms/form/previous-psa-and-testosterone-result/validate/ids", "POST")
// @Api(Description="Form - Previous Psa And Testosterone Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultValidationResponse)", StatusCode=500)
// @DataContract
export class PreviousPsaAndTestosteroneResultPostValidateWithIds implements IReturn<PreviousPsaAndTestosteroneResultValidationResponse>
{
    /**
    * The previous psa and testosterone result to validate.
    */
    // @DataMember(Order=1)
    public form: PreviousPsaAndTestosteroneResult;

    /**
    * The ID of the Institution associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResultPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousPsaAndTestosteroneResultPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreviousPsaAndTestosteroneResultValidationResponse(); }
}

/**
* Form - Previous Psa And Testosterone Result
*/
// @Route("/opms/form/previous-psa-and-testosterone-result/validate/codes", "POST")
// @Api(Description="Form - Previous Psa And Testosterone Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousPsaAndTestosteroneResultValidationResponse)", StatusCode=500)
// @DataContract
export class PreviousPsaAndTestosteroneResultPostValidateWithCodes implements IReturn<PreviousPsaAndTestosteroneResultValidationResponse>
{
    /**
    * The previous psa and testosterone result to validate.
    */
    // @DataMember(Order=1)
    public form: PreviousPsaAndTestosteroneResult;

    /**
    * The Code of the Institution associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the previous psa and testosterone result to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PreviousPsaAndTestosteroneResultPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousPsaAndTestosteroneResultPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreviousPsaAndTestosteroneResultValidationResponse(); }
}

/**
* Form - Prostate Cancer Details
*/
// @Route("/opms/form/prostate-cancer-details/single/id/{Id}", "GET")
// @Api(Description="Form - Prostate Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class ProstateCancerDetailsGetSingleById implements IReturn<ProstateCancerDetailsSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the prostate cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProstateCancerDetailsGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProstateCancerDetailsGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProstateCancerDetailsSingleResponse(); }
}

/**
* Form - Prostate Cancer Details
*/
// @Route("/opms/form/prostate-cancer-details/single/ids", "GET")
// @Api(Description="Form - Prostate Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class ProstateCancerDetailsGetSingleByIds implements IReturn<ProstateCancerDetailsSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the prostate cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the prostate cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the prostate cancer details to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new prostate cancer details if no prostate cancer details is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ProstateCancerDetailsGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProstateCancerDetailsGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProstateCancerDetailsSingleResponse(); }
}

/**
* Form - Prostate Cancer Details
*/
// @Route("/opms/form/prostate-cancer-details/single/codes", "GET")
// @Api(Description="Form - Prostate Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class ProstateCancerDetailsGetSingleByCodes implements IReturn<ProstateCancerDetailsSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the prostate cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the prostate cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the prostate cancer details to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new prostate cancer details if no prostate cancer details is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ProstateCancerDetailsGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProstateCancerDetailsGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProstateCancerDetailsSingleResponse(); }
}

/**
* Form - Prostate Cancer Details
*/
// @Route("/opms/form/prostate-cancer-details/collection", "GET")
// @Api(Description="Form - Prostate Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsCollectionResponse)", StatusCode=500)
// @DataContract
export class ProstateCancerDetailsGetCollection implements IReturn<ProstateCancerDetailsCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ProstateCancerDetailsGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProstateCancerDetailsGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProstateCancerDetailsCollectionResponse(); }
}

/**
* Form - Prostate Cancer Details
*/
// @Route("/opms/form/prostate-cancer-details/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Prostate Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsCollectionResponse)", StatusCode=500)
// @DataContract
export class ProstateCancerDetailsGetCollectionByIds implements IReturn<ProstateCancerDetailsCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the prostate cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProstateCancerDetailsGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProstateCancerDetailsGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProstateCancerDetailsCollectionResponse(); }
}

/**
* Form - Prostate Cancer Details
*/
// @Route("/opms/form/prostate-cancer-details/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Prostate Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsCollectionResponse)", StatusCode=500)
// @DataContract
export class ProstateCancerDetailsGetCollectionByCodes implements IReturn<ProstateCancerDetailsCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProstateCancerDetailsGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProstateCancerDetailsGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProstateCancerDetailsCollectionResponse(); }
}

/**
* Form - Prostate Cancer Details
*/
// @Route("/opms/form/prostate-cancer-details/save", "POST")
// @Api(Description="Form - Prostate Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class ProstateCancerDetailsPostSave implements IReturn<ProstateCancerDetailsSingleResponse>
{
    /**
    * The prostate cancer details to save.
    */
    // @DataMember(Order=1)
    public form: ProstateCancerDetails;

    public constructor(init?: Partial<ProstateCancerDetailsPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProstateCancerDetailsPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProstateCancerDetailsSingleResponse(); }
}

/**
* Form - Prostate Cancer Details
*/
// @Route("/opms/form/prostate-cancer-details/save/ids", "POST")
// @Api(Description="Form - Prostate Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class ProstateCancerDetailsPostSaveWithIds implements IReturn<ProstateCancerDetailsSingleResponse>
{
    /**
    * The prostate cancer details to save.
    */
    // @DataMember(Order=1)
    public form: ProstateCancerDetails;

    /**
    * The ID of the Institution associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the prostate cancer details to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ProstateCancerDetailsPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProstateCancerDetailsPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProstateCancerDetailsSingleResponse(); }
}

/**
* Form - Prostate Cancer Details
*/
// @Route("/opms/form/prostate-cancer-details/save/codes", "POST")
// @Api(Description="Form - Prostate Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class ProstateCancerDetailsPostSaveWithCodes implements IReturn<ProstateCancerDetailsSingleResponse>
{
    /**
    * The prostate cancer details to save.
    */
    // @DataMember(Order=1)
    public form: ProstateCancerDetails;

    /**
    * The Code of the Institution associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the prostate cancer details to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ProstateCancerDetailsPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProstateCancerDetailsPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProstateCancerDetailsSingleResponse(); }
}

/**
* Form - Prostate Cancer Details
*/
// @Route("/opms/form/prostate-cancer-details/delete", "DELETE")
// @Api(Description="Form - Prostate Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsDeleteResponse)", StatusCode=500)
// @DataContract
export class ProstateCancerDetailsDelete implements IReturn<ProstateCancerDetailsDeleteResponse>
{
    /**
    * The prostate cancer details to delete.
    */
    // @DataMember(Order=1)
    public form: ProstateCancerDetails;

    public constructor(init?: Partial<ProstateCancerDetailsDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProstateCancerDetailsDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProstateCancerDetailsDeleteResponse(); }
}

/**
* Form - Prostate Cancer Details
*/
// @Route("/opms/form/prostate-cancer-details/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Prostate Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsDeleteResponse)", StatusCode=500)
// @DataContract
export class ProstateCancerDetailsDeleteWithId implements IReturn<ProstateCancerDetailsDeleteResponse>
{
    /**
    * The ID of the prostate cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProstateCancerDetailsDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProstateCancerDetailsDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProstateCancerDetailsDeleteResponse(); }
}

/**
* Form - Prostate Cancer Details
*/
// @Route("/opms/form/prostate-cancer-details/delete/ids", "DELETE")
// @Api(Description="Form - Prostate Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsDeleteResponse)", StatusCode=500)
// @DataContract
export class ProstateCancerDetailsDeleteWithIds implements IReturn<ProstateCancerDetailsDeleteResponse>
{
    /**
    * The Patient ID of the prostate cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the prostate cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ProstateCancerDetailsDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProstateCancerDetailsDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProstateCancerDetailsDeleteResponse(); }
}

/**
* Form - Prostate Cancer Details
*/
// @Route("/opms/form/prostate-cancer-details/delete/codes", "DELETE")
// @Api(Description="Form - Prostate Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsDeleteResponse)", StatusCode=500)
// @DataContract
export class ProstateCancerDetailsDeleteByCodes implements IReturn<ProstateCancerDetailsDeleteResponse>
{
    /**
    * The Patient Study Number of the prostate cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the prostate cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ProstateCancerDetailsDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProstateCancerDetailsDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProstateCancerDetailsDeleteResponse(); }
}

/**
* Form - Prostate Cancer Details
*/
// @Route("/opms/form/prostate-cancer-details/validate", "POST")
// @Api(Description="Form - Prostate Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsValidationResponse)", StatusCode=500)
// @DataContract
export class ProstateCancerDetailsPostValidate implements IReturn<ProstateCancerDetailsValidationResponse>
{
    /**
    * The prostate cancer details to validate.
    */
    // @DataMember(Order=1)
    public form: ProstateCancerDetails;

    public constructor(init?: Partial<ProstateCancerDetailsPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProstateCancerDetailsPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProstateCancerDetailsValidationResponse(); }
}

/**
* Form - Prostate Cancer Details
*/
// @Route("/opms/form/prostate-cancer-details/validate/ids", "POST")
// @Api(Description="Form - Prostate Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsValidationResponse)", StatusCode=500)
// @DataContract
export class ProstateCancerDetailsPostValidateWithIds implements IReturn<ProstateCancerDetailsValidationResponse>
{
    /**
    * The prostate cancer details to validate.
    */
    // @DataMember(Order=1)
    public form: ProstateCancerDetails;

    /**
    * The ID of the Institution associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProstateCancerDetailsPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProstateCancerDetailsPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProstateCancerDetailsValidationResponse(); }
}

/**
* Form - Prostate Cancer Details
*/
// @Route("/opms/form/prostate-cancer-details/validate/codes", "POST")
// @Api(Description="Form - Prostate Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProstateCancerDetailsValidationResponse)", StatusCode=500)
// @DataContract
export class ProstateCancerDetailsPostValidateWithCodes implements IReturn<ProstateCancerDetailsValidationResponse>
{
    /**
    * The prostate cancer details to validate.
    */
    // @DataMember(Order=1)
    public form: ProstateCancerDetails;

    /**
    * The Code of the Institution associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the prostate cancer details to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProstateCancerDetailsPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProstateCancerDetailsPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProstateCancerDetailsValidationResponse(); }
}

/**
* Form - Psma Pet
*/
// @Route("/opms/form/psma-pet/single/id/{Id}", "GET")
// @Api(Description="Form - Psma Pet")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=500)
// @DataContract
export class PsmaPetGetSingleById implements IReturn<PsmaPetSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the psma pet to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PsmaPetGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PsmaPetGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PsmaPetSingleResponse(); }
}

/**
* Form - Psma Pet
*/
// @Route("/opms/form/psma-pet/single/ids", "GET")
// @Api(Description="Form - Psma Pet")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=500)
// @DataContract
export class PsmaPetGetSingleByIds implements IReturn<PsmaPetSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the psma pet to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the psma pet to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the psma pet to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the psma pet to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the psma pet to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new psma pet if no psma pet is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PsmaPetGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PsmaPetGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PsmaPetSingleResponse(); }
}

/**
* Form - Psma Pet
*/
// @Route("/opms/form/psma-pet/single/codes", "GET")
// @Api(Description="Form - Psma Pet")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=500)
// @DataContract
export class PsmaPetGetSingleByCodes implements IReturn<PsmaPetSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the psma pet to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the psma pet to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the psma pet to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the psma pet to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the psma pet to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new psma pet if no psma pet is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PsmaPetGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PsmaPetGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PsmaPetSingleResponse(); }
}

/**
* Form - Psma Pet
*/
// @Route("/opms/form/psma-pet/collection", "GET")
// @Api(Description="Form - Psma Pet")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PsmaPetCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PsmaPetCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PsmaPetCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PsmaPetCollectionResponse)", StatusCode=500)
// @DataContract
export class PsmaPetGetCollection implements IReturn<PsmaPetCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<PsmaPetGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PsmaPetGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PsmaPetCollectionResponse(); }
}

/**
* Form - Psma Pet
*/
// @Route("/opms/form/psma-pet/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Psma Pet")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PsmaPetCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PsmaPetCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PsmaPetCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PsmaPetCollectionResponse)", StatusCode=500)
// @DataContract
export class PsmaPetGetCollectionByIds implements IReturn<PsmaPetCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the psma pet to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the psma pet to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the psma pet to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PsmaPetGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PsmaPetGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PsmaPetCollectionResponse(); }
}

/**
* Form - Psma Pet
*/
// @Route("/opms/form/psma-pet/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Psma Pet")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PsmaPetCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PsmaPetCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PsmaPetCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PsmaPetCollectionResponse)", StatusCode=500)
// @DataContract
export class PsmaPetGetCollectionByCodes implements IReturn<PsmaPetCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the psma pet to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the psma pet to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the psma pet to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PsmaPetGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PsmaPetGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PsmaPetCollectionResponse(); }
}

/**
* Form - Psma Pet
*/
// @Route("/opms/form/psma-pet/save", "POST")
// @Api(Description="Form - Psma Pet")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=500)
// @DataContract
export class PsmaPetPostSave implements IReturn<PsmaPetSingleResponse>
{
    /**
    * The psma pet to save.
    */
    // @DataMember(Order=1)
    public form: PsmaPet;

    public constructor(init?: Partial<PsmaPetPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PsmaPetPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PsmaPetSingleResponse(); }
}

/**
* Form - Psma Pet
*/
// @Route("/opms/form/psma-pet/save/ids", "POST")
// @Api(Description="Form - Psma Pet")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=500)
// @DataContract
export class PsmaPetPostSaveWithIds implements IReturn<PsmaPetSingleResponse>
{
    /**
    * The psma pet to save.
    */
    // @DataMember(Order=1)
    public form: PsmaPet;

    /**
    * The ID of the Institution associated with the psma pet to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the psma pet to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the psma pet to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the psma pet to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the psma pet to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PsmaPetPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PsmaPetPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PsmaPetSingleResponse(); }
}

/**
* Form - Psma Pet
*/
// @Route("/opms/form/psma-pet/save/codes", "POST")
// @Api(Description="Form - Psma Pet")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PsmaPetSingleResponse)", StatusCode=500)
// @DataContract
export class PsmaPetPostSaveWithCodes implements IReturn<PsmaPetSingleResponse>
{
    /**
    * The psma pet to save.
    */
    // @DataMember(Order=1)
    public form: PsmaPet;

    /**
    * The Code of the Institution associated with the psma pet to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the psma pet to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the psma pet to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the psma pet to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the psma pet to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PsmaPetPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PsmaPetPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PsmaPetSingleResponse(); }
}

/**
* Form - Psma Pet
*/
// @Route("/opms/form/psma-pet/delete", "DELETE")
// @Api(Description="Form - Psma Pet")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PsmaPetDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PsmaPetDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PsmaPetDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PsmaPetDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PsmaPetDeleteResponse)", StatusCode=500)
// @DataContract
export class PsmaPetDelete implements IReturn<PsmaPetDeleteResponse>
{
    /**
    * The psma pet to delete.
    */
    // @DataMember(Order=1)
    public form: PsmaPet;

    public constructor(init?: Partial<PsmaPetDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PsmaPetDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PsmaPetDeleteResponse(); }
}

/**
* Form - Psma Pet
*/
// @Route("/opms/form/psma-pet/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Psma Pet")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PsmaPetDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PsmaPetDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PsmaPetDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PsmaPetDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PsmaPetDeleteResponse)", StatusCode=500)
// @DataContract
export class PsmaPetDeleteWithId implements IReturn<PsmaPetDeleteResponse>
{
    /**
    * The ID of the psma pet to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PsmaPetDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PsmaPetDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PsmaPetDeleteResponse(); }
}

/**
* Form - Psma Pet
*/
// @Route("/opms/form/psma-pet/delete/ids", "DELETE")
// @Api(Description="Form - Psma Pet")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PsmaPetDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PsmaPetDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PsmaPetDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PsmaPetDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PsmaPetDeleteResponse)", StatusCode=500)
// @DataContract
export class PsmaPetDeleteWithIds implements IReturn<PsmaPetDeleteResponse>
{
    /**
    * The Patient ID of the psma pet to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the psma pet to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the psma pet to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the psma pet to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PsmaPetDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PsmaPetDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PsmaPetDeleteResponse(); }
}

/**
* Form - Psma Pet
*/
// @Route("/opms/form/psma-pet/delete/codes", "DELETE")
// @Api(Description="Form - Psma Pet")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PsmaPetDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PsmaPetDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PsmaPetDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PsmaPetDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PsmaPetDeleteResponse)", StatusCode=500)
// @DataContract
export class PsmaPetDeleteByCodes implements IReturn<PsmaPetDeleteResponse>
{
    /**
    * The Patient Study Number of the psma pet to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the psma pet to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the psma pet to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the psma pet to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PsmaPetDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PsmaPetDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PsmaPetDeleteResponse(); }
}

/**
* Form - Psma Pet
*/
// @Route("/opms/form/psma-pet/validate", "POST")
// @Api(Description="Form - Psma Pet")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PsmaPetValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PsmaPetValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PsmaPetValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PsmaPetValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PsmaPetValidationResponse)", StatusCode=500)
// @DataContract
export class PsmaPetPostValidate implements IReturn<PsmaPetValidationResponse>
{
    /**
    * The psma pet to validate.
    */
    // @DataMember(Order=1)
    public form: PsmaPet;

    public constructor(init?: Partial<PsmaPetPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PsmaPetPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PsmaPetValidationResponse(); }
}

/**
* Form - Psma Pet
*/
// @Route("/opms/form/psma-pet/validate/ids", "POST")
// @Api(Description="Form - Psma Pet")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PsmaPetValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PsmaPetValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PsmaPetValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PsmaPetValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PsmaPetValidationResponse)", StatusCode=500)
// @DataContract
export class PsmaPetPostValidateWithIds implements IReturn<PsmaPetValidationResponse>
{
    /**
    * The psma pet to validate.
    */
    // @DataMember(Order=1)
    public form: PsmaPet;

    /**
    * The ID of the Institution associated with the psma pet to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the psma pet to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the psma pet to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the psma pet to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PsmaPetPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PsmaPetPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PsmaPetValidationResponse(); }
}

/**
* Form - Psma Pet
*/
// @Route("/opms/form/psma-pet/validate/codes", "POST")
// @Api(Description="Form - Psma Pet")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PsmaPetValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PsmaPetValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PsmaPetValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PsmaPetValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PsmaPetValidationResponse)", StatusCode=500)
// @DataContract
export class PsmaPetPostValidateWithCodes implements IReturn<PsmaPetValidationResponse>
{
    /**
    * The psma pet to validate.
    */
    // @DataMember(Order=1)
    public form: PsmaPet;

    /**
    * The Code of the Institution associated with the psma pet to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the psma pet to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the psma pet to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the psma pet to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PsmaPetPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PsmaPetPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PsmaPetValidationResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/single/id/{Id}", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleById implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/single/ids", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleByIds implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the source document to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new source document if no source document is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<SourceDocumentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/single/codes", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleByCodes implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the source document to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new source document if no source document is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<SourceDocumentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/collection", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollection implements IReturn<SourceDocumentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<SourceDocumentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollectionByIds implements IReturn<SourceDocumentCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollectionByCodes implements IReturn<SourceDocumentCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/save", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSave implements IReturn<SourceDocumentSingleResponse>
{
    /**
    * The source document to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/save/ids", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSaveWithIds implements IReturn<SourceDocumentSingleResponse>
{
    /**
    * The source document to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    /**
    * The ID of the Institution associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<SourceDocumentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/save/codes", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSaveWithCodes implements IReturn<SourceDocumentSingleResponse>
{
    /**
    * The source document to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    /**
    * The Code of the Institution associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the source document to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<SourceDocumentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/delete", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDelete implements IReturn<SourceDocumentDeleteResponse>
{
    /**
    * The source document to delete.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDeleteWithId implements IReturn<SourceDocumentDeleteResponse>
{
    /**
    * The ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/delete/ids", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDeleteWithIds implements IReturn<SourceDocumentDeleteResponse>
{
    /**
    * The Patient ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<SourceDocumentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/delete/codes", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDeleteByCodes implements IReturn<SourceDocumentDeleteResponse>
{
    /**
    * The Patient Study Number of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<SourceDocumentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/validate", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidate implements IReturn<SourceDocumentValidationResponse>
{
    /**
    * The source document to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/validate/ids", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidateWithIds implements IReturn<SourceDocumentValidationResponse>
{
    /**
    * The source document to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    /**
    * The ID of the Institution associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/validate/codes", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidateWithCodes implements IReturn<SourceDocumentValidationResponse>
{
    /**
    * The source document to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    /**
    * The Code of the Institution associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the source document to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

/**
* Form - Source Documents
*/
// @Route("/opms/form/source-documents/single/id/{Id}", "GET")
// @Api(Description="Form - Source Documents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentsGetSingleById implements IReturn<SourceDocumentsSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the source documents to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentsGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentsGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentsSingleResponse(); }
}

/**
* Form - Source Documents
*/
// @Route("/opms/form/source-documents/single/ids", "GET")
// @Api(Description="Form - Source Documents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentsGetSingleByIds implements IReturn<SourceDocumentsSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the source documents to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the source documents to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the source documents to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source documents to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the source documents to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new source documents if no source documents is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<SourceDocumentsGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentsGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentsSingleResponse(); }
}

/**
* Form - Source Documents
*/
// @Route("/opms/form/source-documents/single/codes", "GET")
// @Api(Description="Form - Source Documents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentsGetSingleByCodes implements IReturn<SourceDocumentsSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the source documents to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the source documents to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source documents to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source documents to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the source documents to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new source documents if no source documents is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<SourceDocumentsGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentsGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentsSingleResponse(); }
}

/**
* Form - Source Documents
*/
// @Route("/opms/form/source-documents/collection", "GET")
// @Api(Description="Form - Source Documents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentsCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentsGetCollection implements IReturn<SourceDocumentsCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<SourceDocumentsGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentsGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentsCollectionResponse(); }
}

/**
* Form - Source Documents
*/
// @Route("/opms/form/source-documents/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Source Documents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentsCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentsGetCollectionByIds implements IReturn<SourceDocumentsCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the source documents to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the source documents to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the source documents to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentsGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentsGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentsCollectionResponse(); }
}

/**
* Form - Source Documents
*/
// @Route("/opms/form/source-documents/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Source Documents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentsCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentsGetCollectionByCodes implements IReturn<SourceDocumentsCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the source documents to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the source documents to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the source documents to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentsGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentsGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentsCollectionResponse(); }
}

/**
* Form - Source Documents
*/
// @Route("/opms/form/source-documents/save", "POST")
// @Api(Description="Form - Source Documents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentsPostSave implements IReturn<SourceDocumentsSingleResponse>
{
    /**
    * The source documents to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocuments;

    public constructor(init?: Partial<SourceDocumentsPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentsPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentsSingleResponse(); }
}

/**
* Form - Source Documents
*/
// @Route("/opms/form/source-documents/save/ids", "POST")
// @Api(Description="Form - Source Documents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentsPostSaveWithIds implements IReturn<SourceDocumentsSingleResponse>
{
    /**
    * The source documents to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocuments;

    /**
    * The ID of the Institution associated with the source documents to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the source documents to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the source documents to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the source documents to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the source documents to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<SourceDocumentsPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentsPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentsSingleResponse(); }
}

/**
* Form - Source Documents
*/
// @Route("/opms/form/source-documents/save/codes", "POST")
// @Api(Description="Form - Source Documents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentsSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentsPostSaveWithCodes implements IReturn<SourceDocumentsSingleResponse>
{
    /**
    * The source documents to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocuments;

    /**
    * The Code of the Institution associated with the source documents to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the source documents to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the source documents to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source documents to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the source documents to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<SourceDocumentsPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentsPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentsSingleResponse(); }
}

/**
* Form - Source Documents
*/
// @Route("/opms/form/source-documents/delete", "DELETE")
// @Api(Description="Form - Source Documents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentsDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentsDelete implements IReturn<SourceDocumentsDeleteResponse>
{
    /**
    * The source documents to delete.
    */
    // @DataMember(Order=1)
    public form: SourceDocuments;

    public constructor(init?: Partial<SourceDocumentsDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentsDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentsDeleteResponse(); }
}

/**
* Form - Source Documents
*/
// @Route("/opms/form/source-documents/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Source Documents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentsDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentsDeleteWithId implements IReturn<SourceDocumentsDeleteResponse>
{
    /**
    * The ID of the source documents to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentsDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentsDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentsDeleteResponse(); }
}

/**
* Form - Source Documents
*/
// @Route("/opms/form/source-documents/delete/ids", "DELETE")
// @Api(Description="Form - Source Documents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentsDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentsDeleteWithIds implements IReturn<SourceDocumentsDeleteResponse>
{
    /**
    * The Patient ID of the source documents to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the source documents to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the source documents to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source documents to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<SourceDocumentsDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentsDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentsDeleteResponse(); }
}

/**
* Form - Source Documents
*/
// @Route("/opms/form/source-documents/delete/codes", "DELETE")
// @Api(Description="Form - Source Documents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentsDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentsDeleteByCodes implements IReturn<SourceDocumentsDeleteResponse>
{
    /**
    * The Patient Study Number of the source documents to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the source documents to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source documents to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source documents to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<SourceDocumentsDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentsDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentsDeleteResponse(); }
}

/**
* Form - Source Documents
*/
// @Route("/opms/form/source-documents/validate", "POST")
// @Api(Description="Form - Source Documents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentsValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentsPostValidate implements IReturn<SourceDocumentsValidationResponse>
{
    /**
    * The source documents to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocuments;

    public constructor(init?: Partial<SourceDocumentsPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentsPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentsValidationResponse(); }
}

/**
* Form - Source Documents
*/
// @Route("/opms/form/source-documents/validate/ids", "POST")
// @Api(Description="Form - Source Documents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentsValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentsPostValidateWithIds implements IReturn<SourceDocumentsValidationResponse>
{
    /**
    * The source documents to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocuments;

    /**
    * The ID of the Institution associated with the source documents to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the source documents to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the source documents to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the source documents to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentsPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentsPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentsValidationResponse(); }
}

/**
* Form - Source Documents
*/
// @Route("/opms/form/source-documents/validate/codes", "POST")
// @Api(Description="Form - Source Documents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentsValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentsPostValidateWithCodes implements IReturn<SourceDocumentsValidationResponse>
{
    /**
    * The source documents to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocuments;

    /**
    * The Code of the Institution associated with the source documents to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the source documents to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the source documents to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source documents to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentsPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentsPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentsValidationResponse(); }
}

// @Route("/opms/file/view/{Id}", "GET")
export class GetFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<GetFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file/download/{Id}", "GET")
export class DownloadFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<DownloadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file/download-all/{PatientId}/{FormDefinitionId}/{FormId}", "GET")
export class DownloadAllFiles implements IReturn<Blob>, IGet
{
    public patientId?: number;
    public formDefinitionId?: number;
    public formId?: number;

    public constructor(init?: Partial<DownloadAllFiles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadAllFiles'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

export class GetInstitutionPersonnelNames implements IReturn<InstitutionPersonnelNamesResponse>, IGet
{
    public institutionCode: string;
    public roles: number[];

    public constructor(init?: Partial<GetInstitutionPersonnelNames>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInstitutionPersonnelNames'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionPersonnelNamesResponse(); }
}

export class GetUserNames implements IReturn<GetUserNamesResponse>, IGet
{
    public instId?: number;
    public roles: number[];

    public constructor(init?: Partial<GetUserNames>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUserNames'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetUserNamesResponse(); }
}

export class PostHasOpmsPermissionsByIds implements IReturn<HasOpmsPermissionCollectionResponse>
{
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public institutionId?: number;
    public patientId?: number;
    public permissions: Permission[];

    public constructor(init?: Partial<PostHasOpmsPermissionsByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasOpmsPermissionsByIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HasOpmsPermissionCollectionResponse(); }
}

export class PostHasOpmsPermissionsByCodes implements IReturn<HasOpmsPermissionCollectionResponse>
{
    public masterGroupCode: string;
    public coordinatingGroupCode: string;
    public institutionCode: string;
    public patientStudyNumber: string;
    public permissions: Permission[];

    public constructor(init?: Partial<PostHasOpmsPermissionsByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasOpmsPermissionsByCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HasOpmsPermissionCollectionResponse(); }
}

