/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    ToggleButtonGroup,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    KeyboardDatePicker,
    Numeric
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-trogapi';

import { RequestState } from '@ngt/request-utilities';

import { makeStyles, InputAdornment } from '@material-ui/core';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import { NumberFormatValues } from 'react-number-format';
import { THREE_DIGIT_POSITIVE_INTEGER } from '../../validators/numeric';


//import SourceDocuments from '../../components/SourceDocuments';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IDiseaseAssessmentProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 1, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const anyExistingPathologicAdenopathyYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.Imaging>) => GetFieldLookupItem(lookups, 'anyExistingPathologicAdenopathy', formState?.values.anyExistingPathologicAdenopathy)?.type === Dtos.LookupYesNoType.Yes;

export const THREE_DIGIT_INTEGER_TWO_DECIMAL_PLACES_POSITIVE = {
    decimalScale: 2,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 1000) {
            return true;
        }

        return false;
    }
}

export const TWO_DIGIT_INTEGER_TWO_DECIMAL_PLACES_POSITIVE = {
    decimalScale: 2,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 100) {
            return true;
        }

        return false;
    }
}

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const DiseaseAssessment: React.FunctionComponent<IDiseaseAssessmentProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const { form } = React.useContext(FormContext)

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.DiseaseAssessment}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <FieldGroup>
                    <Field
                        name="dateOfCtScan"
                        component={KeyboardDatePicker}
                    />
                    <Field
                        name="isPrimaryCancerDetectable"
                        component={ToggleButtonGroup}
                    />
                    <Field
                        name="anyExistingPathologicAdenopathy"
                        component={ToggleButtonGroup}
                    />
                    <CrfCondition
                        type={Dtos.ProstateCancerDetails}
                        condition={anyExistingPathologicAdenopathyYes}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="measurementOfShortAxis"
                            component={Numeric}
                            numberFormat={THREE_DIGIT_POSITIVE_INTEGER}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        mm&nbsp;
                                    </InputAdornment>
                                )
                            }}
                        />
                    </CrfCondition>
                    <Field
                        name="anyDetectableMetastasesOnCtScan"
                        component={ToggleButtonGroup}
                    />
                </FieldGroup>
                <FieldGroup>
                    <Field
                        name="dateOfWbbsScan"
                        component={KeyboardDatePicker}
                    />
                    <Field
                        name="anyDetectableMetastasesOnWbbsScan"
                        component={ToggleButtonGroup}
                    />
                </FieldGroup>
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default DiseaseAssessment;
