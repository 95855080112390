/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae landing page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Button, makeStyles, Theme, Typography, Chip } from '@material-ui/core';

import { useParams } from 'react-router-dom';

import {
    PatientSummaryList,
    PatientContext,
    PatientValidationContext,
    PatientBreadcrumbs,
    PatientInformation,
    PatientInformationFn,
    ProgressButton,
    InstitutionContext,
    MasterGroupContext,
    CollaboratingGroupContext,
    RouteLoading,
    ValidationResultType,
    useSnackbar,

    InstitutionsContext,
    InstitutionBreadcrumbs,
    IFormLabel,

    OnlinePatientManagementContext
} from '@ngt/opms';

import {
    ScreeningSummaryForm,
    ScreeningSummaryTable
} from '@ngt/opms-screeninglog';

import AlertTitle from '@material-ui/lab/AlertTitle';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import useScreeningSummaryColumns from '../../hooks/screeninglog/useScreeningSummaryColumns';
import * as Dtos from '../../api/dtos';
import { ScreeningSummary } from '@ngt/opms-screeninglog/src/api/screeningSummary';
import ScreeningSummaryFieldGroup from './ScreeningSummaryFieldGroup';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IScreeningLogPageParams {
    institutionCode?: string;
}

interface IScreeningLogPageProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    ml1: {
        marginLeft: theme.spacing(1),
    },
    mt1: {
        marginTop: theme.spacing(1),
    },
    mt3: {
        marginTop: theme.spacing(3)
    },
    mr1: {
        marginRight: theme.spacing(1)
    },
    createGrid: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-Start',
        alignItems: 'flex-end',
    },
    createGridBtn: {
        marginRight: theme.spacing(1),
        width: '100%',
    },
    filterCriteriaHeader: {
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        fontWeight: 'bold'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const ScreeningSummaryPage: React.FunctionComponent<IScreeningLogPageProps> = () => {
    const classes = useStyles();

    const { institutionCode } = useParams<Record<string, string>>();

    const [ineligible, setIneligible] = React.useState(false);
    const [unsuitable, setUnsuitable] = React.useState(false);
    const [declined, setDeclined] = React.useState(false);

    const contextInstitutions = React.useContext(InstitutionsContext);

    const institutions = contextInstitutions?.institutions;

    const showInstitutions = React.useMemo(() => {
        return !institutionCode
    }, [institutionCode]);

    var screeningLogDataColumns = useScreeningSummaryColumns(institutions, showInstitutions, ineligible, unsuitable, declined);


    const labels: IFormLabel[] = [
        {
            name: 'registered',
            label: 'Registered',
            detailedLabel: ''
        },
        {
            name: ("summaryDate"),
            label: ("Start date"),
            detailedLabel: ''
        },
        {
            name: ("endDate"),
            label: ("End date"),
            detailedLabel: ''
        },
        {
            name: ("preScreened"),
            label: ("Number of patients pre-screened"),
            detailedLabel: ''
        },
        {
            name: ("neuroendocrine"),
            label: ("Tumour has neuroendocrine differentiation or small cell features"),
            detailedLabel: ''
        },
        {
            name: ("cprc"),
            label: ("Does not meet protocol Castration-resistant Prostate Cancer (CRPC) definition"),
            detailedLabel: ''
        },
        {
            name: ("psaOrTestosterone"),
            label: ("PSA and/or testosterone out of range "),
            detailedLabel: ''
        },
        {
            name: ("psaDoublingTime"),
            label: ("PSA doubling time >10mths"),
            detailedLabel: ''
        },
        {
            name: ("dectectableMets"),
            label: ("History of OR current detectable metastases on conventional imaging"),
            detailedLabel: ''
        },
        {
            name: ("otherEligibility"),
            label: ("Other eligibility criteria not met"),
            detailedLabel: ''
        },
        {
            name: ("performanceStatus"),
            label: ("Performance status ≥ 2"),
            detailedLabel: ''
        },
        {
            name: ("organMarrowFunction"),
            label: ("Inadequate organ and marrow function"),
            detailedLabel: ''
        },
        {
            name: ("priorTreatment"),
            label: ("Prior treatment with ineligible medication and/or treatment"),
            detailedLabel: ''
        },
        {
            name: ("priorMalignancy"),
            label: ("Prior malignancy"),
            detailedLabel: ''
        },
        {
            name: ("incompatibleComorbities"),
            label: ("Other incompatible comorbidities "),
            detailedLabel: ''
        },
        {
            name: ("unableToComplyWithProtocol"),
            label: ("Unable to comply with protocol"),
            detailedLabel: ''
        },
        {
            name: ("siteRestrictions"),
            label: ("Site restrictions"),
            detailedLabel: ''
        },
        {
            name: ("declined"),
            label: ("Did not want to participate"),
            detailedLabel: ''
        },
        {
            name: ("numberConsented"),
            label: ("Number of patients consented"),
            detailedLabel: ''
        }
    ];

    const downloadButtons =
        <>
            <Button
                variant="contained"
                color="primary"
                size="medium"
                component="a"
                className={classes.mr1}
                href={`/print/screeningsummary/${(institutionCode ?? 'all-institutions')}/pdf`}
            >
                PDF
            </Button>
            <Button
                variant="contained"
                color="primary"
                size="medium"
                component="a"
                href={`/print/screeningsummary/${(institutionCode ?? 'all-institutions')}/csv`}
            >
                CSV
            </Button>
        </>;

    const handleClick = (button: number) => {
        switch (button) {
            case 1:
                setIneligible(!ineligible);
                break;
            case 2:
                setUnsuitable(!unsuitable);
                break;
            case 3:
                setDeclined(!declined);
                break;
        }
    }

    const handleDelete = (button: number) => {
        switch (button) {
            case 1:
                setIneligible(!ineligible);
                break;
            case 2:
                setUnsuitable(!unsuitable);
                break;
            case 3:
                setDeclined(!declined);
                break;
        }
    }

    return (
        <>
            <InstitutionBreadcrumbs />
            <div
                className={classes.container}
            >
                <Typography
                    className={classes.mt1}
                >
                    All patients considered for trial participation should be included in the Pre-screening Summary Data
                    (including those deemed ineligible and not offered the study, those that have declined participation
                    and those who have consented).This information will help the Trial Management Committee identify trends
                    that require any changes to the eligibility criteria.
                    <br /><br />
                    Click <b>‘Add Pre-screening Summary Entry’</b> to add a new entry to the log and enter the available information into the pop-up box.
                    <ul>
                        <li>Enter the start date and stop date for the screening period </li>
                        <li>Enter the total number of patients initially considered for trial in the screening period:</li>
                    </ul>
                    <div style={{ paddingLeft: "40px", marginTop: '-10px', marginBottom: '-10px' }}>
                        <ul style={{ listStyleType: 'circle' }}>
                            <li>For each reason for not consenting, add in the total number of patients for that reason (taken from site pre-screening logs).</li>
                            <li>Enter the total number of patients consented</li>
                        </ul>
                    </div>
                    <br />
                    NOTE: Any participant that consents to the study, should be entered in the Registration page of the OPMS (and reason for
                    screen fail should be recorded if not entered on the trial)
                </Typography>

                <ScreeningSummaryTable
                    columns={screeningLogDataColumns}
                    formFieldsComponent={<ScreeningSummaryFieldGroup />}
                    formLabels={labels}
                    title={"Pre-screening Summary Log"}
                    downloadButtons={downloadButtons}
                    addButtonText='Add Pre-Screening Summary Entry'
                    dataEntryTitle='Pre-screening Summary data'
                />

                <div style={{ padding: '20px 10px 10px 0px', marginLeft: '-10px' }}>
                    <Typography className={classes.filterCriteriaHeader}>Table filter criteria</Typography>
                    <Typography style={{ paddingLeft: '15px' }}>
                        {ineligible ?
                            <Chip onClick={() => handleClick(1)} onDelete={() => handleDelete(1)} label="Ineligible" color="secondary" style={{ marginLeft: 5, marginRight: 5 }} />
                            :
                            <Chip onClick={() => handleClick(1)} label="Ineligible" color="secondary" variant="outlined" style={{ marginLeft: 5, marginRight: 5 }} />
                        }
                        {unsuitable ?
                            <Chip onClick={() => handleClick(2)} onDelete={() => handleDelete(2)} label="Unsuitable" color="secondary" style={{ marginLeft: 5, marginRight: 5 }} />
                            :
                            <Chip onClick={() => handleClick(2)} label="Unsuitable" color="secondary" variant="outlined" style={{ marginLeft: 5, marginRight: 5 }} />
                        }
                        {declined ?
                            <Chip onClick={() => handleClick(3)} onDelete={() => handleDelete(3)} label="Declined" color="secondary" style={{ marginLeft: 5, marginRight: 5 }} />
                            :
                            <Chip onClick={() => handleClick(3)} label="Declined" color="secondary" variant="outlined" style={{ marginLeft: 5, marginRight: 5 }} />
                        }
                    </Typography>
                </div>
            </div>
        </>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default ScreeningSummaryPage;