/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    Condition,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    Password,
    KeyboardDatePicker,
    Numeric,
    StaticGrid,
    IStaticGridHeaderProps,
    IStaticGridCellProps,
    FormLabel,
    Input,
    StaticGridRow,
    FormGrid,
    FieldProvider,
    IFormGridCell,
    Masked,
    CtcaeSelector,
    Checkbox,
    getParentPropertyPath
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-trogapi';

import { RequestState } from '@ngt/request-utilities';

import { Typography, makeStyles, InputAdornment } from '@material-ui/core';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import { NumberFormatValues } from 'react-number-format';
import { get } from 'lodash-es';

//import SourceDocuments from '../../components/SourceDocuments';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IBloodsClinicalProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 1, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const FOUR_DIGIT_POSITIVE_INTEGER = {
    decimalScale: 0,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 10000) {
            return true;
        }

        return false;
    }
}

export const FOUR_DIGIT_INTEGER_ONE_DECIMAL_PLACES_POSITIVE = {
    decimalScale: 1,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 10000) {
            return true;
        }

        return false;
    }
}

export const THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACES_POSITIVE = {
    decimalScale: 1,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 1000) {
            return true;
        }

        return false;
    }
}

export const THREE_DIGIT_INTEGER_TWO_DECIMAL_PLACES_POSITIVE = {
    decimalScale: 2,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 1000) {
            return true;
        }

        return false;
    }
}

export const TWO_DIGIT_INTEGER_TWO_DECIMAL_PLACES_POSITIVE = {
    decimalScale: 2,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 100) {
            return true;
        }

        return false;
    }
}

const biochemistryHeader: IStaticGridHeaderProps[] = [
    {
        columnName: 'test',
        content: 'Test',
        width: '50%',
        minWidth: 300
    },
    {
        columnName: 'value',
        content: 'Value',
        width: '25%',
        minWidth: 200
    },
    {
        columnName: 'uln',
        content: 'ULN',
        width: '25%',
        minWidth: 200
    }
];

const altRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'alt'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            U/L&nbsp;
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACES_POSITIVE}
            />
        ),
        fieldName: 'alt'
    },
    {
        columnName: 'uln',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            U/L&nbsp;
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACES_POSITIVE}
            />
        ),
        fieldName: 'altUln'
    }
];

const astRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'ast'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            U/L&nbsp;
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACES_POSITIVE}
            />
        ),
        fieldName: 'ast'
    },
    {
        columnName: 'uln',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            U/L&nbsp;
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACES_POSITIVE}
            />
        ),
        fieldName: 'astUln'
    }
];

const bilirubinRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'totalBilirubin'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">&micro;mol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACES_POSITIVE}
            />
        ),
        fieldName: 'totalBilirubin'
    },
    {
        columnName: 'uln',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">&micro;mol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACES_POSITIVE}
            />
        ),
        fieldName: 'totalBilirubinUln'
    }
];

const ldhRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'ldh'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            U/L&nbsp;
                        </InputAdornment>
                    )
                }}
                numberFormat={FOUR_DIGIT_INTEGER_ONE_DECIMAL_PLACES_POSITIVE}
            />
        ),
        fieldName: 'ldh'
    }
];

const albuminRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'albumin'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            g/L&nbsp;
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACES_POSITIVE}
            />
        ),
        fieldName: 'albumin'
    }
];

const ureaRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'urea'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            mmol/L&nbsp;
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_INTEGER_TWO_DECIMAL_PLACES_POSITIVE}
            />
        ),
        fieldName: 'urea'
    }
];

const creatinineRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'creatinine'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">&micro;mol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACES_POSITIVE}
            />
        ),
        fieldName: 'creatinine'
    },
    {
        columnName: 'uln',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">&micro;mol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACES_POSITIVE}
            />
        ),
        fieldName: 'creatinineUln'
    }
];

const calciumRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'calcium'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            mmol/L&nbsp;
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_INTEGER_TWO_DECIMAL_PLACES_POSITIVE}
            />
        ),
        fieldName: 'calcium'
    }
];

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const BloodsClinical: React.FunctionComponent<IBloodsClinicalProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const { form } = React.useContext(FormContext)

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.BloodsClinical}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <Field
                    name="assessmentDate"
                    component={KeyboardDatePicker}
                />
                <Field
                    name="haemoglobin"
                    component={Numeric}
                    numberFormat={THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACES_POSITIVE}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                g/dL&nbsp;
                            </InputAdornment>
                        )
                    }}
                />
                <Field
                    name="neutrophilCount"
                    component={Numeric}
                    numberFormat={TWO_DIGIT_INTEGER_TWO_DECIMAL_PLACES_POSITIVE}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography color="textSecondary">x10<sup>9</sup>/l&nbsp;</Typography>
                            </InputAdornment>
                        )
                    }}
                />
                <Field
                    name="lymphocyteCount"
                    component={Numeric}
                    numberFormat={TWO_DIGIT_INTEGER_TWO_DECIMAL_PLACES_POSITIVE}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography color="textSecondary">x10<sup>9</sup>/l&nbsp;</Typography>
                            </InputAdornment>
                        )
                    }}
                />
                <Field
                    name="plateletCount"
                    component={Numeric}
                    numberFormat={FOUR_DIGIT_POSITIVE_INTEGER}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography color="textSecondary">x10<sup>9</sup>/l&nbsp;</Typography>
                            </InputAdornment>
                        )
                    }}
                />
                <FieldGroup>
                    <Field
                        name="participantHasGilbertDisease"
                        component={ToggleButtonGroup}
                    />
                    <StaticGrid
                        headers={biochemistryHeader}
                    >
                        <StaticGridRow
                            cells={altRow}
                        />
                        <StaticGridRow
                            cells={astRow}
                        />
                        <StaticGridRow
                            cells={bilirubinRow}
                        />
                        <StaticGridRow
                            cells={ldhRow}
                        />
                        <StaticGridRow
                            cells={albuminRow}
                        />
                        <StaticGridRow
                            cells={ureaRow}
                        />
                        <StaticGridRow
                            cells={creatinineRow}
                        />
                    </StaticGrid>
                </FieldGroup>
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default BloodsClinical;
