
 //* ---------------------------------------------------------------------------------
 //* Copyright:
 //*      NewtonGreen Technologies Pty. Ltd.
 //*      Level 4, 175 Scott St.
 //*      Newcastle, NSW, 2300
 //*      Australia
 //*
 //*      E-mail: support@newtongreen.com
 //*      Tel: (02) 4925 5288
 //*      Fax: (02) 4925 3068
 //*
 //*      All Rights Reserved.
 //* ---------------------------------------------------------------------------------
 


 //* --------------------------------------------------------------------------------
 //* This file contains the hook to use screening summary columns.
 //* --------------------------------------------------------------------------------
 


 //* ---------------------------------------------------------------------------------
 //* Imports - External
 //* ---------------------------------------------------------------------------------
 


 //* Used to create a context.
 
import * as React from 'react';


 //* Used to type columns
 
import { Column } from 'material-table';


 //* Used to format datetimes
 
import { DateTime } from 'luxon';

 //* ---------------------------------------------------------------------------------
 //* Imports - Internal
 //* ---------------------------------------------------------------------------------
 

import * as Dtos from '../../api/dtos';


 //* ---------------------------------------------------------------------------------
 //* Functions
 //* ---------------------------------------------------------------------------------
 

const useScreeningSummaryColumns = (institutions: Dtos.IInstitution[] | null, showSite: boolean, ineligible: boolean, unsuitable: boolean, declined: boolean): Array<Column<Dtos.ScreeningSummary>> => {
    const columns = React.useMemo(() => {
        const cols: Array<Column<Dtos.ScreeningSummary> | boolean> = [
            {
                field: ("summaryDate"),
                title: ("Start date"),
                render: (screeningSummary, type) => (screeningSummary.summaryDate ? DateTime.fromISO(screeningSummary.summaryDate).toFormat('dd/MM/yyyy') : ''),
            },
            {
                field: ("endDate"),
                title: ("End date"),
                render: (screeningSummary, type) => (screeningSummary.endDate ? DateTime.fromISO(screeningSummary.endDate).toFormat('dd/MM/yyyy') : ''),
            },
            {
                field: ("preScreened"),
                title: ("Number of patients Pre-Screened"),
                render: (screeningSummary, type) => (screeningSummary.preScreened ? screeningSummary.preScreened : 0),
            },
            {
                field: ("numberConsented"),
                title: ("Number of patients Consented"),
                render: (screeningSummary, type) => (screeningSummary.numberConsented ? screeningSummary.numberConsented : 0),
            }
        ];
        if (ineligible) {
            cols.push(
                {
                    field: ("neuroendocrine"),
                    title: ("Tumour has neuroendocrine differentiation or small cell features"),
                    render: (screeningSummary, type) => (screeningSummary.neuroendocrine ? screeningSummary.neuroendocrine : 0),
                },
                {
                    field: ("cprc"),
                    title: ("Does not meet protocol Castration-resistant Prostate Cancer (CRPC) definition"),
                    render: (screeningSummary, type) => (screeningSummary.cprc ? screeningSummary.cprc : 0),
                },
                {
                    field: ("psaOrTesfromsterone"),
                    title: ("PSA and/or Testosterone out of range"),
                    render: (screeningSummary, type) => (screeningSummary.psaOrTestosterone ? screeningSummary.psaOrTestosterone : 0),
                },
                {
                    field: ("psaDoublingTime"),
                    title: ("PSA doubling time >10mths"),
                    render: (screeningSummary, type) => (screeningSummary.psaDoublingTime ? screeningSummary.psaDoublingTime : 0),
                },
                {
                    field: ("dectectableMets"),
                    title: ("History of OR current detectable metastases on conventional imaging"),
                    render: (screeningSummary, type) => (screeningSummary.dectectableMets ? screeningSummary.dectectableMets : 0),
                },
                {
                    field: ("otherEligibility"),
                    title: ("Other eligibility criteria not met"),
                    render: (screeningSummary, type) => (screeningSummary.otherEligibility ? screeningSummary.otherEligibility : 0),
                }
            );
        }
        if (unsuitable) {
            cols.push(
                {
                    field: ("organMarrowFunction"),
                    title: ("Performance status ≥2"),
                    render: (screeningSummary, type) => (screeningSummary.organMarrowFunction ? screeningSummary.organMarrowFunction : 0),
                },
                {
                    field: ("performanceStatus"),
                    title: ("Inadequate organ and marrow function"),
                    render: (screeningSummary, type) => (screeningSummary.performanceStatus ? screeningSummary.performanceStatus : 0),
                },
                {
                    field: ("priorTreatment"),
                    title: ("Prior treatment with ineligible medication and/or treatment"),
                    render: (screeningSummary, type) => (screeningSummary.priorTreatment ? screeningSummary.priorTreatment : 0),
                },
                {
                    field: ("priorMalignancy"),
                    title: ("Prior malignancy"),
                    render: (screeningSummary, type) => (screeningSummary.priorMalignancy ? screeningSummary.priorMalignancy : 0),
                },
                {
                    field: ("incompatibleComorbities"),
                    title: ("Other incompatible comorbidities"),
                    render: (screeningSummary, type) => (screeningSummary.incompatibleComorbities ? screeningSummary.incompatibleComorbities : 0),
                },
                {
                    field: ("unableToComplyWithProtocol"),
                    title: ("Unable to comply with protocol"),
                    render: (screeningSummary, type) => (screeningSummary.unableToComplyWithProtocol ? screeningSummary.unableToComplyWithProtocol : 0),
                },
                {
                    field: ("siteRestrictions"),
                    title: ("Site Restrictions"),
                    render: (screeningSummary, type) => (screeningSummary.siteRestrictions ? screeningSummary.siteRestrictions : 0),
                }
            );
        }
        if (declined) {
            cols.push(
                {
                    field: ("declined"),
                    title: ("Did not want to participate"),
                            render: (screeningSummary, type) => (screeningSummary.declined ? screeningSummary.declined : 0),
                }
            );
        }
        return cols.filter(column => !!column) as Array<Column<Dtos.ScreeningSummary>>;
    }, [institutions, showSite, ineligible, unsuitable, declined]);

    return columns;
};

 //* ---------------------------------------------------------------------------------
 //* Default Export
 //* ---------------------------------------------------------------------------------
 

export default useScreeningSummaryColumns;