/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    Condition,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    Password,
    KeyboardDatePicker,
    Input,
    FileUpload,
    IFormGridCell,
    FormGrid,
    IFileUpload,
    useFormState,
    FormDefinitionContext,
    ProgressButton,
    OnlinePatientManagementContext,
    getParentPropertyPath,
    Masked,
    Checkbox,
    CtcaeSelector
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-trogapi';

import { RequestState } from '@ngt/request-utilities';

import { Typography, makeStyles, List, ListItem, ListItemText, ListItemIcon, Button } from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheck } from '@fortawesome/pro-duotone-svg-icons/faCheck';

import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes';


/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import { get } from 'lodash-es';

//import SourceDocuments from '../../components/SourceDocuments';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IAdverseEventProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 1, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const isAeNotOngoing = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.ClinicalAssessment>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.AdverseEvent = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.ongoing !== true;
};

export const UK_DATE = {
    mask: [/[0-3U]/, /[0-9K]/, '/', /[0-1U]/, /[0-9K]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    showMask: true
};

const adverseEventColumns: Array<IFormGridCell<Dtos.AdverseEvent>> = [
    {
        name: 'ctcaeTermId',
        minWidth: 450,
        maxWidth: 450,
        width: 450,
        content: (
            <Input
                component={CtcaeSelector}
                gradeFieldName="ctcaeGradeId"
                specificationFieldName="ctcaeSpecification"
                display="SelectorWithName"
                ctcaeUrl="https://ctcae.trog.com.au/"
            />
        )
    },
    {
        name: 'startDate',
        minWidth: 150,
        maxWidth: 150,
        width: 150,
        content: (
            <Input
                component={Masked}
                maskedFormat={UK_DATE}
            />
        )
    },
    {
        name: 'stopDate',
        minWidth: 150,
        maxWidth: 150,
        width: 150,
        content: (
            <CrfCondition
                type={Dtos.ClinicalAssessment}
                condition={isAeNotOngoing}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Masked}
                    maskedFormat={UK_DATE}
                />
            </CrfCondition>
        )
    },
    {
        name: 'ongoing',
        header: (<></>),
        minWidth: 100,
        maxWidth: 100,
        width: 100,
        content: (
            <Input
                component={Checkbox}
            />
        )
    },
];

const participantExperiencingAnyAeYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.AdverseEventForm>) => GetFieldLookupItem(lookups, 'participantExperiencingAnyAe', formState?.values.participantExperiencingAnyAe)?.type === Dtos.LookupYesNoType.Yes;

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const AdverseEventTbl: React.FunctionComponent = () => {
    const classes = useStyles();

    const { values: formValues } = useFormState<Dtos.AdverseEventForm, any>({ values: true });

    return (
        <>
            <CrfCondition
                type={Dtos.AdverseEventForm}
                condition={participantExperiencingAnyAeYes}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Typography
                    variant="h3"
                    color="secondary"
                    className={classes.subHeading}
                >
                    Adverse Event
                </Typography>
                <FormGrid
                    type={Dtos.AdverseEvent}
                    name="adverseEvents"
                    columns={adverseEventColumns}
                    minRow={formValues.participantExperiencingAnyAe ? 1 : undefined}
                />
            </CrfCondition>
        </>
    );
}

const AdverseEvent: React.FunctionComponent<IAdverseEventProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.AdverseEventForm}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <Field
                    name="participantExperiencingAnyAe"
                    component={ToggleButtonGroup}
                />
                <AdverseEventTbl />
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default AdverseEvent;
