declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

export interface IReportSettings {
    reportServerUrl: string;
    reportViewUrl: string;
    reportDownloadUrl: string;
    reportFolder: string;
    generalPreScreening: string;
    generalReasonsForIneligibilty: string;
    generalScreeningOverview: string;
   
}

const reportSettings: IReportSettings = global.REPORT_SETTINGS;

export default reportSettings;