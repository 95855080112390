import { NumberFormatValues } from "react-number-format";

export const THREE_DIGIT_POSITIVE_INTEGER = {
    decimalScale: 0,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 1000) {
            return true;
        }

        return false;
    }
};