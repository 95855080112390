/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    Condition,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    Password,
    KeyboardDatePicker,
    Input,
    FileUpload,
    IFormGridCell,
    FormGrid,
    IFileUpload,
    useFormState,
    FormDefinitionContext,
    ProgressButton,
    OnlinePatientManagementContext,
    getParentPropertyPath,
    Masked,
    Checkbox,
    Numeric
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-trogapi';

import { RequestState } from '@ngt/request-utilities';

import { Typography, makeStyles, List, ListItem, ListItemText, ListItemIcon, Button } from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheck } from '@fortawesome/pro-duotone-svg-icons/faCheck';

import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes';


/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import { get } from 'lodash-es';

//import SourceDocuments from '../../components/SourceDocuments';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IConcomitantMedicationProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 1, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const isConMedNotOngoing = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.ConcomitantMedicationForm>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.ConcomitantMedication = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.ongoing !== true;
};

const isMedIndicationOther = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.ConcomitantMedicationForm>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.ConcomitantMedication = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.indication === Dtos.LookupIndicationType.Other;
};

export const UK_DATE = {
    mask: [/[0-3U]/, /[0-9K]/, '/', /[0-1U]/, /[0-9K]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    showMask: true
};

const concomitantMedicationColumns: Array<IFormGridCell<Dtos.ConcomitantMedication>> = [
    {
        name: 'medicationName',
        minWidth: 190,
        maxWidth: 190,
        width: 190,
        content: (
            <Input
                component={Text}
            />
        )
    },
    {
        name: 'indication',
        minWidth: 190,
        maxWidth: 190,
        width: 190,
        content: (
            <Input
                component={Select}
            />
        )
    },
    {
        name: 'indicationOther',
        minWidth: 160,
        maxWidth: 160,
        width: 160,
        content: (
            <CrfCondition
                type={Dtos.ConcomitantMedicationForm}
                condition={isMedIndicationOther}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Text}
                />
            </CrfCondition>
        )
    },
    {
        name: 'startDate',
        minWidth: 160,
        maxWidth: 160,
        width: 160,
        content: (
            <Input
                component={Masked}
                maskedFormat={UK_DATE}
            />
        )
    },
    {
        name: 'stopDate',
        minWidth: 160,
        maxWidth: 160,
        width: 160,
        content: (
            <CrfCondition
                type={Dtos.ConcomitantMedicationForm}
                condition={isConMedNotOngoing}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Masked}
                    maskedFormat={UK_DATE}
                />
            </CrfCondition>
        )
    },
    {
        name: 'ongoing',
        header: (<></>),
        minWidth: 100,
        maxWidth: 100,
        width: 100,
        content: (
            <Input
                component={Checkbox}
            />
        )
    }
]

const participantTakingAnyMedicationsYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.ConcomitantMedicationForm>) => GetFieldLookupItem(lookups, 'participantTakingAnyMedications', formState?.values.participantTakingAnyMedications)?.type === Dtos.LookupYesNoType.Yes;

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const ConcomitantMedicationTbl: React.FunctionComponent = () => {
    const classes = useStyles();

    const { values: formValues } = useFormState<Dtos.ConcomitantMedicationForm, any>({ values: true });

    return (
        <>
            <CrfCondition
                type={Dtos.ConcomitantMedicationForm}
                condition={participantTakingAnyMedicationsYes}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Typography
                    variant="h3"
                    color="secondary"
                    className={classes.subHeading}
                >
                    Concomitant Medication
                    </Typography>
                <FormGrid
                    type={Dtos.ConcomitantMedication}
                    name="concomitantMedication"
                    columns={concomitantMedicationColumns}
                    minRow={formValues?.participantTakingAnyMedications ? 1 : undefined}
                />
            </CrfCondition>
        </>
    );
}

const ConcomitantMedication: React.FunctionComponent<IConcomitantMedicationProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.ConcomitantMedicationForm}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <Field
                    name="participantTakingAnyMedications"
                    component={ToggleButtonGroup}
                />
                <ConcomitantMedicationTbl />
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default ConcomitantMedication;
