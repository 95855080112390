/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    Condition,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    Password,
    KeyboardDatePicker,
    Numeric,
    Input,
    FormGrid,
    IStaticGridCellProps,
    IFormGridCell
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-trogapi';

import { RequestState } from '@ngt/request-utilities';

import { Typography, makeStyles, InputAdornment } from '@material-ui/core';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import { NumberFormatValues } from 'react-number-format';
import { THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACES_POSITIVE } from './ClinicalAssessment';


//import SourceDocuments from '../../components/SourceDocuments';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IProstateCancerDetailsProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 1, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const previousAntiAndrogenUseYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.ProstateCancerDetails>) => GetFieldLookupItem(lookups, 'previousAntiAndrogenUse', formState?.values.previousAntiAndrogenUse)?.type === Dtos.LookupYesNoType.Yes;
const bilateralOrchiectomyYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.ProstateCancerDetails>) => GetFieldLookupItem(lookups, 'bilateralOrchiectomy', formState?.values.bilateralOrchiectomy)?.type === Dtos.LookupYesNoType.Yes;
const participantCurrentlyOnGnrhYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.ProstateCancerDetails>) => GetFieldLookupItem(lookups, 'participantCurrentlyOnGnrh', formState?.values.participantCurrentlyOnGnrh)?.type === Dtos.LookupYesNoType.Yes;
const participantCurrentlyOnGnrhNo = ({ lookups, formState }: ICrfConditionParameters<Dtos.ProstateCancerDetails>) => GetFieldLookupItem(lookups, 'participantCurrentlyOnGnrh', formState?.values.participantCurrentlyOnGnrh)?.type === Dtos.LookupYesNoType.No;

export const THREE_DIGIT_INTEGER_TWO_DECIMAL_PLACES_POSITIVE = {
    decimalScale: 2,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 1000) {
            return true;
        }

        return false;
    }
}

export const TWO_DIGIT_INTEGER_TWO_DECIMAL_PLACES_POSITIVE = {
    decimalScale: 2,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 100) {
            return true;
        }

        return false;
    }
}

const previousPsaAndTestosteroneResultsColumns: Array<IFormGridCell<Dtos.PreviousPsaAndTestosteroneResult>> = [
    {
        name: 'dateOfPsa',
        minWidth: 250,
        maxWidth: 250,
        width: 250,
        content: (
            <Input
                component={KeyboardDatePicker}
            />
        )
    },
    {
        name: 'psaLevel',
        minWidth: 250,
        maxWidth: 250,
        width: 250,
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            ng/ml&nbsp;
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_INTEGER_TWO_DECIMAL_PLACES_POSITIVE}
            />
        )
    },
    {
        name: 'dateOfSerumTestosterone',
        minWidth: 250,
        maxWidth: 250,
        width: 250,
        content: (
            <Input
                component={KeyboardDatePicker}
            />
        )
    },
    {
        name: 'serumTestosterone',
        minWidth: 250,
        maxWidth: 250,
        width: 250,
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            nmol/l&nbsp;
                        </InputAdornment>
                    )
                }}
                numberFormat={TWO_DIGIT_INTEGER_TWO_DECIMAL_PLACES_POSITIVE}
            />
        )
    }
];

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const ProstateCancerDetails: React.FunctionComponent<IProstateCancerDetailsProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const { form } = React.useContext(FormContext)

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.ProstateCancerDetails}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <FieldGroup>
                    <Field
                        name="anyConfirmedAdenoCarcinoma"
                        component={ToggleButtonGroup}
                    />
                    <Field
                        name="anyNeuroendocrineDifferentiation"
                        component={ToggleButtonGroup}
                    />
                    <Field
                        name="anySmallCellFeatures"
                        component={ToggleButtonGroup}
                    />
                </FieldGroup>
                <FieldGroup>
                    <Field
                        name="previousAntiAndrogenUse"
                        component={ToggleButtonGroup}
                    />
                    <CrfCondition
                        type={Dtos.ProstateCancerDetails}
                        condition={previousAntiAndrogenUseYes}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="dateOfAntiAndrogenWithdrawal"
                            component={KeyboardDatePicker}
                        />
                    </CrfCondition>
                </FieldGroup>
                <Field
                    name="previousProstatectomy"
                    component={ToggleButtonGroup}
                />
                <FieldGroup>
                    <Field
                        name="bilateralOrchiectomy"
                        component={ToggleButtonGroup}
                    />
                    <CrfCondition
                        type={Dtos.ProstateCancerDetails}
                        condition={bilateralOrchiectomyYes}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="dateOfBilateralOrchiectomy"
                            component={KeyboardDatePicker}
                        />
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <Field
                        name="participantCurrentlyOnGnrh"
                        component={ToggleButtonGroup}
                    />
                    <CrfCondition
                        type={Dtos.ProstateCancerDetails}
                        condition={participantCurrentlyOnGnrhYes}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="gnrhTherapyStartDate"
                            component={KeyboardDatePicker}
                        />
                    </CrfCondition>
                    <CrfCondition
                        type={Dtos.ProstateCancerDetails}
                        condition={participantCurrentlyOnGnrhNo}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="gnrhTherapyStopDate"
                            component={KeyboardDatePicker}
                        />
                    </CrfCondition>
                    <Field
                        name="dateOfCrpcConfirmed"
                        component={KeyboardDatePicker}
                    />
                    <Typography
                        variant="h3"
                        color="secondary"
                        className={classes.subHeading}
                    >
                        Previous PSA and Testosterone results to show castration-resistance
                    </Typography>
                    <FormGrid
                        type={Dtos.PreviousPsaAndTestosteroneResult}
                        name="previousPsaAndTestosteroneResults"
                        columns={previousPsaAndTestosteroneResultsColumns}
                        minRow={2}
                    />
                    <Field
                        name="participantPsaDoublingTime"
                        component={Numeric}
                        numberFormat={THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACES_POSITIVE}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    mths&nbsp;
                                </InputAdornment>
                            )
                        }}
                    />
                </FieldGroup>
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default ProstateCancerDetails;
