/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    Condition,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    Password,
    KeyboardDatePicker,
    Checkbox,
    Numeric,

    Autocomplete
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-trogapi';

import { RequestState } from '@ngt/request-utilities';

import { Typography, makeStyles, Box } from '@material-ui/core';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import { NumberFormatValues } from 'react-number-format';
import { THREE_DIGIT_POSITIVE_INTEGER } from '../../validators/numeric';
import { countryCodes } from '../../utils/countryCodes';
import { FilterOptionsState } from '@material-ui/lab';
import { languageCodes } from '../../utils/languageCodes';

//import SourceDocuments from '../../components/SourceDocuments';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IGeneralProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 3, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */
const postcodeNotApplicableFalse = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => formState?.values.postcodeNotApplicable != true;

export const FOUR_DIGIT_POSITIVE_INTEGER = {
    decimalScale: 0,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 10000) {
            return true;
        }

        return false;
    }
}

export const TWO_DIGIT_POSITIVE_INTEGER = {
    decimalScale: 0,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 100) {
            return true;
        }

        return false;
    }
}

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const General: React.FunctionComponent<IGeneralProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const { form } = React.useContext(FormContext)

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.General}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <Field
                    name="siteName"
                    component={Text}
                    label="Site name"
                    inputProps={{
                        value: institution?.name
                    }}
                    disabled
                />
                <Field
                    name="patientInitials"
                    component={Text}
                />
                <Field
                    name="dateOfBirth"
                    component={KeyboardDatePicker}
                />
                <Field
                    name="ageAtConsent"
                    component={Numeric}
                    numberFormat={THREE_DIGIT_POSITIVE_INTEGER}
                />
                <Field
                    name="medicalOncologistName"
                    component={Text}
                />
                <Field
                    name="radiationOncologistName"
                    component={Text}
                />
                <Field
                    name="dateOfConsent"
                    component={KeyboardDatePicker}
                />
                <Typography
                    variant="h3"
                    color="secondary"
                    className={classes.subHeading}
                >
                    Demographic Details
                </Typography>
                <Field
                    name="indigenousStatus"
                    component={Select}
                />
                <Field
                    name="countryOfBirth"
                    component={Autocomplete}
                    options={countryCodes}
                    autoHighlight
                    getOptionLabel={(option: Dtos.ILookupItem) => `${option.id}`}
                    filterOptions={(options: Dtos.ILookupItem[], state: FilterOptionsState<Dtos.ILookupItem>) => options.filter(option => option.id?.toString().includes(state.inputValue) || option.value.toLowerCase().includes(state.inputValue.toLowerCase()))}
                    renderOption={(option: Dtos.ILookupItem) => (
                        <Box style={{ display: 'flex', gap: '1rem' }}>
                            <Box><strong>{option.id}</strong></Box>
                            <Box>{option.value}</Box>
                        </Box>
                    )}
                /> 
                <Field
                    name="primarySpokenLanguage"
                    component={Autocomplete}
                    options={languageCodes}
                    autoHighlight
                    getOptionLabel={(option: Dtos.ILookupItem) => `${option.id}`}
                    filterOptions={(options: Dtos.ILookupItem[], state: FilterOptionsState<Dtos.ILookupItem>) => options.filter(option => option.id?.toString().includes(state.inputValue) || option.value.toLowerCase().includes(state.inputValue.toLowerCase()))}
                    renderOption={(option: Dtos.ILookupItem) => (
                        <Box style={{ display: 'flex', gap: '1rem' }}>
                            <Box><strong>{option.id}</strong></Box>
                            <Box>{option.value}</Box>
                        </Box>
                    )}
                />
                <Field
                    name="languageSpokenAsAChild"
                    component={Autocomplete}
                    options={languageCodes}
                    autoHighlight
                    getOptionLabel={(option: Dtos.ILookupItem) => `${option.id}`}
                    filterOptions={(options: Dtos.ILookupItem[], state: FilterOptionsState<Dtos.ILookupItem>) => options.filter(option => option.id?.toString().includes(state.inputValue) || option.value.toLowerCase().includes(state.inputValue.toLowerCase()))}
                    renderOption={(option: Dtos.ILookupItem) => (
                        <Box style={{ display: 'flex', gap: '1rem' }}>
                            <Box><strong>{option.id}</strong></Box>
                            <Box>{option.value}</Box>
                        </Box>
                    )}
                />
                <FieldGroup>
                    <Field
                        name="postcodeNotApplicable"
                        component={Checkbox}
                        label="Postcode"
                    />
                    <CrfCondition
                        type={Dtos.General}
                        mode="Enable"
                        condition={postcodeNotApplicableFalse}
                        subscription={{ values: true }}
                    >
                        <Field
                            name="postcode"
                            component={Text}
                            label=""
                        />
                    </CrfCondition>
                </FieldGroup>
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default General;
