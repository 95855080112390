/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to get access to router context.
 */
import { useHistory, useParams, Link } from 'react-router-dom';

/*
 * Used to style components
 */
import { makeStyles, Grid, Button, Tooltip } from '@material-ui/core';

/**
 * Used for the basic page layout.
 */
import {
    TrialContextSelector,
    ALL_MASTER_GROUPS_CODE,
    ALL_COLLABORATING_GROUPS_CODE,
    ALL_COUNTRIES_CODE,
    ALL_INSTITUTIONS_CODE,
    InstitutionsContext,
    PatientsByCodesResolver,
    PatientCollapsibleTable,
    CountriesContext,
    InstitutionBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    PatientContext,
    RouteLoading
} from '@ngt/opms';

import { RequestState } from '@ngt/request-utilities';
import { usePermissionsByIds } from '@ngt/opms-trogapi';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * used to get column spec for the new patient table.
 */
import usePrerandomisedColumns from '../hooks/usePrerandomisedColumns';

/*
 * used to get column spec for the pre-registered patient table.
 */
import useUnderReviewPatientColumns from '../hooks/useUnderReviewPatientColumns';

/*
 * used to get column spec for the randomised patient table.
 */
import useRandomisedPatientColumns from '../hooks/useRandomisedPatientColumns';

/*
 * used to get column spec for the ineligible patient table.
 */
import useIneligiblePatientColumns from '../hooks/useIneligiblePatientColumns';

/*
 * Used to type patient state.
 */
import * as Dtos from '../api/dtos';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IRegistrationParams {
    masterGroupCode?: string
    collaboratingGroupCode?: string
    countryCode?: string
    institutionCode?: string
}

interface IRegistrationProps {
    showAdminPage?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    addPatientColumn: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    addPatient: {
        width: '100%'
    }
}));

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsAdminister,
    Dtos.Permission.OpmsPatientUpdate,
    Dtos.Permission.OpmsPatientImpersonate,
    Dtos.Permission.OpmsPatientAccountManagement
];

const prerandomisedStates = [Dtos.PatientStateType.Prerandomised];
const underReviewStates = [Dtos.PatientStateType.UnderReview];
const randomisedStates = [Dtos.PatientStateType.Randomised];
const ineligibleStates = [Dtos.PatientStateType.Ineligible];


const Registration: React.FunctionComponent<IRegistrationProps> = ({
    showAdminPage
}) => {
    const classes = useStyles();

    const history = useHistory();

    const { masterGroupCode, collaboratingGroupCode, countryCode, institutionCode } = useParams<IRegistrationParams>();

    const onSelect = React.useCallback((newMasterGroupCode?: string | null, newCollaboratingGroupCode?: string | null, newCountryCode?: string | null, newInstitutionCode?: string | null) => {
        if (!showAdminPage) {
            if (newInstitutionCode) {
                history.push(`/registration/${newInstitutionCode}`);
            }
            else {
                history.push(`/registration`);
            }

            return;
        }

        if (newInstitutionCode) {
            history.push(`/registration/admin/${(newMasterGroupCode ?? ALL_MASTER_GROUPS_CODE)}/${(newCollaboratingGroupCode ?? ALL_COLLABORATING_GROUPS_CODE)}/${(newCountryCode ?? ALL_COUNTRIES_CODE)}/${newInstitutionCode}`);
        }
        else if (newCountryCode) {
            history.push(`/registration/admin/${(newMasterGroupCode ?? ALL_MASTER_GROUPS_CODE)}/${(newCollaboratingGroupCode ?? ALL_COLLABORATING_GROUPS_CODE)}/${newCountryCode}`);

        }
        else if (newCollaboratingGroupCode) {
            history.push(`/registration/admin/${(newMasterGroupCode ?? ALL_MASTER_GROUPS_CODE)}/${newCollaboratingGroupCode}`);

        }
        else if (newMasterGroupCode) {
            history.push(`/registration/admin/${newMasterGroupCode}`);
        }
        else {
            history.push(`/registration/admin`);
        }
    }, [history, showAdminPage]);

    const parsedMasterGroupCode = React.useMemo(() => {
        return masterGroupCode !== ALL_MASTER_GROUPS_CODE ? masterGroupCode : null;
    }, [masterGroupCode]);

    const parsedCollaboratingGroupCode = React.useMemo(() => {
        return collaboratingGroupCode !== ALL_COLLABORATING_GROUPS_CODE ? collaboratingGroupCode : null;
    }, [collaboratingGroupCode]);

    const parsedCountryCode = React.useMemo(() => {
        return countryCode !== ALL_COUNTRIES_CODE ? countryCode : null;
    }, [countryCode]);

    const parsedInstitutionCode = React.useMemo(() => {
        return institutionCode !== ALL_INSTITUTIONS_CODE ? institutionCode : null;
    }, [institutionCode]);

    const { countries } = React.useContext(CountriesContext);
    const { institutions } = React.useContext(InstitutionsContext);

    React.useEffect(() => {
        if (institutions?.length === 1) {
            history.push(`/registration/${institutions[0].code}`)
        }
    }, [institutions, history])

    const institution = React.useMemo(() => {
        return institutions?.find(institution => institution.code === parsedInstitutionCode);
    }, [parsedInstitutionCode, institutions])

    const showInitials = React.useMemo(() => {
        if (!parsedCountryCode && !institution) {
            return false;
        }

        if (parsedCountryCode) {
            return countries?.find(country => country.code === parsedCountryCode)?.name === 'Australia' ?? false;
        }

        const institutionCountryId = institution?.countryId;

        if (!institutionCountryId) {
            return false;
        }

        return countries?.find(country => country.id === institutionCountryId)?.name === 'Australia' ?? false;
    }, [parsedCountryCode, countries, institution]);

    const showInstitutions = React.useMemo(() => {
        return !parsedInstitutionCode
    }, [parsedInstitutionCode]);

    const prerandomisedColumns = usePrerandomisedColumns(institutions, true, showInstitutions);
    const underReviewColumns = useUnderReviewPatientColumns(institutions, true, showInstitutions);
    const randomisedColumns = useRandomisedPatientColumns(institutions, true, showInstitutions);
    const ineligibleColumns = useIneligiblePatientColumns(institutions, true, showInstitutions);

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { patient, actions, saveState } = React.useContext(PatientContext);

    const [
        [
            canAdministerOpms,
            canUpdatePatient,
            canImpersonatePatient,
            canManagePatientAccount
        ],
        permissionLoadState
    ] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true); /*TODO: ask about the permission load state*/

    const onRowClick = React.useCallback((event: React.MouseEvent<Element, MouseEvent>, rowData?: Dtos.IPatient | undefined) => {
        history.push(`/registration/${institutionCode ?? ALL_INSTITUTIONS_CODE}/${rowData?.studyNumber}`)
    }, [history, institutionCode]);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <>
                <RouteLoading />
            </>
        );
    }

    return (
        <>
            <InstitutionBreadcrumbs />
            <div
                className={classes.container}
            >
                <TrialContextSelector
                    onChange={onSelect}
                    allowAllMasterGroups={true}
                    allowAllCollaboratingGroups={true}
                    allowAllCountries={true}
                    allowAllInstitutions={(institutions?.length ?? 0) > 1}
                    hideMasterGroups={!showAdminPage}
                    hideCollaboratingGroups={!showAdminPage}
                    hideCountries={!showAdminPage}
                    masterGroupCode={masterGroupCode}
                    collaboratingGroupCode={collaboratingGroupCode}
                    countryCode={countryCode}
                    institutionCode={institutionCode}
                >
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.addPatientColumn}
                    >
                        {
                            parsedInstitutionCode && canUpdatePatient ?
                                institution?.enabled ?
                                    <Button
                                        className={classes.addPatient}
                                        variant="contained"
                                        color="secondary"
                                        component={Link}
                                        to={
                                            showAdminPage ?
                                                `/registration/admin/${parsedMasterGroupCode ?? ALL_MASTER_GROUPS_CODE}/${parsedCollaboratingGroupCode ?? ALL_COLLABORATING_GROUPS_CODE}/${parsedCountryCode ?? ALL_COUNTRIES_CODE}/${parsedInstitutionCode ?? ALL_INSTITUTIONS_CODE}/add-patient` :
                                                `/registration/${parsedInstitutionCode ?? ALL_INSTITUTIONS_CODE}/add-patient`
                                        }
                                    >
                                        Add Participant
                                    </Button> :
                                    <Tooltip
                                        className={classes.addPatient}
                                        title="Institution must be active to add a patient"
                                    >
                                        <div>
                                            <Button
                                                className={classes.addPatient}
                                                disabled
                                                variant="contained"
                                                color="secondary"
                                                component={Link}
                                                to={`/registration/${parsedInstitutionCode ?? ALL_INSTITUTIONS_CODE}/add-patient`}
                                            >
                                                Add Participant
                                            </Button>
                                        </div>
                                    </Tooltip> :
                                <Tooltip
                                    className={classes.addPatient}
                                    title={canUpdatePatient ? 'Select an institution to add a patient' : 'Insufficient permissions to add a patient'}
                                >
                                    <div>
                                        <Button
                                            className={classes.addPatient}
                                            disabled
                                            variant="contained"
                                            color="secondary"
                                            component={Link}
                                            to={`/registration/${parsedInstitutionCode ?? ALL_INSTITUTIONS_CODE}/add-patient`}
                                        >
                                            Add Participant
                                        </Button>
                                    </div>
                                </Tooltip>
                        }
                    </Grid>
                </TrialContextSelector>
                <PatientsByCodesResolver
                    masterGroupCode={parsedMasterGroupCode}
                    collaboratingGroupCode={parsedCollaboratingGroupCode}
                    countryCode={parsedCountryCode}
                    institutionCode={parsedInstitutionCode}
                    patientStateIds={prerandomisedStates}
                >
                    <PatientCollapsibleTable
                        title="Pre-randomised Participant"
                        entityName="Participant"
                        columns={prerandomisedColumns}
                        onRowClick={onRowClick as any}
                    />
                </PatientsByCodesResolver>
                <PatientsByCodesResolver
                    masterGroupCode={parsedMasterGroupCode}
                    collaboratingGroupCode={parsedCollaboratingGroupCode}
                    countryCode={parsedCountryCode}
                    institutionCode={parsedInstitutionCode}
                    patientStateIds={underReviewStates}
                >
                    <PatientCollapsibleTable
                        title="Participants Under Review"
                        pluralizeTitle={false}
                        entityName="Participant"
                        columns={underReviewColumns}
                        onRowClick={onRowClick as any}
                    />
                </PatientsByCodesResolver>
                <PatientsByCodesResolver
                    masterGroupCode={parsedMasterGroupCode}
                    collaboratingGroupCode={parsedCollaboratingGroupCode}
                    countryCode={parsedCountryCode}
                    institutionCode={parsedInstitutionCode}
                    patientStateIds={randomisedStates}
                >
                    <PatientCollapsibleTable
                        title="Randomised Participant"
                        entityName="Participant"
                        columns={randomisedColumns}
                        onRowClick={onRowClick as any}
                    />
                </PatientsByCodesResolver>
                <PatientsByCodesResolver
                    masterGroupCode={parsedMasterGroupCode}
                    collaboratingGroupCode={parsedCollaboratingGroupCode}
                    countryCode={parsedCountryCode}
                    institutionCode={parsedInstitutionCode}
                    patientStateIds={ineligibleStates}
                >
                    <PatientCollapsibleTable
                        title="Ineligible Participant"
                        entityName="Participant"
                        columns={ineligibleColumns}
                        onRowClick={onRowClick as any}
                    />
                </PatientsByCodesResolver>
            </div>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default Registration;
