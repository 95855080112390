/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    Condition,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    Password,
    KeyboardDatePicker,
    Numeric,
    StaticGrid,
    IStaticGridHeaderProps,
    IStaticGridCellProps,
    FormLabel,
    Input,
    StaticGridRow,
    FormGrid,
    FieldProvider,
    IFormGridCell,
    Masked,
    CtcaeSelector,
    Checkbox,
    getParentPropertyPath
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-trogapi';

import { RequestState } from '@ngt/request-utilities';

import { Typography, makeStyles, InputAdornment } from '@material-ui/core';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import { NumberFormatValues } from 'react-number-format';
import { get } from 'lodash-es';
import { THREE_DIGIT_POSITIVE_INTEGER } from '../../validators/numeric';

//import SourceDocuments from '../../components/SourceDocuments';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IClinicalAssessmentsProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 1, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const FOUR_DIGIT_POSITIVE_INTEGER = {
    decimalScale: 0,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 10000) {
            return true;
        }

        return false;
    }
}

export const FOUR_DIGIT_INTEGER_ONE_DECIMAL_PLACES_POSITIVE = {
    decimalScale: 1,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 10000) {
            return true;
        }

        return false;
    }
}

export const THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACES_POSITIVE = {
    decimalScale: 1,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 1000) {
            return true;
        }

        return false;
    }
}

export const THREE_DIGIT_INTEGER_TWO_DECIMAL_PLACES_POSITIVE = {
    decimalScale: 2,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 1000) {
            return true;
        }

        return false;
    }
}

export const TWO_DIGIT_INTEGER_TWO_DECIMAL_PLACES_POSITIVE = {
    decimalScale: 2,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 100) {
            return true;
        }

        return false;
    }
}

export const UK_DATE = {
    mask: [/[0-3U]/, /[0-9K]/, '/', /[0-1U]/, /[0-9K]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    showMask: true
};

const lastBloodTransfusionNotApplicableFalse = ({ lookups, formState }: ICrfConditionParameters<Dtos.ClinicalAssessment>) => formState?.values.lastBloodTransfusionNotApplicable != true;
const lastGrowthFactorNotApplicableFalse = ({ lookups, formState }: ICrfConditionParameters<Dtos.ClinicalAssessment>) => formState?.values.lastGrowthFactorNotApplicable != true;

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const ClinicalAssessments: React.FunctionComponent<IClinicalAssessmentsProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const { form } = React.useContext(FormContext)

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.ClinicalAssessment}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <Field
                    name="dateOfClinicalAssessment"
                    component={KeyboardDatePicker}
                />
                <Field
                    name="bloodPressureSystolic"
                    component={Numeric}
                    numberFormat={THREE_DIGIT_POSITIVE_INTEGER}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                mmHg&nbsp;
                            </InputAdornment>
                        )
                    }}
                /> 
                <Field
                    name="bloodPressureDiastolic"
                    component={Numeric}
                    numberFormat={THREE_DIGIT_POSITIVE_INTEGER}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                mmHg&nbsp;
                            </InputAdornment>
                        )
                    }}
                /> 
                <FieldGroup>
                    <Field
                        name="receivedGrowthFactorsOrBloodTransfusions"
                        component={ToggleButtonGroup}
                    />
                    <Field
                        name="lastBloodTransfusionNotApplicable"
                        component={Checkbox}
                        label="Date of last blood transfusion"
                    />
                    <CrfCondition
                        type={Dtos.ClinicalAssessment}
                        mode="Enable"
                        condition={lastBloodTransfusionNotApplicableFalse}
                        subscription={{ values: true }}
                    >
                        <Field
                            name="dateOfLastBloodTransfusion"
                            component={KeyboardDatePicker}
                            label=""
                        />
                    </CrfCondition>

                    <Field
                        name="lastGrowthFactorNotApplicable"
                        component={Checkbox}
                        label="Date of last growth factor"
                    />
                    <CrfCondition
                        type={Dtos.ClinicalAssessment}
                        mode="Enable"
                        condition={lastGrowthFactorNotApplicableFalse}
                        subscription={{ values: true }}
                    >
                        <Field
                            name="dateOfLastGrowthFactor"
                            component={KeyboardDatePicker}
                            label=""
                        />
                    </CrfCondition>
                </FieldGroup>
                <Field
                    name="participantTreatedWithSecondGeneration"
                    component={ToggleButtonGroup}
                />
                <Field
                    name="participantUsedOestrogens"
                    component={ToggleButtonGroup}
                />
                <Field
                    name="participantHadAnyRadiotherapy"
                    component={ToggleButtonGroup}
                />
                <Field
                    name="participantStartedTreatment"
                    component={ToggleButtonGroup}
                />
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default ClinicalAssessments;
