/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Select,
    ToggleButtonGroup,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    KeyboardDatePicker
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-trogapi';

import { RequestState } from '@ngt/request-utilities';

import { Typography, makeStyles } from '@material-ui/core';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import { NumberFormatValues } from 'react-number-format';


//import SourceDocuments from '../../components/SourceDocuments';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IPsmaPetProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 1, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const THREE_DIGIT_INTEGER_TWO_DECIMAL_PLACES_POSITIVE = {
    decimalScale: 2,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 1000) {
            return true;
        }

        return false;
    }
}

export const TWO_DIGIT_INTEGER_TWO_DECIMAL_PLACES_POSITIVE = {
    decimalScale: 2,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 100) {
            return true;
        }

        return false;
    }
}

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const PsmaPet: React.FunctionComponent<IPsmaPetProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const { form } = React.useContext(FormContext)

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.PsmaPet}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <Field
                    name="dateOfPsmaPetScan"
                    component={KeyboardDatePicker}
                />
                <Field
                    name="numberOfSitesOfActivity"
                    component={Select}
                />
                <Field
                    name="anyExtraPelvicDisease"
                    component={ToggleButtonGroup}
                />
                <FieldGroup>
                    <Typography
                        className={classes.subHeading}
                    >
                        What types of tumour are present?
                    </Typography>
                    <Field
                        name="bone"
                        component={ToggleButtonGroup}
                    />
                    <Field
                        name="pelvicNodal"
                        component={ToggleButtonGroup}
                    />
                    <Field
                        name="extraPelvicNodal"
                        component={ToggleButtonGroup}
                    />
                    <Field
                        name="visceral"
                        component={ToggleButtonGroup}
                    />
                    <Field
                        name="prostate"
                        component={ToggleButtonGroup}
                    />
                    <Field
                        name="prostateBed"
                        component={ToggleButtonGroup}
                    />
                </FieldGroup>
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default PsmaPet;
